import React, { useEffect, useContext, useMemo } from 'react';
import { slide as SlideMenu } from 'react-burger-menu';
import { InspectorDashboardContext } from '../context';
import InspectifyCobrandingLogo from '../../../components/InspectifyCobrandingLogo';
import { DashboardPageTab } from '../types';
import {
  Container, MenuButton, LogoContainer, burgerMenuStyles,
} from './styles';
import ContractorDetails from '../Menu/ContractorDetails';
import { signOut } from '../../../utils/amplify_auth';
import Footer from '../../../components/PageLayout/Footer';
import hamburger_icon from '../../../assets/icons/hamburger.svg';
import { ContractorTypeEnum, UserContractor } from '../../../types';
import AccountSwitcher from '../../../components/AccountSwitcher/AccountSwitcher';
import { canAccessMSAPage } from '../../../utils/user';
import MenuButtonSection from '../Menu/MenuButtonSection';
import { BusinessDetailsSection } from '../BusinessDetails/types';
import { useTreatment } from '../../../utils/splitio';
import { SplitFlags } from '../../../redux/actions';

interface Props {
  user: UserContractor,
  setCurrentSection: (section: BusinessDetailsSection) => void,
  handleClickMenuTab: (tab: DashboardPageTab, leaveMenuOpen?: boolean) => void,
  showMenu: boolean,
  setShowMenu: (value: boolean) => void,
}

const MobileMenu: React.FC<Props> = ({
  user,
  setCurrentSection,
  handleClickMenuTab,
  showMenu,
  setShowMenu,
}) => {
  const { state } = useContext(InspectorDashboardContext);
  const featureInspectorOnboarding = useTreatment(SplitFlags.FeatureInspectorOnboarding);

  const showMSA = useMemo(() => (
    canAccessMSAPage(user)
  ), [user]);

  useEffect(() => {
    // Note stef: context for Slide menu in monorepo
    // Prevent background scroll when menu is open
    if (showMenu) {
      document.body.setAttribute('style', 'overflow: hidden;');
    } else {
      document.body.setAttribute('style', 'overflow: visible;');
    }
  }, [showMenu]);

  return (
    <Container>
      <LogoContainer isMenuOpen={showMenu}>
        <InspectifyCobrandingLogo
          display="inspectify"
          size="large"
        />
      </LogoContainer>
      <SlideMenu
        right
        isOpen={showMenu}
        onStateChange={({ isOpen }) => setShowMenu(isOpen)}
        styles={burgerMenuStyles}
        customBurgerIcon={<img src={hamburger_icon} alt="hamburger_icon" />}
      >
        <div style={{ display: 'flex', height: '100%', flexDirection: 'column' }}>
          <ContractorDetails contractor={user.currentUser.contractor} displayForMobile />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <AccountSwitcher />
            <MenuButton
              selected={state.page === DashboardPageTab.Jobs}
              onClick={() => handleClickMenuTab(DashboardPageTab.Jobs)}
            >
              {DashboardPageTab.Jobs}
            </MenuButton>
            <MenuButton
              selected={state.page === DashboardPageTab.BusinessDetails}
              onClick={() => handleClickMenuTab(DashboardPageTab.BusinessDetails, true)}
            >
              {DashboardPageTab.BusinessDetails}
            </MenuButton>
            {/* TODO: remove contractor type checks when feature flag is removed */}
            {((featureInspectorOnboarding === 'on' && user.currentUser.contractor.contractorType === ContractorTypeEnum.Inspector)
              || user.currentUser.contractor.contractorType === ContractorTypeEnum.Surveyor)
              && state.page === DashboardPageTab.BusinessDetails ? (
                <>
                  <MenuButtonSection
                    sectionName={BusinessDetailsSection.BasicInformation}
                    isMobile
                    setCurrentSection={setCurrentSection}
                    setShowMenu={setShowMenu}
                  />
                  {user.currentUser?.contractor.contractorType === ContractorTypeEnum.Inspector && (
                  <MenuButtonSection
                    sectionName={BusinessDetailsSection.InspectionServices}
                    isMobile
                    setCurrentSection={setCurrentSection}
                    setShowMenu={setShowMenu}
                  />
                  )}
                  <MenuButtonSection
                    sectionName={BusinessDetailsSection.Certifications}
                    isMobile
                    setCurrentSection={setCurrentSection}
                    setShowMenu={setShowMenu}
                  />
                  <MenuButtonSection
                    sectionName={BusinessDetailsSection.ServiceableArea}
                    isMobile
                    setCurrentSection={setCurrentSection}
                    setShowMenu={setShowMenu}
                  />
                  <MenuButtonSection
                    sectionName={BusinessDetailsSection.AccessAndSoftware}
                    isMobile
                    setCurrentSection={setCurrentSection}
                    setShowMenu={setShowMenu}
                  />
                  <MenuButtonSection
                    sectionName={BusinessDetailsSection.Documents}
                    isMobile
                    setCurrentSection={setCurrentSection}
                    setShowMenu={setShowMenu}
                  />
                </>
              ) : null}
            {showMSA ? (
              <MenuButton
                type="button"
                selected={state.page === DashboardPageTab.MasterInspectionAndServiceAgreement}
                onClick={() => handleClickMenuTab(DashboardPageTab.MasterInspectionAndServiceAgreement)}
              >
                {DashboardPageTab.MasterInspectionAndServiceAgreement}
              </MenuButton>
            ) : (
              null
            )}
            {user.currentUser.grantedPermissions.includes('view_payment_setup_page')
              && (
              <>
                <MenuButton
                  selected={state.page === DashboardPageTab.TipaltiSetupProcess}
                  onClick={() => handleClickMenuTab(DashboardPageTab.TipaltiSetupProcess)}
                >
                  {DashboardPageTab.TipaltiSetupProcess}
                </MenuButton>

                <MenuButton
                  selected={state.page === DashboardPageTab.TipaltiPaymentHistory}
                  onClick={() => handleClickMenuTab(DashboardPageTab.TipaltiPaymentHistory)}
                >
                  {DashboardPageTab.TipaltiPaymentHistory}
                </MenuButton>
              </>
              )}
            <MenuButton onClick={() => signOut()}>Log out</MenuButton>
          </div>
          <Footer sidebarFooter />
        </div>
      </SlideMenu>
    </Container>
  );
};

export default MobileMenu;
