import React from 'react';
import CloseIcon from 'haven-design-system/build/assets/svgs/action/close.svg?component';
import './styles.scss';
import Button from '../Button';
import { ContactModalProps } from './types';

const ContactModal: React.FC<ContactModalProps> = ({
  isOpen,
  header,
  url,
  onClose,
}) => (
  <>
    {isOpen ? (
      <div className="modalBackdrop" aria-hidden="true" onClick={onClose}>
        <div className="contactModal">
          <div className="contactModalHeaderContainer">
            <div className="contactModalHeader">
              {header}
            </div>
            <Button
              className="closeButton"
              onClick={onClose}
            >
              <CloseIcon />
            </Button>
          </div>
          <div className="contactModalBody">
            Email:&nbsp;
            <a
              className="contactModalLink"
              href={`mailto:${url}`}
            >
              {url}
            </a>
          </div>
        </div>
      </div>
    ) : null}
  </>
);

export default ContactModal;
