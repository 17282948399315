import React from 'react';
import { StateCodeEnum, StateEnum } from '../../../types/address';

export interface OnboardingFormBasicInformationProps {
  firstName: string,
  setFirstName: (e: string) => void,
  lastName: string,
  setLastName: (e: string) => void,
  phoneNumber: string,
  setPhoneNumber: (e: string) => void,
  email: string,
  setEmail: (e: string) => void,
  businessName: string,
  setBusinessName: (e: string) => void,
  website: string,
  setWebsite: (e: string) => void,
  street: string,
  setStreet: (e: string) => void,
  city: string,
  setCity: (e: string) => void,
  state: StateEnum | StateCodeEnum,
  setState: (e: StateEnum | StateCodeEnum) => void,
  zipCode: string,
  setZipCode: (e: string) => void,
  phoneErrorMessage: string,
  setPhoneErrorMessage: (e: string) => void,
  emailErrorMessage: string,
  setEmailErrorMessage: (e: string) => void,
  showZipCodeError: boolean,
  setShowZipCodeError: (e: boolean) => void,
  isMobile: boolean,
  addressRef: React.RefObject<HTMLInputElement>,
  onboardingContractorType: string;
}

export interface OnboardingFormPageProps {
  firstName: string,
  setFirstName: (e: string) => void,
  lastName: string,
  setLastName: (e: string) => void,
  phoneNumber: string,
  setPhoneNumber: (e: string) => void,
  email: string,
  setEmail: (e: string) => void,
  businessName: string,
  setBusinessName: (e: string) => void,
  website: string,
  setWebsite: (e: string) => void,
  street: string,
  setStreet: (e: string) => void,
  city: string,
  setCity: (e: string) => void,
  state: StateEnum | StateCodeEnum,
  setState: (e: StateEnum | StateCodeEnum) => void,
  zipCode: string,
  setZipCode: (e: string) => void,
  hasMultipleInspectors: boolean,
  setHasMultipleInspectors: (e: boolean) => void,
  hasModeOfTransportation: boolean,
  setHasModeOfTransporation: (e: boolean) => void,
  hasReliablePhone: boolean,
  setHasReliablePhone: (e: boolean) => void,
  sendAutomatedSms: boolean,
  setSendAutomatedSms: (e: boolean) => void,
  serviceAreaRadiusInMiles: number;
  setServiceAreaRadiusInMiles: (e: number) => void,
  inspectorLicenseHeld: boolean,
  setInspectorLicenseHeld: (e: boolean) => void,
  contractorLicenseHeld: boolean,
  setContractorLicenseHeld: (e: boolean) => void,
  realEstateLicenseHeld: boolean,
  setRealEstateLicenseHeld: (e: boolean) => void,
  phoneErrorMessage: string,
  setPhoneErrorMessage: (e: string) => void,
  emailErrorMessage: string,
  setEmailErrorMessage: (e: string) => void,
  showZipCodeError: boolean,
  setShowZipCodeError: (e: boolean) => void,
  formComplete: () => boolean;
  isMobile: boolean,
  addressRef: React.RefObject<HTMLInputElement>,
  submitOnboardingForm: () => void;
  loading: boolean;
  onboardingContractorType: string;
}

export enum BasicInformation {
  Title = 'Basic Information',
  Required = '*',
  FirstName = 'First name',
  LastName = 'Last name',
  Email = 'Email',
  Phone = 'Phone number',
  BusinessName = 'Business name',
  BusinessPhoneNumber = 'Business phone number',
  BusinessEmail = 'Business email',
  BusinessContactName = 'Business contact name',
  SchedulerName = 'Scheduler name',
  SchedulerPhoneNumber = 'Scheduler phone number',
  SchedulerEmail = 'Scheduler email',
  Website = 'Website',
  StreetAddress = 'Street address',
  City = 'City',
  State = 'State',
  ZipCode = 'Zip code',
  EmailError = 'Please enter a valid email address',
  PhoneError = 'Please enter a valid 10-digit phone number',
  ZipCodeError = 'Please enter a valid zip code',
  PhoneNumberInUse = 'Phone number already in use',
  EmailInUse = 'Email already in use',
}

export enum OnboardingFormQuestions {
  InspectorTitle = 'Additional question',
  SurveyorTitle = 'Job specific questions',
  Required = '*',
  Yes = 'Yes',
  No = 'No',
  MultipleInspectors = 'Do you employ more than one inspector at your company?',
  Transportation = 'Do you have a reliable mode of transportation such as a car, van, or truck?',
  Smartphone = 'Do you have a reliable smartphone and are able to download apps from the Google play or Apple app store?',
  Sms = 'Do you agree to receive automated transactional messages about your jobs from Inspectify. Message and data rates may apply. Number of messages may very. To opt-out, reply STOP to any text message.',
  SmsYes = 'Yes, I consent to receiving SMS messages',
  SmsNo= 'No, I do not consent to receiving SMS messages',
  Distance = 'How far are you willing to drive?',
  DistanceSelect = '(Select the furthest distance in miles)',
  DistanceMin = '5 miles',
  DistanceMax = '200 miles',
  License = 'Do you currently hold any of the following licenses?',
  LicenseSelect = '(Select all that apply)',
  Inspector = 'Inspector',
  Contractor = 'Contractor',
  Realtor = 'Real Estate Agent/Realtor',
}
