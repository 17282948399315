import { PageLayoutType } from '../../types';

export interface PageLayoutProps {
  /** Type of layout that should be used for this page */
  type?: PageLayoutType;
}

export enum RehabPdfFilterOption {
  Tags = 'tags',
  Contributors = 'contributors',
}
