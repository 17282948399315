export interface ContactModalProps {
  isOpen: boolean,
  header: string,
  url: string,
  onClose: () => void,
}

export enum ContactSupport {
  Header = 'Questions? Ask us!',
}
