import React, { useMemo } from 'react';
import { WorkItem as WorkItemType } from '../../../../types';
import { WorkItemBox } from '../../styles';
import WorkItem from './WorkItem';
import { useTypedSelector } from '../../../../redux/store';
import { selectRehabWorkItemAttachments } from '../../../../redux/selectors';
import { groupWorkItemsBySystemAndSubsystem } from '../../../../utils/rehab';

interface Props {
  workItems: { [systemName: string]: WorkItemType[] };
  imageWidth: number;
}

const Content: React.FC<Props> = ({ workItems, imageWidth }) => {
  const workItemAttachments = useTypedSelector(selectRehabWorkItemAttachments);

  const groupedItems = useMemo(() => groupWorkItemsBySystemAndSubsystem(workItems), [workItems]);

  return (
    <>
      {Object.keys(groupedItems).map((systemName) => (
        <div key={systemName}>
          <h3>{systemName}</h3>
          {Object.keys(groupedItems[systemName]).map((subsystemName) => (
            <div key={subsystemName}>
              {subsystemName && subsystemName !== 'null' && (
                <h4>
                  <span style={{ fontWeight: 'normal' }}>{systemName}</span> / {subsystemName}
                </h4>
              )}
              {groupedItems[systemName][subsystemName].map((item) => (
                <WorkItemBox key={item.id}>
                  <WorkItem
                    item={item}
                    imageWidth={imageWidth}
                    workItemAttachments={workItemAttachments}
                  />
                </WorkItemBox>
              ))}
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

export default Content;
