import React, {
  useRef, useEffect, useState, useMemo,
} from 'react';
import {
  Report,
  ReportSubsystem,
  ReportSystem,
  InspectionWarranty,
} from 'marketplace-common';
import cn from 'classnames';
import styles from './styles.module.scss';
import {
  getAllSystemAttachments,
  getAllSystemDefects,
  getAllSystemInformations,
  getAllSystemLimitations,
  getSystemIssueCounts,
  hideSystem,
  showBasedOnReportDeliveryFilter,
} from '../../../utils/report';
import { useReportsPageContext } from '../../../utils/reportsPageContext';
import Attachments from './Attachments';
import { ReportDeliveryFilter } from '../../../types/models';
import { WorkItemModalOptions } from '../../RehabToolPage/types';
import SubsystemDetails from './SubsystemDetails';
import { getGroupedSubsystemsByName } from '../utils';
import { getReportApplianceInformations } from '../../../utils/reportApplianceInformation';
import IssueBadge from './IssueBadge';

interface Props {
  report: Report
  groupedReportSystemsByName: { [name: string]: ReportSystem[] }
  systemName: string
  inspectionWarranty?: InspectionWarranty
  imageWidth: number
  index: number
  filter: ReportDeliveryFilter
  isRehab?: boolean
  isAppliancesOnly?: boolean
  isPrint?: boolean
  handleWorkItemModal?: (options: WorkItemModalOptions) => void
  inspectifyAdmin: boolean
  noDeficienciesRequired: boolean
  isInspectionWarrantyExpired: boolean
  allowCopyLongLiveUrl: boolean
  handleOpenReportMediaUploader?: (itemId: string, itemType: string, reportSystemGroupId?: string | null) => void;
}

const SystemDetails: React.FC<Props> = ({
  report,
  groupedReportSystemsByName,
  inspectionWarranty,
  systemName,
  imageWidth,
  index,
  filter,
  isRehab,
  isAppliancesOnly,
  isPrint = false,
  handleWorkItemModal,
  inspectifyAdmin,
  noDeficienciesRequired,
  isInspectionWarrantyExpired,
  handleOpenReportMediaUploader,
  allowCopyLongLiveUrl,
}) => {
  const systemRef = useRef<HTMLDivElement>();

  const { dispatch } = useReportsPageContext(isPrint);

  const groupSystems = useMemo(() => {
    if (systemName && groupedReportSystemsByName?.[systemName]) {
      return (
        groupedReportSystemsByName as { [name: string]: ReportSystem[] }
      )[systemName].filter((system) => (
        !hideSystem(system, report)
      ));
    }
    return [];
  }, [groupedReportSystemsByName]);

  const groupedReportSubsystemsByName: { [name: string]: ReportSubsystem[] } = useMemo(() => (
    groupSystems && report.subsystems
      ? getGroupedSubsystemsByName(groupSystems, report.subsystems) : {}
  ), [groupSystems, report?.subsystems]);

  const allSubsystems = useMemo(() => Object.values(groupedReportSubsystemsByName).reduce(
    (acc, subsystems) => [...acc, ...subsystems], [],
  ), [groupedReportSubsystemsByName]);

  const allSystemDefects = useMemo(() => (
    allSubsystems ? getAllSystemDefects(allSubsystems, report) : []
  ), [allSubsystems]);

  const allSystemLimitations = useMemo(() => (
    allSubsystems ? getAllSystemLimitations(allSubsystems, report) : []
  ), [allSubsystems]);

  const allSystemAttachments = useMemo(() => (
    allSubsystems ? getAllSystemAttachments(allSubsystems, report) : []
  ), [allSubsystems]);

  const allSystemInformations = useMemo(() => (
    allSubsystems ? getAllSystemInformations(allSubsystems, report) : []
  ), [allSubsystems]);

  const systemApplianceInformation = useMemo(() => (
    allSubsystems ? getReportApplianceInformations(
      report.applianceInformations,
      allSystemInformations,
    ) : []), [allSubsystems]);

  const {
    safety: safetyDefectCount,
    monitor: monitorDefectCount,
    repair: repairDefectCount,
    flagged: flaggedDefectCount,
    limitations: limitationsCount,
  } = getSystemIssueCounts(groupSystems, report, filter);

  const remainingIssueCount = (
    safetyDefectCount
    + monitorDefectCount
    + repairDefectCount
    + flaggedDefectCount
    + limitationsCount
  );

  const [show, setShow] = useState(true);

  useEffect(() => {
    if (systemRef.current) dispatch({ type: 'add_ref', payload: systemRef });
  }, [systemRef.current]);

  useEffect(() => {
    setShow(showBasedOnReportDeliveryFilter(
      allSystemDefects,
      allSystemLimitations,
      allSystemAttachments,
      systemApplianceInformation,
      filter,
    ));
  }, [filter, allSystemDefects, allSystemLimitations, allSystemAttachments]);

  if (!show) return null;

  return (
    <div
      ref={systemRef}
      id={systemName}
      className={cn(
        styles.scrollMargin,
        styles.systemContainer,
        index === 0 ? null : styles.systemContainerNotFirst,
      )}
    >
      <div className={styles.displayRowAlignCenter}>
        <h2 className={styles.systemHeaderText}>
          {systemName}
        </h2>
        {remainingIssueCount > 0 && (
          <IssueBadge
            type="issue"
            count={remainingIssueCount}
            noDeficienciesRequired={noDeficienciesRequired}
          />
        )}
      </div>
      {Object.keys(groupedReportSubsystemsByName).map((subsystemName) => (
        groupedReportSubsystemsByName[subsystemName].map((subsystem, i) => (
          <SubsystemDetails
            index={i}
            report={report}
            noDeficienciesRequired={noDeficienciesRequired}
            isInspectionWarrantyExpired={isInspectionWarrantyExpired}
            inspectionWarranty={inspectionWarranty}
            key={`${subsystem.reportSystemId}-${subsystem.id}-${subsystemName}`}
            subsystem={subsystem}
            imageWidth={imageWidth}
            filter={filter}
            isRehab={isRehab}
            isAppliancesOnly={isAppliancesOnly}
            handleWorkItemModal={handleWorkItemModal}
            systemName={systemName}
            isPrint={isPrint}
            inspectifyAdmin={inspectifyAdmin}
            handleOpenReportMediaUploader={handleOpenReportMediaUploader}
            allowCopyLongLiveUrl={allowCopyLongLiveUrl}
          />
        ))
      ))}

      {filter !== ReportDeliveryFilter.IssuesOnly && groupSystems.filter(
        (system) => system?.reportAttachmentIds?.length,
      ).map((system, i) => (
        <Attachments
          key={`system-attachment-${system.id}`}
          title={i === 0 ? `More ${system.name} photos / videos` : null}
          attachments={(system.reportAttachmentIds || [])
            .reduce((acc, id) => {
              if (report.attachments[id]) {
                return [...acc, report.attachments[id]];
              }
              return acc;
            }, [])}
          imageWidth={imageWidth}
          isSystemAttachments
          isRehab={isRehab}
          handleWorkItemModal={handleWorkItemModal}
          systemName={system.name}
          subsystemName={system.name}
          isPrint={isPrint}
          allowCopyLongLiveUrl={allowCopyLongLiveUrl}
          handleOpenReportMediaUploader={() => {
            handleOpenReportMediaUploader(system.id, 'ReportSystem', system.reportSystemGroupId);
          }}
        />
      ))}
    </div>
  );
};

export default SystemDetails;
