export type InspectionServiceType = {
  name: string,
  formattedName: string,
  description: string,
  active: boolean,
  recentlyEdited: boolean,
  allowCustomPricing: boolean,
  tooltipId: string,
};

export interface InspectionServiceProps {
  inspectionService: InspectionServiceType,
  onClick: () => void,
}

export interface ServicePricingModalProps {
  isOpen: boolean,
  closeModal: () => void,
  inspectionServiceName: string,
  customPricing: string,
  setCustomPricing: (value: string) => void,
  serviceIsSubcontracted: boolean,
  setServiceIsSubcontracted: (value: boolean) => void,
  savePricing: () => void,
  savePricingButtonDisabled: boolean,
}

export enum PricingModalText {
  Instructions = 'Please add pricing information and considerations. If applicable, include variable pricing.',
  ExampleInput = 'Example:\n$150 standalone\n$75 add-on with home inspection',
  Subcontracted = 'This is a subcontracted service',
  Closing = 'We\'ll add your pricing to our system and reach out if we have any questions.',
}
