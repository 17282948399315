import React, { Dispatch, SetStateAction } from 'react';
import {
  LocalProperty,
  WorksOrderStakeholder,
  InspectionDocument,
  LocalInspection,
  Job,
  getJobStatusBadgeColor,
  JobStatus,
} from 'marketplace-common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { colorPaletteSand600 } from 'haven-design-system/build/typescript/es6';
import {
  AltImage,
  AltImageWrapper,
  Badge,
  BadgeText,
  PracticeIcon,
  PracticeIconWrapper,
} from '../../shared/styles';
import {
  AddressText,
  DetailsContainer,
  InfoContainer,
  MainText,
  Section,
  StatusContainer,
  SubText,
  TextLayoutContainer,
  Title,
  HomeImage,
  Container,
  Details,
  DetailsButton,
  RestrictedWidthMainText,
  RestrictedWidthSubText,
  LayoutContainer2,
  PayText,
} from './styles';
import house from '../../../../assets/icons/house.svg';
import practiceJobIcon from '../../../../assets/icons/job-card_practice.svg';
import Spinner from '../../../../components/Spinner';
import ReportUploadModal from './ReportUpload/ReportUploadModal';
import ContactsModal from './ContactsModal';
import PayModal from './PayModal/PayModal';
import ReportUploadRow from './ReportUpload/ReportUploadRow';

interface Props {
  job: Job;
  onBack: () => void;
  loading: boolean;
  jobStatus: JobStatus;
  orderId: number;
  time: string;
  showPay: boolean;
  pay: number;
  isPaid: boolean;
  inspectionTypes: string;
  client: WorksOrderStakeholder | null,
  info: string;
  propertyInfo: LocalProperty;
  inspections: LocalInspection[];
  isMock: boolean;
  openReportUploadModal: (inspection: LocalInspection) => void;
  closeReportUploadModal: () => void;
  isContactsModalOpen: boolean;
  openContactsModal: () => void;
  closeContactsModal: () => void;
  isPayModalOpen: boolean;
  openPayModal: () => void;
  closePayModal: () => void;
  selectedInspection: LocalInspection | null;
  useSpectora: boolean;
  mainText: string;
  subText: string;
  fileForUpload: File;
  urlForUpload: string;
  otherSelectedInspections: LocalInspection[];
  setOtherSelectedInspections: Dispatch<React.SetStateAction<LocalInspection[]>>;
  setFileForUpload: Dispatch<SetStateAction<File>>;
  setUrlForUpload: Dispatch<SetStateAction<string>>;
  upload: () => void;
  error: string;
  setError: (str: string) => void;
  inspectionDocuments: InspectionDocument[];
  templateNames: string;
}

const JobDetails: React.FC<Props> = ({
  job,
  onBack,
  loading,
  jobStatus,
  orderId,
  time,
  showPay,
  pay,
  isPaid,
  inspectionTypes,
  client,
  info,
  propertyInfo,
  inspections,
  openReportUploadModal,
  closeReportUploadModal,
  isContactsModalOpen,
  openContactsModal,
  closeContactsModal,
  isPayModalOpen,
  openPayModal,
  closePayModal,
  selectedInspection,
  useSpectora,
  mainText,
  subText,
  fileForUpload,
  urlForUpload,
  otherSelectedInspections,
  setOtherSelectedInspections,
  setFileForUpload,
  setUrlForUpload,
  upload,
  error,
  setError,
  inspectionDocuments,
  templateNames,
  isMock,
}) => (
  <Container>
    <Title onClick={onBack}><FontAwesomeIcon icon={faChevronLeft} /> &nbsp; Back</Title>
    <DetailsContainer>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <ReportUploadModal
            job={job}
            modalIsOpen={selectedInspection !== null}
            closeModal={closeReportUploadModal}
            selectedInspection={selectedInspection}
            useSpectora={useSpectora}
            inspections={inspections}
            setFileForUpload={setFileForUpload}
            setUrlForUpload={setUrlForUpload}
            otherSelectedInspections={otherSelectedInspections}
            fileForUpload={fileForUpload}
            urlForUpload={urlForUpload}
            setOtherSelectedInspections={setOtherSelectedInspections}
            upload={upload}
            error={error}
            setError={setError}
          />
          <ContactsModal
            modalIsOpen={isContactsModalOpen}
            closeModal={closeContactsModal}
            orderId={orderId}
          />
          <PayModal
            modalIsOpen={isPayModalOpen}
            closeModal={closePayModal}
            inspections={inspections}
            pay={pay}
          />
          {jobStatus && (
            <Badge backgroundColor={isMock ? colorPaletteSand600 : getJobStatusBadgeColor(jobStatus)}>
              <BadgeText>
                {isMock ? 'PRACTICE' : jobStatus.toLocaleUpperCase().replace('_', ' ')}
              </BadgeText>
            </Badge>
          )}
          {
            (() => {
              if (isMock) {
                return (
                  <PracticeIconWrapper>
                    <PracticeIcon src={practiceJobIcon} alt="home" />
                  </PracticeIconWrapper>
                );
              }
              if (propertyInfo?.photo?.url) {
                return (
                  <HomeImage image={propertyInfo.photo.url} />
                );
              }
              return (
                <AltImageWrapper>
                  <AltImage src={house} alt="home" />
                </AltImageWrapper>
              );
            })()
          }
          <InfoContainer>
            <AddressText>
              {`${propertyInfo.street} ${
                propertyInfo.street2 ? propertyInfo.street2 : ''
              }`}
            </AddressText>
            <AddressText>
              {`${propertyInfo.city}, ${propertyInfo.state} ${propertyInfo.zipCode}`}
            </AddressText>
            <StatusContainer status={jobStatus}>
              <LayoutContainer2>
                <TextLayoutContainer>
                  <RestrictedWidthMainText>{mainText}</RestrictedWidthMainText>
                  <RestrictedWidthSubText>{subText}</RestrictedWidthSubText>
                  {inspections.map((inspection) => (
                    <ReportUploadRow
                      key={`${inspection.inspectionType}-${inspection.id}`}
                      inspection={inspection}
                      inspectionDocuments={inspectionDocuments}
                      onUploadClick={(ins) => openReportUploadModal(ins)}
                    />
                  ))}
                </TextLayoutContainer>
              </LayoutContainer2>
            </StatusContainer>
            <Section>
              <Details>
                <SubText>Inspection Date</SubText>
                <MainText>
                  {time}
                </MainText>
              </Details>
            </Section>
            {showPay
              && (
                <Section>
                  <Details>
                    <SubText>Your pay</SubText>
                    <PayText paid={isPaid}>
                      ${pay.toFixed(2)} {isPaid ? '(Complete)' : '(Unpaid)'}
                    </PayText>
                  </Details>
                  <DetailsButton onClick={openPayModal}>More info</DetailsButton>
                </Section>
              )}
            <Section>
              <Details>
                <SubText>Requested by</SubText>
                <MainText>{client?.name || 'N/A'}</MainText>
              </Details>
              <DetailsButton onClick={openContactsModal}>Contacts</DetailsButton>
            </Section>
            <Section>
              <Details>
                <SubText>Inspection type</SubText>
                <MainText>{inspectionTypes}</MainText>
              </Details>
            </Section>
            <Section>
              <Details>
                <SubText>Inspection template</SubText>
                <MainText>{templateNames || '--'}</MainText>
              </Details>
            </Section>
            <Section>
              <Details>
                <SubText>Additional information</SubText>
                <MainText>{info || '--'}</MainText>
              </Details>
            </Section>
          </InfoContainer>
        </>
      )}
    </DetailsContainer>
  </Container>
);

export default JobDetails;
