import React from 'react';
import Modal from 'react-modal';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { IdRef } from 'marketplace-common';
import InspectionWarrantyImage from 'haven-design-system/build/assets/svgs/images/warranty/logo.svg?component';
import LargeCheckmark from 'haven-design-system/build/assets/svgs/images/general/check.svg?component';
import './styles.scss';
import { FormFile, FormInput } from '../../../../components/FormElements';
import { uploadPropertyOwnershipDocument } from '../../../../utils/file_upload';
import { captureException } from '../../../../utils/error';
import { SUBMIT_HOME_OWNERSHIP_FORM } from '../../../../graphql/mutations/submitHomeOwnershipForm';
import { EventName, track } from '../../../../utils/analytics';
import Banner from '../Banner';
import { setWarranty } from '../../../../redux/actions';
import Checkbox from '../../../../components/Checkbox';

interface Props {
  warrantyId: IdRef;
  isOpen: boolean;
  close: () => void;
}

const OwnershipForm: React.FC<Props> = ({
  warrantyId,
  isOpen,
  close,
}) => {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [purchaseDate, setPurchaseDate] = React.useState('');
  const [file, setFile] = React.useState<File | null>(null);
  const [isVerified, setIsVerified] = React.useState(false);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const [submitHomeOwnershipForm, { loading }] = useMutation(
    SUBMIT_HOME_OWNERSHIP_FORM, {
      onCompleted: (data) => {
        if (data.warrantyProveOwnership.inspectionWarranty) {
          dispatch(setWarranty(data.warrantyProveOwnership.inspectionWarranty));
          setIsSubmitted(true);
          setErrorMessage(null);
        }
      },
      onError: (err) => {
        setErrorMessage('There was an issue submitting your proof of ownership. Please verify the information and try again. If the issue persists, please contact support at warranty@inspectify.com.');
        captureException(err, 'Error submitting proof of home ownership');
        setIsSubmitted(false);
      },
    },
  );

  const disabled = (
    !firstName
    || !lastName
    || !phoneNumber
    || !email
    || !purchaseDate
    || !file
    || !isVerified
  ) || loading;

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      if (disabled) throw new Error('Missing required fields');
      const blobSignedId = await uploadPropertyOwnershipDocument(file);

      const input: any = {
        inspectionWarrantyId: warrantyId,
        ownerFirstName: firstName,
        ownerLastName: lastName,
        ownerEmail: email,
        ownerPhoneNumber: phoneNumber,
        purchaseDate,
        blobSignedId,
      };

      await submitHomeOwnershipForm({ variables: { input } });

      track(EventName.InspectionWarrantyProofOfOwnershipSubmitted, {
        warrantyId,
        firstName,
        lastName,
        email,
        phoneNumber,
        purchaseDate,
        hasFile: !!file,
      });

      setFirstName('');
      setLastName('');
      setEmail('');
      setPhoneNumber('');
      setPurchaseDate('');
      setIsVerified(false);
      setFile(null);
    } catch (err: any) {
      captureException(err, 'Error uploading property ownership document');
      setIsSubmitted(false);
    }
  };

  const handleClose = () => {
    setIsSubmitted(false);
    setErrorMessage(null);
    close();
  };

  return (
    <Modal
      className="warranty__modal"
      overlayClassName="warranty__overlay"
      isOpen={isOpen}
      onRequestClose={handleClose}
      ariaHideApp={false}
    >
      <div className="warranty__content">
        <div className="warranty__header">
          <div className="warranty__header__image">
            <InspectionWarrantyImage />
          </div>
          <div className="warranty__header__title">
            {isSubmitted ? 'Thank you for verifying your home ownership' : 'Proof of Home Ownership Form'}
          </div>
        </div>
        {!isSubmitted && (
          <form id="home-ownership-form" className="warranty__form" onSubmit={handleSubmit}>
            {errorMessage && (<Banner type="error" body={errorMessage} />)}
            <div className="warranty__form__half">
              <div className="warranty__form__half__input">
                <FormInput
                  name="First name"
                  value={firstName}
                  onChange={(e:any) => setFirstName(e.target.value)}
                  required
                />
              </div>
              <div className="warranty__form__half__input">
                <FormInput
                  name="Last name"
                  value={lastName}
                  onChange={(e: any) => setLastName(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="warranty__form__half">
              <div className="warranty__form__half__input">
                <FormInput
                  name="Email"
                  value={email}
                  onChange={(e:any) => setEmail(e.target.value)}
                  type="email"
                  required
                />
              </div>
              <div className="warranty__form__half__input">
                <FormInput
                  name="Phone number"
                  value={phoneNumber}
                  onChange={(value: string) => setPhoneNumber(value)}
                  phone
                  required
                />
              </div>
            </div>
            <div className="warranty__form__full">
              <div className="warranty__form__full__input">
                <FormInput
                  name="Purchase date"
                  value={purchaseDate}
                  onChange={(e:any) => setPurchaseDate(e.target.value)}
                  type="date"
                  required
                />
              </div>
            </div>
            <div className="warranty__form__full">
              <div className="warranty__form__full__input">
                <FormFile
                  name="Proof of home ownership document"
                  files={file ? [file] : []}
                  addFile={(uploadedFile: File) => {
                    setFile(uploadedFile);
                  }}
                  upload
                  tooltip="Acceptable documents include: Property deed, Purchase document, Property tax receipt, Mortgage payment record, Service bills (utilities, internet, etc.), Home insurance, Affidavit of ownership"
                />
              </div>
            </div>
            <div className="warranty__form__full">
              <div className="warranty__form__full__input">
                <Checkbox isChecked={isVerified} toggle={() => setIsVerified(!isVerified)}>
                  I verify that I am the owner of this home
                </Checkbox>
              </div>
            </div>
            <div className="warranty__form__submit">
              <button
                type="submit"
                className="warranty__form__submit__button"
                disabled={disabled}
              >
                Submit
              </button>
            </div>
          </form>
        )}
        {isSubmitted && (
          <div className="warranty__complete">
            <div className="warranty__complete__image"><LargeCheckmark /></div>
            <div className="warranty__complete__text">
              After your status is verified,
              you will be able to initiate a claim directly from your inspection report.
            </div>
            <div className="warranty__complete__close">
              <button
                type="button"
                className="warranty__complete__close__button"
                onClick={handleClose}
              >
                Close this window
              </button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default OwnershipForm;
