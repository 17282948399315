import React, { useMemo } from 'react';
import { isNil } from 'lodash';
import { WorkItem } from '../../../../types';
import { formatMoney } from '../../../../utils/money';
import { TableRow } from '../../styles';
import { SummaryItemName, SummaryItemCost } from './styles';

const WorkItemSection: React.FC<{
  title: string,
  subtitle?: string,
  items: WorkItem[],
}> = ({ title, subtitle, items }) => {
  const totalCost = useMemo(() => {
    if (items.length) {
      return items.reduce((sum, cur) => sum + (cur.totalPrice || 0), 0);
    }
    return null;
  }, [items]);

  return (
    <>
      <TableRow>
        <SummaryItemName isBold>{title} {(subtitle && subtitle !== 'null') && (
        <span> / {subtitle}</span>
        )}
        </SummaryItemName>
        <SummaryItemCost isBold>{formatMoney(totalCost)}</SummaryItemCost>
      </TableRow>
      {items.map((item) => (
        <TableRow key={item.id}>
          <SummaryItemName>
            {item.title || 'Undefined work item'}
          </SummaryItemName>
          <SummaryItemCost>{isNil(item.totalPrice) ? 'Price Pending' : formatMoney(item.totalPrice)}</SummaryItemCost>
        </TableRow>
      ))}
    </>
  );
};

export default WorkItemSection;
