import React from 'react';
import {
  getJobStatusBadgeColor,
  JobStatus,
  Property,
} from 'marketplace-common';
import { colorPaletteSand600 } from 'haven-design-system/build/typescript/es6';
import house from '../../../../assets/icons/house.svg';
import inspectifyLogo from '../../../../assets/inspectify/Inspectify_Logo_Jobs.svg';
import practiceJobIcon from '../../../../assets/icons/job-card_practice.svg';
import checkmarkJobs from '../../../../assets/icons/checkmark_jobs.svg';
import {
  AddressText,
  AltImage,
  AltImageWrapper,
  Badge,
  BadgeText,
  CheckmarkImage,
  DateText,
  HomeImage,
  InspectionsText,
  LogoImage,
  PracticeIcon,
  PracticeIconWrapper,
} from '../../shared/styles';
import {
  JobRowButton,
  JobRowCard,
  JobRowInfo,
  JobRowPic,
} from './styles';

interface Props {
  onCardClick: (e: any) => void;
  isClickable: boolean;
  jobStatus: JobStatus;
  property: Property;
  scheduledInspection: string;
  inspectionTypes: string;
  buttonText: string;
  isAppRequired: boolean;
  isMock: boolean;
  handleClick: (e: any) => void;
}

const JobRow: React.FC<Props> = ({
  onCardClick,
  isClickable,
  jobStatus,
  property,
  scheduledInspection,
  inspectionTypes,
  buttonText,
  isAppRequired,
  isMock,
  handleClick,
}) => (
  <>
    <JobRowCard onClick={onCardClick} clickable={isClickable}>
      <JobRowPic>
        {jobStatus && (
          <Badge backgroundColor={isMock ? colorPaletteSand600 : getJobStatusBadgeColor(jobStatus)}>
            <BadgeText>
              {isMock ? 'PRACTICE' : jobStatus.toLocaleUpperCase().replace('_', ' ')}
            </BadgeText>
          </Badge>
        )}
        {
          (() => {
            if (isMock) {
              return (
                <PracticeIconWrapper>
                  <PracticeIcon src={practiceJobIcon} alt="home" />
                </PracticeIconWrapper>
              );
            }
            if (property?.photo?.url) {
              return (
                <HomeImage src={property.photo.url} alt="home" />
              );
            }
            return (
              <AltImageWrapper>
                <AltImage src={house} alt="home" />
              </AltImageWrapper>
            );
          })()
        }
      </JobRowPic>
      <JobRowInfo>
        <DateText>{scheduledInspection}</DateText>
        <AddressText>{property.address}</AddressText>
        <InspectionsText>{inspectionTypes}</InspectionsText>
      </JobRowInfo>
      <JobRowButton
        completed={jobStatus === JobStatus.Completed}
        upload={buttonText === 'Upload report'}
        appRequired={isAppRequired}
        clickable={isClickable}
        onClick={handleClick}
      >
        {!isClickable && isAppRequired && jobStatus !== JobStatus.Completed && (
          <><LogoImage src={inspectifyLogo} /> {buttonText}</>
        )}
        {jobStatus === JobStatus.Completed && (
          <><CheckmarkImage src={checkmarkJobs} /> {buttonText}</>
        )}
        {isClickable && <>{buttonText}</>}
      </JobRowButton>
    </JobRowCard>
  </>
);

export default JobRow;
