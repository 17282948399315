import cn from 'classnames';
import React from 'react';
import { Tooltip } from 'react-tooltip';
import { FormInputCurrency } from '../../../../components/FormElements';
import { formatMoney } from '../../../../utils/money';
import styles from './styles.module.scss';
import { DrawRowProps } from './types';

const DrawRow: React.FC<DrawRowProps> = ({
  data,
  displayUnitColumns,
  displayFinancialColumns,
  displayDrawDisbursementFields,
  editable,
  onChange,
}) => (
  <>
    {data.units.map((unit, index, units) => {
      const isLast = index === units.length - 1;
      const actualDisbursement = unit.clientDisbursement || unit.disbursement || 0;
      const progress = unit.clientProgress || unit.progress || 0;
      const progressError = progress > 1 || progress < 0;
      const previousDisbursementError = unit.previousDisbursement > unit.budget && unit.previousDisbursement > 0;
      const disbursementError = unit.clientDisbursement
        ? unit.clientDisbursement > unit.budget
        : unit.disbursement > unit.budget;

      const bottomBorderClass = isLast ? styles.borderBottom : null;

      return (
        <tr key={unit.id}>
          {/* These 2 are special, they span the length of the units */}
          {index === 0 && (
            <td rowSpan={units.length} className={cn(styles.verticalAlignTop, styles.borderBottom)}>
              {data.item}
            </td>
          )}
          {index === 0 && (
            <td rowSpan={units.length} className={cn(styles.verticalAlignTop, styles.borderBottom)}>
              {data.description}
            </td>
          )}

          {displayUnitColumns && (
            <td className={bottomBorderClass}>
              {unit.name}
            </td>
          )}

          {displayFinancialColumns && (
            <td className={bottomBorderClass}>
              {editable ? (
                <FormInputCurrency
                  value={unit.budget}
                  includeLabel={false}
                  name={`budget-${unit.id}`}
                  loading={unit.budgetIsLoading}
                  className={cn('form-control', 'form-control-sm')}
                  onValueBlur={(value) => value !== unit.budget
                    && onChange(value, `budget-${index}`, data)}
                />
              ) : formatMoney(Math.round((unit.budget || 0) * 100) / 100)}
            </td>
          )}

          {displayFinancialColumns && displayDrawDisbursementFields && (
            <td className={bottomBorderClass}>
              {editable ? (
                <>
                  {previousDisbursementError && (
                    <Tooltip
                      anchorSelect={`#previousDisbursement-${unit.id}`}
                      place="bottom"
                      content="Cannot exceed item budget"
                    />
                  )}
                  <FormInputCurrency
                    value={unit.previousDisbursement}
                    includeLabel={false}
                    name={`previousDisbursement-${unit.id}`}
                    loading={unit.previousDisbursementIsLoading}
                    className={cn('form-control', 'form-control-sm', { 'is-invalid': previousDisbursementError })}
                    min={0}
                    onValueBlur={(value) => value !== unit.previousDisbursement
                      && onChange(value, `previousDisbursement-${index}`, data)}
                  />
                  {previousDisbursementError
                    && <span className={cn(styles.editedProgress, styles.hasError)}>Cannot exceed item budget</span>}
                </>
              ) : formatMoney(Math.round(unit.previousDisbursement * 100) / 100)}
            </td>
          )}

          {displayFinancialColumns && (
            <td className={bottomBorderClass}>
              {formatMoney(Math.round(unit.remainingBalance * 100) / 100)}
            </td>
          )}

          <td className={cn(styles.separatorAlignedMiddle, bottomBorderClass)}>
            {progressError && (
              <Tooltip
                anchorSelect={`#progress-${unit.id}`}
                place="bottom"
                content={
                  progress > 1
                    ? 'Cannot exceed item budget'
                    : 'Adjusted based on disbursement'
                }
              />
            )}
            <span
              id={`progress-${unit.id}`}
              className={cn(progressError ? styles.error : null)}
            >
              {Math.round(progress * 100)}%
              {unit.clientProgress ? (
                <>
                  <br />
                  <span className={styles.editedProgress}>(edited)</span>
                </>
              ) : null}
            </span>
          </td>

          {displayFinancialColumns && displayDrawDisbursementFields && (
            <td className={bottomBorderClass}>
              {editable ? (
                <>
                  {disbursementError && (
                    <Tooltip
                      anchorSelect={`#clientDisbursement-${unit.id}`}
                      place="bottom"
                      content="Cannot exceed item budget"
                    />
                  )}
                  <FormInputCurrency
                    id={`clientDisbursement-${unit.id}`}
                    value={actualDisbursement}
                    loading={unit.clientDisbursementIsLoading}
                    includeLabel={false}
                    name={`clientDisbursement-${index}`}
                    className={cn('form-control', ' form-control-sm', { 'is-invalid': disbursementError })}
                    min={0}
                    onValueBlur={(value) => (value !== unit.clientDisbursement || value !== unit.disbursement)
                      && onChange(value, `clientDisbursement-${index}`, data)}
                  />
                  {disbursementError && (
                    <span className={cn(styles.editedProgress, styles.hasError)}>Cannot exceed item budget</span>
                  )}
                </>
              ) : formatMoney(Math.round((actualDisbursement) * 100) / 100)}
            </td>
          )}
        </tr>
      );
    })}
  </>
);

export default DrawRow;
