import React, { useContext, useMemo, useState } from 'react';
import CloseIcon from 'haven-design-system/build/assets/svgs/action/close.svg?component';
import { useHistory } from 'react-router-dom';
import { isNull } from 'lodash';
import {
  MarketSaturationStatus, OnboardingTrackerProps, TrackerItemHeader, TrackerItemInstructions,
} from './types';
import HelloOnboardingImage from '../../../../assets/images/helloOnboardingImage.svg';
import CompleteOnboardingImage from '../../../../assets/images/confetti.svg';
import ThanksOnboardingImage from '../../../../assets/images/thanksOnboardingImage.svg';
import './styles.scss';
import '../../../Onboarding/styles.scss';
import TrackerItem from './TrackerItem';
import Button from '../../../../components/Button';
import { captureException } from '../../../../utils/error';
import { DashboardPageTab } from '../../types';
import { InspectorDashboardContext } from '../../context';
import { ContractorTypeEnum } from '../../../../types';
import { useUpdateInspectorOnboarding, useUpdateSurveyorOnboarding } from '../../../../hooks/onboarding';
import { ContactSupport } from '../../../../components/ContactModal/types';

const OnboardingTracker: React.FC<OnboardingTrackerProps> = (
  { contractorType, onboardingData, handleContactModal },
) => {
  const history = useHistory();
  const { setPage } = useContext(InspectorDashboardContext);
  const [trackerDismissed, setTrackerDismissed] = useState(false);

  const areTrackerItemsComplete: boolean = useMemo(() => {
    if (contractorType === ContractorTypeEnum.Surveyor) {
      return onboardingData.misaSigned
      && !!onboardingData?.backgroundCheckSubmittedAt
      && onboardingData.paymentDetailsFilled
      && onboardingData.appLoggedIn;
    }
    if (contractorType === ContractorTypeEnum.Inspector) {
      const partialItemsComplete: boolean = onboardingData.misaSigned
      && onboardingData.businessInformationAdded
      && onboardingData.paymentDetailsFilled
      && onboardingData.appLoggedIn;
      return onboardingData.isMultiInspectorFirm
        ? partialItemsComplete && onboardingData.usersAdded
        : partialItemsComplete;
    }
    return false;
  }, []);

  const { updateSurveyorOnboarding } = useUpdateSurveyorOnboarding({ errorMessage: 'Graphql failed to update surveyor onboarding' });
  const { updateInspectorOnboarding } = useUpdateInspectorOnboarding({ errorMessage: 'Graphql failed to update inspector onboarding' });

  const handleCloseOnboardingTracker = () : void => {
    if (areTrackerItemsComplete) {
      try {
        setTrackerDismissed(true);
        if (contractorType === ContractorTypeEnum.Surveyor) {
          updateSurveyorOnboarding({
            variables: {
              input: {
                id: onboardingData.id,
                onboardingTrackerDismissedAt: Date.now() / 1000,
              },
            },
            context: { headers: { onboarding_id: onboardingData.id } },
          });
        }
        if (contractorType === ContractorTypeEnum.Inspector) {
          updateInspectorOnboarding({
            variables: {
              input: {
                id: onboardingData.id,
                onboardingTrackerDismissedAt: Date.now() / 1000,
              },
            },
            context: { headers: { onboarding_id: onboardingData.id } },
          });
        }
      } catch (error) {
        captureException(error, { source: 'User closed onboarding tracker' });
      }
    }
  };

  if (trackerDismissed) {
    return null;
  }

  if (onboardingData.marketSaturatedAtOnboardingTime) {
    return (
      <div className="onboardingContainer">
        <div className="onboardingContentContainer">
          <img src={ThanksOnboardingImage} alt="ThanksOnboardingImage" />
          <div className="onboardingInstructionsContainer__saturatedMarket">
            <div className="text__header">
              {MarketSaturationStatus.Header}
            </div>
            <div className="text__subheader">
              {MarketSaturationStatus.Body}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="onboardingContainer">
      {areTrackerItemsComplete && (
        <div className="closeButtonContainer">
          <Button onClick={() => handleCloseOnboardingTracker()} className="closeButtonBlue">
            <CloseIcon />
          </Button>
        </div>
      )}
      <div className="onboardingContentContainer">
        <div className="onboardingInstructionsContainer">
          {areTrackerItemsComplete ? (
            <>
              <img className="completeOnboardingImage" src={CompleteOnboardingImage} alt="CompleteOnboardingImage" />
              <div className="text__header">
                You&apos;re all set!
              </div>
              <div className="text__subheader">
                Feel free to check out our&nbsp;
                <a
                  className="text__link"
                  href={contractorType === ContractorTypeEnum.Surveyor
                    ? 'https://knowledge.inspectify.com/inspectify-community-guidelines-surveyors'
                    : 'https://knowledge.inspectify.com/inspectify-community-guidelines-inspectors'}
                  target="_blank"
                  rel="noreferrer"
                >
                  Community Guidelines
                </a>
                &nbsp;and&nbsp;
                <a
                  className="text__link"
                  href={contractorType === ContractorTypeEnum.Surveyor
                    ? 'https://knowledge.inspectify.com/surveyors'
                    : 'https://knowledge.inspectify.com/inspectors'}
                  target="_blank"
                  rel="noreferrer"
                >
                  Help Center
                </a>
                &nbsp;if you have any questions.
              </div>
              <Button
                onClick={() => handleCloseOnboardingTracker()}
              >
                Close set up
              </Button>
            </>
          ) : (
            <>
              <img src={HelloOnboardingImage} alt="HelloOnboardingImage" />
              <div className="text__header">
                Welcome to Inspectify!
              </div>
              <div className="text__subheader">
                Complete the checklist to finish setting up your account.&nbsp;
                If you have questions, visit our&nbsp;
                <a
                  className="text__link"
                  href={contractorType === ContractorTypeEnum.Surveyor
                    ? 'https://knowledge.inspectify.com/surveyors'
                    : 'https://knowledge.inspectify.com/inspectors'}
                  target="_blank"
                  rel="noreferrer"
                >
                  Help Center.
                </a>
              </div>
            </>
          )}
          {contractorType === ContractorTypeEnum.Inspector && (
            <Button onClick={handleContactModal}>
              {ContactSupport.Header}
            </Button>
          )}
        </div>
        <div className="onboardingTrackerItemsContainer">
          <TrackerItem
            isChecked={onboardingData.misaSigned}
            header={TrackerItemHeader.MISA}
            instructions={TrackerItemInstructions.MISA}
            onClick={() => setPage(DashboardPageTab.MasterInspectionAndServiceAgreement)}
          />
          {contractorType === ContractorTypeEnum.Inspector && (
            <TrackerItem
              isChecked={onboardingData?.businessInformationAdded}
              header={TrackerItemHeader.BusinessInformation}
              instructions={TrackerItemInstructions.BusinessInformation}
              onClick={() => setPage(DashboardPageTab.BusinessDetails)}
            />
          )}
          {onboardingData?.backgroundCheckRequired && (
            <TrackerItem
              isChecked={!!onboardingData.backgroundCheckSubmittedAt}
              header={TrackerItemHeader.BackgroundCheck}
              instructions={isNull(onboardingData?.submitBackgroundCheckUrl)
                ? TrackerItemInstructions.BackgroundCheckDisabled
                : TrackerItemInstructions.BackgroundCheck}
              disabled={isNull(onboardingData?.submitBackgroundCheckUrl)}
              onClick={() => window.open(onboardingData.submitBackgroundCheckUrl, '_blank')}
            />
          )}
          <TrackerItem
            isChecked={onboardingData.paymentDetailsFilled}
            header={TrackerItemHeader.Payment}
            instructions={TrackerItemInstructions.Payment}
            onClick={() => setPage(DashboardPageTab.TipaltiSetupProcess)}
          />
          {onboardingData?.isMultiInspectorFirm && (
            <TrackerItem
              isChecked={onboardingData.usersAdded}
              header={TrackerItemHeader.AddUsers}
              instructions={TrackerItemInstructions.AddUsers}
              onClick={() => setPage(DashboardPageTab.UserManagement)}
            />
          )}
          <TrackerItem
            isChecked={onboardingData.appLoggedIn}
            header={TrackerItemHeader.App}
            instructions={TrackerItemInstructions.App}
            onClick={() => history.push(`/download_app/${contractorType}`)}
          />
        </div>
      </div>
    </div>
  );
};

export default OnboardingTracker;
