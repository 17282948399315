import React, { useMemo, useState } from 'react';
import '../../styles.scss';
import InformationSection from '../../../../components/InformationSection';
import { InspectionServicesSectionProps, RemoveServicesModalEnum } from './types';
import InspectionServicePricingModal from './InspectionService/InspectionServicePricingModal';
import InspectionService from './InspectionService/InspectionService';
import { ContractorPricingInput } from '../../../../types';
import { InspectionServiceType } from './InspectionService/types';
import ContactModal from '../../../../components/ContactModal';

const InspectionServicesSection: React.FC<InspectionServicesSectionProps> = ({
  sectionRef,
  title,
  inspectionServices,
  contractorPricingInputs,
  setContractorPricingInputs,
}) => {
  const [pricingModalIsOpen, setPricingModalIsOpen] = useState(false);
  const [removeServicesModalIsOpen, setRemoveServicesModalIsOpen] = useState(false);
  const [selectedService, setSelectedService] = useState<InspectionServiceType>(null);
  const [customPricing, setCustomPricing] = useState<string>('');
  const [serviceIsSubcontracted, setServiceIsSubcontracted] = useState(false);

  const savePricingButtonDisabled = useMemo(() => customPricing.length <= 5, [customPricing]);

  const handleClosePricingModal = () => {
    setCustomPricing('');
    setServiceIsSubcontracted(false);
    setSelectedService(null);
    setPricingModalIsOpen(false);
  };

  const handleSaveInspectionService = () => {
    const pricingInput: ContractorPricingInput = {
      name: selectedService.name,
      customPricing,
      subContracted: serviceIsSubcontracted,
    };
    setContractorPricingInputs([...contractorPricingInputs, pricingInput]);
    handleClosePricingModal();
  };

  const handleSelectInspectionService = (inspectionService: InspectionServiceType) => {
    if (inspectionService.recentlyEdited) {
      // Remove an inspection service from contractorPricingInputs if the user deselects a service that was not active
      setContractorPricingInputs(contractorPricingInputs.filter((pricingInput) => pricingInput.name !== inspectionService.name));
      return;
    }
    if (inspectionService.active) {
      setRemoveServicesModalIsOpen(true);
      return;
    }
    if (inspectionService.allowCustomPricing) {
      setSelectedService(inspectionService);
      setPricingModalIsOpen(true);
      return;
    }
    setContractorPricingInputs([...contractorPricingInputs,
      {
        name: inspectionService.name,
        customPricing: '',
        subContracted: false,
      },
    ]);
  };

  return (
    <InformationSection
      title={title}
      sectionRef={sectionRef}
    >
      <div className="inspectionServicesContainer">
        {inspectionServices?.map((inspectionService) => (
          <InspectionService
            key={inspectionService.name}
            inspectionService={inspectionService}
            onClick={() => handleSelectInspectionService(inspectionService)}
          />
        ))}
      </div>
      <InspectionServicePricingModal
        isOpen={pricingModalIsOpen}
        closeModal={handleClosePricingModal}
        inspectionServiceName={selectedService?.formattedName}
        customPricing={customPricing}
        setCustomPricing={setCustomPricing}
        serviceIsSubcontracted={serviceIsSubcontracted}
        setServiceIsSubcontracted={setServiceIsSubcontracted}
        savePricing={handleSaveInspectionService}
        savePricingButtonDisabled={savePricingButtonDisabled}
      />
      <ContactModal
        isOpen={removeServicesModalIsOpen}
        header={RemoveServicesModalEnum.Header}
        url={RemoveServicesModalEnum.Email}
        onClose={() => setRemoveServicesModalIsOpen(false)}
      />
    </InformationSection>
  );
};

export default InspectionServicesSection;
