import {
  colorPaletteGrayscale50,
  colorBaseBlackPrimary,
  colorBaseBlackLight,
  colorBaseTealLight,
  colorPaletteSand200,
} from 'haven-design-system/build/typescript/es6';
import styled, { css } from 'styled-components';
import { mediaBreakpointDown } from '../../../styles/mediaBreakpoints';

export const CommonTextStyles = css`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
`;

export const basePageStyles = css`
  height: 100%;
  width: 100%;
`;

export const baseTitleStyles = css`
  font-family: Nunito Sans;
  font-style: normal;
`;

export const Container = styled.div`
  background-color: transparent;
  ${basePageStyles}
  padding: 40px 80px 64px 80px;
  height: fit-content;
  @media only screen and (${mediaBreakpointDown.md}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 0px 20px;
  }
`;

export const Input = styled.input`
  border: 1px solid ${colorPaletteGrayscale50};
  border-radius: 2px;
  height: 40px;
  padding-left: 10px;
  ::-webkit-outer-spin-button, ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`;

export const SearchLabel = styled.label`
  display: flex;
  align-self: center;
  margin-left: auto;
`;

export const DateText = styled.div`
  ${CommonTextStyles}
  color: ${colorBaseBlackPrimary};
  margin-bottom: 16px;
`;

export const InspectionsText = styled.div`
  ${CommonTextStyles}
  color: ${colorBaseBlackLight};
  font-weight: normal;
`;

export const AddressText = styled.div`
  ${CommonTextStyles}
  color: ${colorBaseBlackLight};
  margin-bottom: 4px;
`;

export const Badge = styled.div<{ backgroundColor: string }>`
  background-color: ${(props) => (props.backgroundColor)};
  position: absolute;
  margin-top: 10px;
  margin-left: 10px;
  border-radius: 80px;
  width: 80px;
  height: 20px;
`;

export const BadgeText = styled.div`
  color: white;
  ${CommonTextStyles}
  font-size: 10px;
  line-height: 20px;
  text-align: center;
`;

export const HomeImage = styled.img`
  width: 100%;
  height: 100%;
  min-width: 160px;

  @media only screen and (${mediaBreakpointDown.md}) {
    height: 260px;
  }
`;

export const AltImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-width: 160px;
  background-color: ${colorBaseTealLight};
  display: flex;
  justify-content: center;

  @media only screen and (${mediaBreakpointDown.md}) {
    height: 260px;
  }
`;

export const AltImage = styled.img`
  background-color: ${colorBaseTealLight};
  width: 40%;
  height: 40%;
  margin: auto;
`;

export const LogoImage = styled.img`
  background-color: transparent;
`;

export const CheckmarkImage = styled.img`
  background-color: transparent;
  padding-right: 4px;
`;

export const PracticeIcon = styled.img`
  background-color: ${colorPaletteSand200};
  width: 60%;
  height: 60%;
  margin-top: 20px;
  max-height: 150px;
`;

export const PracticeIconWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-width: 160px;
  background-color: ${colorPaletteSand200};
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (${mediaBreakpointDown.md}) {
    height: 260px;
  }
`;
