import React from 'react';
import { Tooltip } from 'react-tooltip';
import './styles.scss';
import InfoOutlinedIcon from 'haven-design-system/build/assets/svgs/display/info_outlined.svg?component';
import PayIcon from 'haven-design-system/build/assets/svgs/display/pay.svg?component';
import { InspectionServiceProps } from './types';

const InspectionService: React.FC<InspectionServiceProps> = ({
  inspectionService, onClick,
}) => {
  const buttonClass = (inspectionService.active || inspectionService.recentlyEdited)
    ? 'inspectionServiceContainer--active' : 'inspectionServiceContainer';

  return (
    <button
      className={buttonClass}
      type="button"
      tabIndex={0}
      onClick={onClick}
    >
      {inspectionService.formattedName}
      <div className="inspectionServiceIconContainer">
        {inspectionService.allowCustomPricing && (
        <PayIcon />
        )}
        {inspectionService.description.length > 0 && (
        <div
          id={inspectionService.tooltipId}
          role="button"
          tabIndex={0}
          onClick={(e) => e.stopPropagation()}
          onKeyPress={() => {}}
        >
          <InfoOutlinedIcon />
        </div>
        )}
      </div>
      {inspectionService.description.length > 0 && (
        <Tooltip
          anchorSelect={`#${inspectionService.tooltipId}`}
          place="bottom"
          className="inspectionServiceTooltip"
          content={inspectionService.description}
        />
      )}
    </button>
  );
};

export default InspectionService;
