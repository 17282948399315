import { IdRef, ReportSeverityType, ReportSystemNested } from 'marketplace-common';

export interface Project {
  id: string,
  propertyId: number,
  teamId: number,
  reportId: string,
  name: string,
  workItems: string[],
  publishedAt: number | null,
  teamMarketId?: string | null,
}

export interface ProjectsInterface {
  [id: string]: Project,
}

export interface TeamMarket {
  id: string,
  name: string,
  stateAbbreviation: string
}

export interface TeamMarketInterface {
  [id: string]: TeamMarket,
}

export interface TeamCustomSystem {
  id: string,
  teamId: string,
  name: string,
  subsystemNames: string[],
  displayIndex: number,
}

export interface TeamCustomSystemsInterface {
  [id: string]: TeamCustomSystem,
}

export interface WorkItem {
  id: string,
  systemName: string,
  subsystemName?: string | null,
  title: string,
  details: string
  unit: string | null,
  quantity: number | null,
  displayIndex?: number | null,
  pricePerUnit: number | null,
  totalPrice: number | null,
  projectId: string,
  tags: string[],
  templateWorkItemPricingId: string | null,
  pricingExternalId: string | null,
  workItemAssociations: string[],
  workItemAttachments: string[],
  workItemContributors?: WorkItemContributorType[],
}

export interface WorkItemsInterface {
  [id: string]: WorkItem,
}

export interface RehabDeficiencyData {
  id: string | null,
  reportSubsystemId: string | null,
  catalogDeficiencyId: string | null,
  reportInformationId: string | null,
  note: string | null,
  title: string | null,
  detail: string | null,
  severity: ReportSeverityType | null,
}

export interface RehabInformationData {
  id: string | null,
  reportSubsystemId: string | null,
  catalogInformationId: string | null,
  note: string | null,
  title: string | null,
  dataName: string | null,
  detail: string | null,
  value: string | null,
  units: string | null,
  questionType: string | number | boolean | null,
  cannotAnswer: boolean | null,
  allowsOther: boolean | null,
  requiresLocations: boolean | null,
  requiresMedias: number | null,
  isMultiSelect: boolean | null,
  requiresDeficiencyCondition: string | null,
  requiresDeficiencyCatalogDeficiencyId: string | null,
  options: string[] | null,
}

export interface RehabLimitationData {
  id: string | null,
  reportSubsystemId: string | null,
  catalogLimitationId: string | null,
  note: string | null,
  title: string | null,
  detail: string | null,
}

export interface RehabSubsystemData {
  id: string | null,
  reportSystemId: string | null,
  catalogSubsystemId: string | null,
  note: string | null,
  name: string | null,
  status: string | null,
}

export interface RehabAttachmentData {
  id: string | null,
  itemType: string | null,
  itemId: string | null,
  s3ObjectKey: string | null,
  fileName: string | null,
}

export enum RehabItemType {
  ReportDeficiency = 'ReportDeficiency',
  ReportInformation = 'ReportInformation',
  ReportLimitation = 'ReportLimitation',
  ReportSubsystem = 'ReportSubsystem',
  ReportAttachment = 'ReportAttachment',
}

export interface WorkItemAssociation {
  id: string,
  itemId: string,
  itemType: RehabItemType,
  workItemId: string,
  data: RehabDeficiencyData |
  RehabInformationData |
  RehabLimitationData |
  RehabSubsystemData |
  RehabAttachmentData,
}

export interface WorkItemAssociationsMap {
  [key: string]: WorkItemAssociation[];
}

export interface WorkItemAssociationsInterface {
  [id: string]: WorkItemAssociation,
}

export interface WorkItemAttachment {
  id: string,
  workItemId: string,
  workItemAssociationId: string | null,
  s3ObjectKey: string | null,
  attachmentUrl: string | null,
  thumbnailUrl: string | null,
  resizedUrls?: {
    url: string,
    width: string,
  }[] | null,
}

export interface WorkItemAttachmentsInterface {
  [id: string]: WorkItemAttachment,
}

export interface WorkItemContributorType {
  amount: string,
  createdAt?: number,
  id: IdRef,
  name: string,
  updatedAt?: number,
  workItemId?: IdRef,
  isPreExisting?: boolean,
}
export interface WorkItemContributors {
  workItemContributors : WorkItemContributorType[]
}

export interface TemplateWorkItemPricing {
  id: string,
  templateWorkItemId: string,
  pricingExternalId: string,
  details: string,
  unit: string,
  pricePerUnit: number,
  lumpSumPrice: number,
  teamMarketId?: string | null,
  tags: string[],
}

export interface TemplateWorkItem {
  id: string,
  teamId: number,
  systemName: string | null | undefined,
  title: string,
  createdAt: number,
  updatedAt: number,
  templateWorkItemPricings: string[],
}

export interface TemplateWorkItemsInterface {
  [id: string]: TemplateWorkItem,
}

export interface TemplateWorkItemPricingsInterface {
  [id: string]: TemplateWorkItemPricing,
}

export type ProjectHistoryCard = {
  id: IdRef,
  itemType: ProjectHistoryItemType,
  itemId: IdRef,
  eventField: string | null | undefined,
  user: string,
  createdAt: number,
  title?: string | null,
  newValue?: string | number | null,
  changeDirection?: ProjectHistoryChangeDirectionType | string | null,
  oldValue?: string | number | null,
};

export type ProjectHistoryCardsByDate = {
  date: string,
  projectHistoryCards: ProjectHistoryCard[];
};

export enum ProjectHistoryItemType {
  Project = 'Rehab::Project',
  WorkItem = 'Rehab::WorkItem',
}

export enum ProjectHistoryEventType {
  create = 'create',
  update = 'update',
  delete = 'delete',
}

export enum ProjectHistoryChangeDirectionType {
  increased = 'Increased from',
  decreased = 'Decreased from',
  previously = 'Previously',
  lumpSum = 'Changed from unit cost',
}

export enum ProjectHistoryFieldType {
  projectCreated = 'Project was created',
  projectUpdated = 'Project was updated',
  workItemAdded = 'Work item was added',
  workItemRemoved = 'Work item was removed',
  quantity = 'Quantity',
  unitCost = 'Unit cost',
  totalCost = 'Total cost',
  unit = 'Unit',
  system = 'System',
  subsystem = 'Location',
  title = 'Title',
  description = 'Description',
  lumpSum = 'Lump sum',
  default = '',
}

export type ProjectHistoryObjectChanges = {
  fieldName: string,
  oldValue: string | null | undefined,
  newValue: string | null | undefined,
};

export enum ObjectChangesFieldName {
  quantity = 'quantity',
  unitCost = 'price_per_unit',
  totalCost = 'total_price',
  unit = 'unit',
  system = 'system_name',
  location = 'subsystem_name',
  title = 'title',
  description = 'details',
  updatedAt = 'updated_at',
  createdAt = 'created_at',
}

export type ProjectHistoryUser = {
  id: string,
  name: string | null | undefined,
  email: string | null | undefined,
};

export interface RawTemplateWorkItemPricing {
  id: string
  templateWorkItemId: string
  pricingExternalId: string
  details: string
  unit: string
  pricePerUnit: number
  lumpSumPrice: number
}

export interface RawTemplateWorkItem {
  id: string
  teamId: number
  systemName: string
  title: string
  createdAt: number
  updatedAt: number
  templateWorkItemPricings: RawTemplateWorkItemPricing[]
}

export type ProjectHistoryItem = {
  id: IdRef,
  itemType: ProjectHistoryItemType,
  itemId: IdRef,
  event: ProjectHistoryEventType,
  createdAt: number,
  objectChanges: ProjectHistoryObjectChanges[],
  user: ProjectHistoryUser,
};

export interface ProjectHistory {
  [projectWorkHistoryId: string]: ProjectHistoryItem,
}

export interface WorkHistory {
  [projectId: string]: ProjectHistory,
}

export interface Rehab {
  projects: ProjectsInterface,
  rehabTeamMarkets: TeamMarketInterface,
  rehabTeamCustomSystems: TeamCustomSystemsInterface,
  rehabReportSystems: ReportSystemNested[];
  workItems: WorkItemsInterface,
  workItemAssociations: WorkItemAssociationsInterface,
  workItemAttachments: WorkItemAttachmentsInterface,
  templateWorkItems: TemplateWorkItemsInterface,
  templateWorkItemPricings: TemplateWorkItemPricingsInterface,
  workHistory: WorkHistory,
}
