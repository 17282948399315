import React from 'react';
import '../../styles.scss';
import { AccessAndSoftwareSectionProps } from './types';
import InformationSection from '../../../../components/InformationSection';
import { ContractorTypeEnum } from '../../../../types';
import { OnboardingFormQuestions } from '../../../Onboarding/OnboardingFormPage/types';
import { BusinessDetailsText, ReportWritingSoftwareEnum } from '../types';

const AccessAndSoftwareSection: React.FC<AccessAndSoftwareSectionProps> = ({
  sectionRef,
  title,
  onboardingContractorType,
  hasSupra,
  setHasSupra,
  hasSentrilock,
  setHasSentriLock,
  reportWritingSoftware,
  setReportWritingSoftware,
}) => (
  <InformationSection
    title={title}
    sectionRef={sectionRef}
  >
    <div className="userProfile__section__inputRow">
      <div className="userProfile__section__inputRow__inputContainer">
        <div className="userProfile__section__inputRow__inputContainer__label">
          {BusinessDetailsText.SupraAccess}
        </div>
        <label htmlFor="supra_yes" className="radioButtonContainer">
          <input
            type="radio"
            id="supra_yes"
            className="radioButton"
            name="supra"
            checked={hasSupra}
            onChange={(e) => setHasSupra(e.target.checked)}
          />
          <div className={hasSupra ? 'responseTextSelected' : 'responseText'}>
            {OnboardingFormQuestions.Yes}
          </div>
        </label>
        <label htmlFor="supra_no" className="radioButtonContainer">
          <input
            type="radio"
            id="supra_no"
            className="radioButton"
            name="supra"
            checked={hasSupra === false}
            onChange={(e) => setHasSupra(!e.target.checked)}
          />
          <div className={hasSupra === false ? 'responseTextSelected' : 'responseText'}>
            {OnboardingFormQuestions.No}
          </div>
        </label>
      </div>
    </div>
    <div className="userProfile__section__inputRow">
      <div className="userProfile__section__inputRow__inputContainer">
        <div className="userProfile__section__inputRow__inputContainer__label">
          {BusinessDetailsText.SentriLockAccess}
        </div>
        <label htmlFor="sentrilock_yes" className="radioButtonContainer">
          <input
            type="radio"
            id="sentrilock_yes"
            className="radioButton"
            name="sentrilock"
            checked={hasSentrilock}
            onChange={(e) => setHasSentriLock(e.target.checked)}
          />
          <div className={hasSentrilock ? 'responseTextSelected' : 'responseText'}>
            {OnboardingFormQuestions.Yes}
          </div>
        </label>
        <label htmlFor="sentrilock_no" className="radioButtonContainer">
          <input
            type="radio"
            id="sentrilock_no"
            className="radioButton"
            name="sentrilock"
            checked={hasSentrilock === false}
            onChange={(e) => setHasSentriLock(!e.target.checked)}
          />
          <div className={hasSentrilock === false ? 'responseTextSelected' : 'responseText'}>
            {OnboardingFormQuestions.No}
          </div>
        </label>
      </div>
    </div>
    {onboardingContractorType === ContractorTypeEnum.Inspector && (
      <div className="userProfile__section__inputRow__inputContainer">
        <div className="userProfile__section__inputRow__inputContainer__label">
          {BusinessDetailsText.ReportWritingSoftware}
        </div>
        <select
          className="userProfile__section__inputRow__inputContainer__input"
          value={reportWritingSoftware}
          name="report_writing_software"
          onChange={(e) => setReportWritingSoftware(e.target.value as ReportWritingSoftwareEnum)}
        >
          <option key="blank">Select</option>
          {Object.values(ReportWritingSoftwareEnum).map((software: ReportWritingSoftwareEnum) => (
            <option key={software}>{software}</option>
          ))}
        </select>
      </div>
    )}
  </InformationSection>
);

export default AccessAndSoftwareSection;
