export interface UserPermissionProps {
  adminPermission: boolean;
  offersPermission: boolean;
  pricingPermission: boolean;
  handleUpdatePermission: (permissionName: string, value: boolean) => void;
}

export enum PermissionsSections {
  Title = 'Permissions',
  Description = 'Determines what the user can see within the Inspectify app.',
  CallInspectify = 'Call Inspectify at 1 (855) 271-6876 to change this permission.',
  AdminDescription = 'The user will be able to add, remove, and edit users within the dashboard. They will also be able view payment information and invoices.',
  OffersDescription = 'The user will be able to receive push notifications in regards to new job offers, as well as accept or decline new offers available in the app.',
  PricingDescription = 'The user will be able to view pricing for inspections within the Inspectify App.',
}
