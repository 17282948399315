import React from 'react';
import './styles.scss';
import CloseIcon from 'haven-design-system/build/assets/svgs/action/close.svg?component';
import { PricingModalText, ServicePricingModalProps } from './types';
import Button from '../../../../../components/Button';
import Checkbox from '../../../../../components/Checkbox';

const InspectionServicePricingModal: React.FC<ServicePricingModalProps> = ({
  isOpen,
  closeModal,
  inspectionServiceName,
  customPricing,
  setCustomPricing,
  serviceIsSubcontracted,
  setServiceIsSubcontracted,
  savePricing,
  savePricingButtonDisabled,
}) => (
  <>
    {isOpen ? (
      <div className="servicePricingModalBackdrop" aria-hidden="true" onClick={closeModal}>
        <div className="servicePricingModalContainer" aria-hidden="true" onClick={(e) => e.stopPropagation()}>
          <div className="servicePricingModalHeader">
            {`Add ${inspectionServiceName} pricing`}
            <Button onClick={closeModal} className="closeButtonBlack">
              <CloseIcon />
            </Button>
          </div>
          {PricingModalText.Instructions}
          <textarea
            rows={6}
            placeholder={PricingModalText.ExampleInput}
            value={customPricing}
            onChange={(e) => setCustomPricing(e.target.value)}
          />
          <Checkbox
            isChecked={serviceIsSubcontracted}
            toggle={() => setServiceIsSubcontracted(!serviceIsSubcontracted)}
            label={PricingModalText.Subcontracted}
          />
          {PricingModalText.Closing}
          <div className="servicePricingModalActions">
            <Button onClick={closeModal} secondary>
              Cancel
            </Button>
            <Button
              onClick={savePricing}
              disabled={savePricingButtonDisabled}
            >
              Add
            </Button>
          </div>
        </div>
      </div>
    ) : null}
  </>
);

export default InspectionServicePricingModal;
