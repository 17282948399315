import { useMutation } from '@apollo/client';
import { UPDATE_INSPECTION_SCOPE_ITEM } from 'marketplace-common';
import React, { ContextType, useContext, useMemo } from 'react';
import { Spinner, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { captureException } from '../../../../utils/error';
import { ReportContext } from '../../context';
import DrawRow from './DrawRow';
import DrawTotalRow from './DrawTotalRow';
import { EmptyTableState } from './EmptyTableState';
import styles from './styles.module.scss';
import { DrawBudgetRowData, DrawInspectionBudgetSummaryProps } from './types';

const DrawInspectionBudgetSummary: React.FC<DrawInspectionBudgetSummaryProps> = ({
  displayWithTotal = true,
  editable = false,
  isPrint = false,
}) => {
  const context = useContext(ReportContext);
  // TODO: REHAB DOESN'T LIKE THIS
  const {
    state: { drawBudget, selectedReport, order },
    setDrawInspectionBudgetLoading,
    updateDrawInspectionBudget,
  } = context || {} as ContextType<typeof ReportContext>;

  const displayDrawDisbursementFields = useMemo(() => (
    order?.team?.includeDrawDisbursementFields || false
  ), [order?.team?.includeDrawDisbursementFields]);

  const displayFinancialColumns = useMemo(() => (
    (drawBudget || []).some(({ units, item }) => item !== 'Total' && units.some((unit) => unit.budget > 0))
  ), [drawBudget]);

  const displayUnitColumns = useMemo(() => (
    Object.keys(selectedReport?.systemGroups || {})?.length > 1
  ), [selectedReport]);

  const [colSpan, currentDrawColSpan] = useMemo(() => {
    if (displayFinancialColumns && displayDrawDisbursementFields) {
      return [displayUnitColumns ? 6 : 5, 2];
    }
    if (displayFinancialColumns) return [displayUnitColumns ? 5 : 4, 1];
    return [displayUnitColumns ? 3 : 2, 1];
  }, [displayFinancialColumns, displayUnitColumns]);

  const [updateDrawBudget, { loading, error }] = useMutation(UPDATE_INSPECTION_SCOPE_ITEM);

  const handleChange = async (value: number, name: string, data: DrawBudgetRowData) => {
    const [type, i] = name.split('-');

    const index = parseInt(i, 10);
    const datum = data.units[index];
    if (value === datum[type]) return;

    try {
      datum[type] = value;
      setDrawInspectionBudgetLoading(drawBudget, data, index, type, true);

      // these calculations need to be sent to the server first
      if (type === 'previousDisbursement' || type === 'budget') {
        datum.clientDisbursement = datum.budget * (datum.clientProgress || datum.progress) - datum.previousDisbursement;
        datum.clientDisbursementIsDirty = !(datum.clientDisbursement !== datum.disbursement);
      }

      if (type === 'clientDisbursement') {
        datum.clientDisbursementIsDirty = true;
      }

      // eslint-disable-next-line no-param-reassign
      data.units[index] = datum;
      const newScopeItem = updateDrawInspectionBudget(drawBudget, data, index);

      const response = await updateDrawBudget({
        variables: {
          input: {
            id: newScopeItem.id,
            progress: newScopeItem.clientProgress,
            budget: newScopeItem.budget,
            disbursement: newScopeItem.disbursement,
            clientDisbursement: newScopeItem.clientDisbursement,
            previousDisbursement: newScopeItem.previousDisbursement,
          },
        },
      });
      if (response.data?.updateInspectionScopeItem) {
        setDrawInspectionBudgetLoading(drawBudget, data, index, type, false);
      }
    } catch (e) {
      toast.error('An error occurred while updating the inspection scope item.');
      captureException(e, { source: 'DrawInspectionBudgetSummary', name, type, value, unitId: datum.id });
      setDrawInspectionBudgetLoading(drawBudget, data, index, type, false);
    }
  };

  const hasData = Object.values(drawBudget).length;
  return (
    <div className={styles.container}>
      <h4>Inspection Scope</h4>
      <Table className={styles.table} hover={!isPrint}>
        <thead className={styles.alignCenter}>
          <tr>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <th colSpan={colSpan} />
            <th colSpan={currentDrawColSpan} className={styles.currentDraw}>
              Current Draw
            </th>
          </tr>
          <tr>
            <th className={styles.borderBottom} scope="col" style={{ minWidth: 150 }}>
              Item
            </th>
            <th className={styles.borderBottom} scope="col" style={{ minWidth: 172 }}>
              Description
            </th>
            {displayUnitColumns && (
              <th className={styles.borderBottom} scope="col" style={{ minWidth: 80 }}>
                Units
              </th>
            )}
            {displayFinancialColumns && (
              <th className={styles.borderBottom} scope="col" style={{ minWidth: 100 }}>
                Item
                Budget
              </th>
            )}
            {displayFinancialColumns && displayDrawDisbursementFields && (
              <th className={styles.borderBottom} scope="col" style={{ maxWidth: 120 }}>
                Previous
                Disbursement
              </th>
            )}
            {displayFinancialColumns && (
              <th className={styles.borderBottom} scope="col" style={{ minWidth: 100 }}>
                Remaining Balance
              </th>
            )}
            <th className={styles.bottomCellSeparator} scope="col" style={{ minWidth: 82 }}>
              Progress
            </th>
            {displayFinancialColumns && displayDrawDisbursementFields && (
              <th className={styles.borderBottom} scope="col" style={{ minWidth: 110 }}>
                Disbursement
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {!hasData && loading && (
            <tr>
              <td colSpan={7} className={styles.loading}>
                <Spinner animation="border" />
              </td>
            </tr>
          )}

          {(!hasData && (!loading || error)) && (
            <tr>
              <td colSpan={7} className={styles.loading}>
                <EmptyTableState />
              </td>
            </tr>
          )}

          {drawBudget.map((row) => (
            <DrawRow
              key={`row-${row.units[0].id}`}
              data={row}
              editable={editable}
              onChange={handleChange}
              displayFinancialColumns={displayFinancialColumns}
              displayDrawDisbursementFields={displayDrawDisbursementFields}
              displayUnitColumns={displayUnitColumns}
            />
          ))}
        </tbody>
        {displayWithTotal && hasData > 0 && (
          <DrawTotalRow
            data={drawBudget}
            displayFinancialColumns={displayFinancialColumns}
            displayDrawDisbursementFields={displayDrawDisbursementFields}
            displayUnitColumns={displayUnitColumns}
          />
        )}
      </Table>
      {isPrint && (
        <div className={styles.pageBreak} />
      )}
    </div>
  );
};

export default DrawInspectionBudgetSummary;
