import { getUnixTime } from 'date-fns';
import {
  getFormattedInspectionTypes,
  getJobStatus,
  IdRef,
  InspectionProposal,
  Job,
  JobStatus,
  LocalInspectionProposal,
} from 'marketplace-common';
import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import JobRow from './JobRow';
import { getFormattedDate, getFormattedDateTime } from '../../../../utils/date';
import { InspectorDashboardContext } from '../../context';
import { DashboardPageTab } from '../../types';
import { useTypedSelector } from '../../../../redux/store';
import { selectWorks } from '../../../../redux/selectors';

interface Props {
  job: Job;
}

const JobRowLoader: React.FC<Props> = ({
  job,
}) => {
  const {
    inspections, orders, properties, reports: records, inspectionProposals,
  } = useTypedSelector(selectWorks);

  const { setPage } = useContext(InspectorDashboardContext);
  const order = orders[job.orderId];
  const property = properties && order && order.propertyId
    ? properties[order.propertyId]
    : null;
  const [inspectionTypes, setInspectionTypes] = useState('');
  const [scheduledInspection, setScheduledInspection] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [isClickable, setIsClickable] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [jobStatus, setJobStatus] = useState(job.status);
  const [scheduledProposals, setScheduledProposals] = useState<InspectionProposal[]>();

  const isAppRequired = useMemo(() => job.inspectionIds.some((id) => inspections[id].appRequirement === 'required'), [job, inspections]);

  const onUpload = (event: any) => {
    event.stopPropagation();
    setPage(DashboardPageTab.Jobs, {
      orderId: job.orderId,
      scheduledTime: job.scheduledTime,
      status: job.status,
      reportUpload: true,
    });
  };

  const onClickCard = (event: any) => {
    event.stopPropagation();
    setPage(DashboardPageTab.Jobs, {
      orderId: job.orderId,
      scheduledTime: job.scheduledTime,
      status: job.status,
    });
  };

  const handleClick = (event: any) => {
    if (buttonText === 'Upload report') onUpload(event);
  };

  useEffect(() => {
    if (inspections && job && job.inspectionIds) {
      setInspectionTypes(getFormattedInspectionTypes(inspections, job.inspectionIds, job.isMock));
    }
  }, [inspections, job.inspectionIds, job.isMock]);

  useEffect(() => {
    if (job && job.scheduledTime) {
      if (scheduledProposals && scheduledProposals.find((p) => p.isGoAnytime)) {
        setScheduledInspection(`Complete by ${getFormattedDate(job.scheduledTime)} - Anytime`);
      } else {
        setScheduledInspection(getFormattedDateTime(job.scheduledTime));
      }
    }
  }, [job.scheduledTime, scheduledProposals]);

  useEffect(() => {
    if (job && records) setJobStatus(getJobStatus(job, records));
    if (inspectionTypes === 'Cancellation fee') setJobStatus(JobStatus.Cancelled);
    const proposalIds: IdRef[] = [];
    job.inspectionIds.forEach((ins) => {
      if (inspections[ins]?.scheduledProposalId) {
        proposalIds.push(inspections[ins].scheduledProposalId);
      }
    });
    const proposals: LocalInspectionProposal[] = [];
    proposalIds.forEach((id) => {
      if (inspectionProposals[id]) proposals.push(inspectionProposals[id]);
    });
    setScheduledProposals(proposals);
  }, [job, records, inspections, inspectionTypes]);

  const addButtonAttributes = () => {
    switch (jobStatus) {
      case JobStatus.Cancelled:
        setButtonText('Cancelled');
        setIsClickable(false);
        setIsHidden(true);
        break;
      case JobStatus.Completed:
        setButtonText('Complete');
        setIsClickable(false);
        break;
      case JobStatus.InProgress:
        if (isAppRequired) setButtonText('App Required');
        setIsClickable(false);
        break;
      case JobStatus.Scheduled:
        if (isAppRequired) {
          setButtonText('App Required');
          setIsClickable(false);
        } else {
          const endOfToday = new Date().setUTCHours(23, 59, 59);
          if (job.scheduledTime <= getUnixTime(endOfToday)) {
            setButtonText('Upload report');
            setIsClickable(true);
          } else {
            setIsClickable(false);
          }
        }
        break;
      default:
        setIsClickable(false);
        break;
    }
  };

  useEffect(() => {
    if (job && job.status) addButtonAttributes();
  }, [job, job.status, jobStatus, isAppRequired]);

  if (isHidden) return null;

  return (
    <JobRow
      onCardClick={onClickCard}
      isClickable={isClickable}
      jobStatus={jobStatus}
      property={property}
      scheduledInspection={scheduledInspection}
      inspectionTypes={inspectionTypes}
      buttonText={buttonText}
      isAppRequired={isAppRequired}
      handleClick={handleClick}
      isMock={job.isMock}
    />
  );
};

export default JobRowLoader;
