import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import Auth, { CognitoHostedUIIdentityProvider, CognitoUser } from '@aws-amplify/auth';
import { useLazyQuery } from '@apollo/client';
import { DEPRECATED, client } from '../../graphql/createApolloClient';
import { CONTRACTOR_ALLOW_INITIAL_USER_CREATION } from '../../graphql/queries';
import { useUrlQuery } from '../../utils/hooks';
import InspectifyLayout from '../../components/InspectifyLayout';
import { AuthPageState, PageOrigin } from './types';
import ResetPasswordRequestPage from './ResetPasswordRequestPage';
import SignInPage from './SignInPage';
import ResetPasswordPage from './ResetPasswordPage';
import SetNewPasswordPage from './SetNewPasswordPage';
import InspectorSignUpPage from './InspectorSignUpPage';
import VerifyTempPasswordPage from './VerifyTempPasswordPage';
import { FETCH_AVAILABLE_USERS } from '../../graphql/queries/user';
import { captureException } from '../../utils/error';
import { TeamUser } from '../../types';

const SignInLoader: React.FC = () => {
  const query = useUrlQuery();
  const nextPath = query.get('next');
  const contractorToken = query.get('contractor_token');
  const projectId = query.get('project_id');
  const history = useHistory();

  const [screenState, setScreenState] = useState(AuthPageState.SignIn);
  const [resetPasswordEmail, setResetPasswordEmail] = useState(null as string | null);
  const [signUpEmail, setSignUpEmail] = useState(null as string | null);
  const [signedInUser, setSignedInUser] = useState(null as CognitoUser | null);

  const pageOrigin = useMemo(() => {
    if ((nextPath || '').startsWith('/rehab/')) {
      return PageOrigin.RehabTool;
    }
    if ((nextPath || '').startsWith('/dashboard/')) {
      return PageOrigin.InspectorDashboard;
    }
    return null;
  }, []);

  const getRedirectOnSuccess = () => {
    if (pageOrigin === PageOrigin.RehabTool && projectId) {
      return `/rehab/${projectId}`;
    }
    if (nextPath) {
      return nextPath;
    }
    return '/';
  };

  const handleSignInSuccess = async () => {
    const { data, errors } = await client.query(
      {
        query: FETCH_AVAILABLE_USERS,
        variables: { onlyContractors: false },
      },
    );

    if (errors) {
      captureException(errors, { source: 'Loading available users' });
    } else {
      localStorage.setItem('inspectifySelectedUser', data.availableUsers?.[0]?.id);
      localStorage.setItem('inspectifySelectedTeamIds', data.availableUsers?.[0]?.teamUsers?.map((teamUser: TeamUser) => teamUser?.team?.id).join(',') || []);
    }

    history.push(getRedirectOnSuccess());
    window.location.reload(); // Need this to load data properly on redirect
  };

  const handleGoogleSignIn = async () => {
    // Amplify Social SignIn - https://docs.amplify.aws/lib/auth/social/q/platform/js/
    await Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
      customState: getRedirectOnSuccess(), // Redirect Google signin success
    });
  };

  const displayScreen = () => {
    switch (screenState) {
      case AuthPageState.SignIn:
        return (
          <SignInPage
            pageOrigin={pageOrigin}
            onSignInSuccess={handleSignInSuccess}
            onGoogleSignIn={handleGoogleSignIn}
            onForgotPassword={() => {
              setScreenState(AuthPageState.ResetPasswordRequest);
            }}
            onNewPasswordRequired={(user: CognitoUser) => {
              setSignedInUser(user);
              setScreenState(AuthPageState.SetNewPassword);
            }}
          />
        );
      case AuthPageState.ResetPasswordRequest:
        return (
          <ResetPasswordRequestPage
            onSendResetPasswordEmailSuccess={(email) => {
              setResetPasswordEmail(email);
              setScreenState(AuthPageState.ResetPassword);
            }}
          />
        );
      case AuthPageState.ResetPassword:
        return (
          <ResetPasswordPage
            email={resetPasswordEmail}
            onResetPasswordSuccess={handleSignInSuccess}
          />
        );
      case AuthPageState.SetNewPassword:
        return (
          <SetNewPasswordPage
            signedInUser={signedInUser}
            onSetNewPasswordSuccess={handleSignInSuccess}
          />
        );
      case AuthPageState.InspectorSignUp:
        return (
          <InspectorSignUpPage
            contractorToken={contractorToken}
            onSendTemporaryPasswordEmailSuccess={(email) => {
              setSignUpEmail(email);
              setScreenState(AuthPageState.VerifyTempPassword);
            }}
          />
        );
      case AuthPageState.VerifyTempPassword:
        return (
          <VerifyTempPasswordPage
            email={signUpEmail}
            onSignInSuccess={handleSignInSuccess}
            onTemporaryPasswordSuccess={(user: CognitoUser) => {
              setSignedInUser(user);
              setScreenState(AuthPageState.SetNewPassword);
            }}
          />
        );
      default:
        return null;
    }
  };

  const [getContractorAllowsInitialUserCreation] = useLazyQuery(
    CONTRACTOR_ALLOW_INITIAL_USER_CREATION, {
      context: { DEPRECATED },
      onCompleted: (data) => {
        if (data.contractorAllowInitialUserCreation) {
          setScreenState(AuthPageState.InspectorSignUp);
        } else {
          setScreenState(AuthPageState.SignIn);
        }
      },
    },
  );

  useEffect(() => {
    if (contractorToken) {
      getContractorAllowsInitialUserCreation({
        variables: { token: contractorToken },
      });
    }
  }, [contractorToken]);

  return (
    <>
      <Helmet>
        <title>Inspectify Sign In</title>
      </Helmet>
      <InspectifyLayout showSignIn={false} showFooter />
      {displayScreen()}
    </>
  );
};

export default SignInLoader;
