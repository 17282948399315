import React from 'react';
import './styles.scss';
import ThinkingImage from '../../../assets/images/thinkingImage.svg';
import { BusinessDetailsProps, BusinessDetailsSection, BusinessDetailsText } from './types';
import Button from '../../../components/Button';
import Spinner from '../../../components/Spinner';
import BasicInformationSection from './BusinessDetailsSections/BasicInformationSection';
import CertificationsSection from './BusinessDetailsSections/CertificationsSection';
import ServiceableAreaSection from './BusinessDetailsSections/ServiceableAreaSection';
import AccessAndSoftwareSection from './BusinessDetailsSections/AccessAndSoftwareSection';
import DocumentsSection from './BusinessDetailsSections/DocumentsSection';
import InspectionServicesSection from './BusinessDetailsSections/InspectionServicesSection';
import ConfirmationModal from '../../../components/ConfirmationModal/ConfirmationModal';
import { ContractorTypeEnum } from '../../../types';

const BusinessDetails: React.FC<BusinessDetailsProps> = ({
  renderSectionContent,
  updateButtonDisabled,
  setBusinessDetailsEdited,
  confirmationModalIsOpen,
  setConfirmationModalIsOpen,
  handleConfirm,
  currentSection,
  loading,
  errorUpdating,
  basicInformationRef,
  inspectionServicesRef,
  certificationsRef,
  serviceableAreaRef,
  accessAndSoftwareRef,
  documentsRef,
  misaFiles,
  certificationFiles,
  uploadInsuranceCertificate,
  loadingNewFile,
  inspectionServices,
  contractorPricingInputs,
  setContractorPricingInputs,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  businessName,
  setBusinessName,
  website,
  setWebsite,
  phoneNumber,
  setPhoneNumber,
  street,
  setStreet,
  city,
  setCity,
  state,
  setState,
  zipCode,
  setZipCode,
  showEmailError,
  setShowEmailError,
  showPhoneError,
  setShowPhoneError,
  showZipCodeError,
  setShowZipCodeError,
  addressRef,
  serviceAreaRadiusInMiles,
  setServiceAreaRadiusInMiles,
  hasSupra,
  setHasSupra,
  hasSentrilock,
  setHasSentriLock,
  reportWritingSoftware,
  setReportWritingSoftware,
  isMobile,
  stateLicenseNumber,
  setStateLicenseNumber,
  ashiLicenseNumber,
  setAshiLicenseNumber,
  internachiLicenseNumber,
  setInternachiLicenseNumber,
  otherLicenseNumber,
  setOtherLicenseNumber,
  inspectorLicenseHeld,
  setInspectorLicenseHeld,
  contractorLicenseHeld,
  setContractorLicenseHeld,
  realEstateLicenseHeld,
  setRealEstateLicenseHeld,
  msaSigned,
  updateBusinessDetails,
  onboardingContractorType,
  businessContactName,
  setBusinessContactName,
  schedulerName,
  setSchedulerName,
  schedulerEmail,
  setSchedulerEmail,
  schedulerPhoneNumber,
  setSchedulerPhoneNumber,
  showSchedulerPhoneError,
  setShowSchedulerEmailError,
  showSchedulerEmailError,
  setShowSchedulerPhoneError,
}) => (isMobile ? (
  <>
    <ConfirmationModal
      opened={confirmationModalIsOpen}
      header="Are you sure?  Your work will not be saved."
      backButton="Back"
      confirmButton="Yes, I want to leave"
      onBack={() => setConfirmationModalIsOpen(false)}
      onConfirm={handleConfirm}
      imageUrl={ThinkingImage}
    />
    {renderSectionContent(currentSection)}
  </>
) : (
  <>
    <div className="businessDetailsHeaderContainer">
      <div className="userManagement__manageUsers__header__text">
        Business Details
      </div>
      <div className="businessDetailsUpdateButtonContainer">
        <div className="businessDetailsUpdateButton">
          <Button
            onClick={updateBusinessDetails}
            disabled={updateButtonDisabled}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          >
            {loading ? <Spinner /> : 'Update'}
          </Button>
        </div>
        {errorUpdating && (
          <div className="businessDetailsErrorMessage">
            {BusinessDetailsText.Error}
          </div>
        )}
      </div>
    </div>
    <div className="userManagement__manageUsers" onChange={() => setBusinessDetailsEdited(true)}>
      <ConfirmationModal
        opened={confirmationModalIsOpen}
        header="Are you sure?  Your work will not be saved."
        backButton="Back"
        confirmButton="Yes, I want to leave"
        onBack={() => setConfirmationModalIsOpen(false)}
        onConfirm={handleConfirm}
        imageUrl={ThinkingImage}
      />
      <div className="businessDetailsContainer">
        <BasicInformationSection
          title={BusinessDetailsSection.BasicInformation}
          sectionRef={basicInformationRef}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          businessName={businessName}
          setBusinessName={setBusinessName}
          website={website}
          setWebsite={setWebsite}
          businessContactName={businessContactName}
          setBusinessContactName={setBusinessContactName}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          email={email}
          setEmail={setEmail}
          schedulerName={schedulerName}
          setSchedulerName={setSchedulerName}
          schedulerEmail={schedulerEmail}
          setSchedulerEmail={setSchedulerEmail}
          schedulerPhoneNumber={schedulerPhoneNumber}
          setSchedulerPhoneNumber={setSchedulerPhoneNumber}
          street={street}
          setStreet={setStreet}
          city={city}
          setCity={setCity}
          state={state}
          setState={setState}
          zipCode={zipCode}
          setZipCode={setZipCode}
          showZipCodeError={showZipCodeError}
          setShowZipCodeError={setShowZipCodeError}
          showPhoneError={showPhoneError}
          setShowPhoneError={setShowPhoneError}
          showEmailError={showEmailError}
          setShowEmailError={setShowEmailError}
          showSchedulerPhoneError={showSchedulerPhoneError}
          setShowSchedulerPhoneError={setShowSchedulerPhoneError}
          showSchedulerEmailError={showSchedulerEmailError}
          setShowSchedulerEmailError={setShowSchedulerEmailError}
          addressRef={addressRef}
          isMobile={isMobile}
          onboardingContractorType={onboardingContractorType}
        />
        <div className="divider" />
        {onboardingContractorType === ContractorTypeEnum.Inspector && (
          <>
            <InspectionServicesSection
              title={BusinessDetailsSection.InspectionServices}
              sectionRef={inspectionServicesRef}
              inspectionServices={inspectionServices}
              contractorPricingInputs={contractorPricingInputs}
              setContractorPricingInputs={setContractorPricingInputs}
            />
            <div className="divider" />
          </>
        )}
        <CertificationsSection
          title={BusinessDetailsSection.Certifications}
          sectionRef={certificationsRef}
          onboardingContractorType={onboardingContractorType}
          stateLicenseNumber={stateLicenseNumber}
          setStateLicenseNumber={setStateLicenseNumber}
          ashiLicenseNumber={ashiLicenseNumber}
          setAshiLicenseNumber={setAshiLicenseNumber}
          internachiLicenseNumber={internachiLicenseNumber}
          setInternachiLicenseNumber={setInternachiLicenseNumber}
          otherLicenseNumber={otherLicenseNumber}
          setOtherLicenseNumber={setOtherLicenseNumber}
          inspectorLicenseHeld={inspectorLicenseHeld}
          setInspectorLicenseHeld={setInspectorLicenseHeld}
          contractorLicenseHeld={contractorLicenseHeld}
          setContractorLicenseHeld={setContractorLicenseHeld}
          realEstateLicenseHeld={realEstateLicenseHeld}
          setRealEstateLicenseHeld={setRealEstateLicenseHeld}
        />
        <div className="divider" />
        <ServiceableAreaSection
          isMobile={isMobile}
          onboardingContractorType={onboardingContractorType}
          title={BusinessDetailsSection.ServiceableArea}
          sectionRef={serviceableAreaRef}
          serviceAreaRadiusInMiles={serviceAreaRadiusInMiles}
          setServiceAreaRadiusInMiles={setServiceAreaRadiusInMiles}
        />
        <div className="divider" />
        <AccessAndSoftwareSection
          title={BusinessDetailsSection.AccessAndSoftware}
          sectionRef={accessAndSoftwareRef}
          hasSupra={hasSupra}
          setHasSupra={setHasSupra}
          onboardingContractorType={onboardingContractorType}
          hasSentrilock={hasSentrilock}
          setHasSentriLock={setHasSentriLock}
          reportWritingSoftware={reportWritingSoftware}
          setReportWritingSoftware={setReportWritingSoftware}
        />
        <div className="divider" />
        <DocumentsSection
          title={BusinessDetailsSection.Documents}
          sectionRef={documentsRef}
          misaFiles={misaFiles}
          certificationFiles={certificationFiles}
          uploadInsuranceCertificate={uploadInsuranceCertificate}
          loadingNewFile={loadingNewFile}
          msaSigned={msaSigned}
          onboardingContractorType={onboardingContractorType}
        />
        <div className="divider" />
        <div className="businessDetailsUpdateButtonContainer">
          <div className="businessDetailsUpdateButton">
            <Button
              onClick={updateBusinessDetails}
              disabled={updateButtonDisabled}
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
              {loading ? <Spinner /> : 'Update'}
            </Button>
          </div>
          {errorUpdating && (
            <div className="businessDetailsErrorMessage">{BusinessDetailsText.Error}</div>
          )}
        </div>
      </div>
    </div>
  </>
)
);

export default BusinessDetails;
