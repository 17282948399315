import React from 'react';
import './styles.scss';
import { PermissionsSections, UserPermissionProps } from './types';
import ToggleSwitch from '../../../../../components/ToggleSwitch';
import { UserPermissionEnum } from '../../../../../types';

const UserPermissions: React.FC<UserPermissionProps> = ({
  adminPermission,
  offersPermission,
  pricingPermission,
  handleUpdatePermission,
}) => (
  <div className="userPermissions">
    <div className="userPermissions__section">
      <div className="userPermissions__section__title">
        {PermissionsSections.Title}
      </div>
      <div className="userPermissions___section__description">
        {PermissionsSections.Description}
      </div>
    </div>
    <div className="userPermissions__section--large">
      <div className="userPermissions__section__row">
        <div className="userPermissions__section__row__textContainer">
          <div className="userPermissions__section__row__textContainer__permissionName--disabled">
            {UserPermissionEnum.Admin}
          </div>
          <div className="userPermissions__section__row__textContainer__permissionDescription--disabled">
            <div className="userPermissions__section__row__textContainer__permissionDescription--disabled--bold">
              {PermissionsSections.CallInspectify}
            </div>
            {PermissionsSections.AdminDescription}
          </div>
        </div>
        <ToggleSwitch isOn={adminPermission} tag="admin" disabled />
      </div>
      <div className="userPermissions__section__divider" />
      <div className="userPermissions__section__row">
        <div className="userPermissions__section__row__textContainer">
          <div className="userPermissions__section__row__textContainer__permissionName">
            {UserPermissionEnum.Offers}
          </div>
          <div className="userPermissions__section__row__textContainer__permissionDescription">
            {PermissionsSections.OffersDescription}
          </div>
        </div>
        <ToggleSwitch
          isOn={offersPermission}
          tag="offers"
          toggle={() => handleUpdatePermission('offersPermission', !offersPermission)}
        />
      </div>
      <div className="userPermissions__section__divider" />
      <div className="userPermissions__section__row">
        <div className="userPermissions__section__row__textContainer">
          <div className="userPermissions__section__row__textContainer__permissionName">
            {UserPermissionEnum.Pricing}
          </div>
          <div className="userPermissions__section__row__textContainer__permissionDescription">
            {PermissionsSections.PricingDescription}
          </div>
        </div>
        <ToggleSwitch
          isOn={pricingPermission}
          tag="pricing"
          toggle={() => handleUpdatePermission('pricingPermission', !pricingPermission)}
        />
      </div>
    </div>
  </div>
);

export default UserPermissions;
