import { useMutation } from '@apollo/client';
import cn from 'classnames';
import { UPDATE_INSPECTION_SCOPE_ITEM } from 'marketplace-common';
import React, { ContextType, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { FormInputCurrency } from '../../../components/FormElements';
import { SplitFlags } from '../../../redux/actions';
import { captureException } from '../../../utils/error';
import { useTreatment } from '../../../utils/splitio';
import { ReportContext } from '../context';

type InlineFiledProps = {
  inspectionScopeItemId: string
  reportSubsystemId: string
};

export const InlineInspectionScopeField: React.FC<InlineFiledProps> = ({
  inspectionScopeItemId,
}) => {
  const featureDrawManagement = useTreatment(SplitFlags.FeatureDrawManagement);
  const [updateDrawBudget, { loading, error }] = useMutation(UPDATE_INSPECTION_SCOPE_ITEM);
  const context = useContext(ReportContext);
  // TODO: REHAB DOESN'T LIKE THIS
  const {
    state: { drawBudget, orderTeamId },
    setDrawInspectionBudgetLoading,
    updateDrawInspectionBudget,
  } = context || { state: { drawBudget: [], orderTeamId: '' } } as ContextType<typeof ReportContext>;

  const drawBudgetRowData = drawBudget.find((item) => item.units.some((unit) => unit.id === inspectionScopeItemId));

  const scopeItem = drawBudgetRowData?.units.find((unit) => unit.id === inspectionScopeItemId);
  const unitIndex = drawBudgetRowData?.units.findIndex((unit) => unit.id === inspectionScopeItemId);
  const { disbursement, id, clientDisbursement } = scopeItem || {};
  const [hasError] = useState(
    scopeItem?.clientDisbursement
      ? scopeItem?.clientDisbursement > scopeItem?.budget
      : scopeItem?.disbursement > scopeItem?.budget,
  );

  useEffect(() => {
    if (error) {
      toast.error('An error occurred while updating the disbursement');
      captureException(error);
    }
  }, [error]);

  const handleValueChange = useCallback(async (value: number) => {
    setDrawInspectionBudgetLoading(
      drawBudget,
      drawBudgetRowData,
      unitIndex,
      'clientDisbursement',
      true,
    );
    drawBudgetRowData.units[unitIndex].clientDisbursement = value;
    const newScopeItem = updateDrawInspectionBudget(drawBudget, drawBudgetRowData, unitIndex);
    await updateDrawBudget({
      variables: {
        input: {
          id: newScopeItem.id,
          progress: newScopeItem.clientProgress,
          budget: newScopeItem.budget,
          disbursement: newScopeItem.disbursement,
          clientDisbursement: newScopeItem.clientDisbursement,
          previousDisbursement: newScopeItem.previousDisbursement,
        },
      },
    });
  }, [inspectionScopeItemId, updateDrawBudget]);

  // do not render if feature is off, or if scopeItem is not found
  const canEditDrawManagement = useMemo(() => {
    const selectedTeamIds = localStorage.getItem('inspectifySelectedTeamIds')?.split(',') || [];
    return featureDrawManagement === 'on' && selectedTeamIds.includes(orderTeamId?.toString());
  }, [featureDrawManagement, orderTeamId]);

  if (!canEditDrawManagement || !scopeItem) return null;
  const value = clientDisbursement || disbursement;

  return (
    <Form.Group controlId={id}>
      <Form.Label column={false}>
        Current Disbursement
      </Form.Label>
      <FormInputCurrency
        className={cn('form-control', 'form-control-sm', { 'is-invalid': hasError })}
        value={value}
        includeLabel={false}
        disabled={loading}
        loading={loading}
        id={`inlineField-${id}`}
        name={`inlineField-${id}`}
        onValueBlur={handleValueChange}
      />
      {hasError && (
        <Form.Control.Feedback type="invalid">
          Cannot exceed item budget
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};
