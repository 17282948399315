import { IdRef, InspectionProposal, PropertyPhotoInterface } from 'marketplace-common';

export * from './rehab';
export * from './report';

// https://docs.aws.amazon.com/cognito/latest/developerguide/user-pool-settings-attributes.html
export interface CognitoUser {
  email: string,
  emailVerified: boolean,
  phoneNumber: string,
  phoneNumberVerified: boolean,
  sub: string,
  name: string,
  givenName: string,
  familyName: string,
  picture: string,
}

/**
 * Currently logged in user,
 * `false` if no user is logged in,
 * `null` if the current user hasn't been fetched
 */
export type CurrentUser = CognitoUser | false | null;

export type UserPermission =
  'view_new_jobs_tab' |
  'view_your_pay_field' |
  'view_payment_setup_page' |
  'contractor_admin';

export interface UserContractor {
  currentUser : User
}

export interface User {
  id: string,
  email: string,
  name: string,
  created_at: number,
  admin: boolean,
  teamId: number,
  teamAdmin: boolean,
  canManageUsers: boolean,
  contractorId: string,
  contractor: Contractor,
  archivedAt: number | null,
  activatedAt: number | null,
  grantedPermissions: UserPermission[],
  phoneNumber: string,
  teamUsers: {
    team: {
      id: number
      allowRehabToolAccess: boolean,
      enableRehabCostSplitting: boolean,
      name: string,
      token: string,
      drawSummaryTableDisplay: string,
      sowSummaryTableDisplay: string,
      rehabTeamMarketIds: string[],
    }
  }[]
}

// TODO: (GA9-634) move this to marketplace-common
export interface UnauthenticatedContractor {
  id: string,
  businessName: string,
  street: string,
  city: string,
  state: string,
}

export interface TeamUser {
  team: Team
}

// TODO: (GA9-634) move this to marketplace-common
export interface AvailableUser {
  id: string,
  contractorId?: string,
  grantedPermissions: string[],
  contractor?: UnauthenticatedContractor,
  teamUsers: TeamUser[]
}

export type CollectEnum = 'no_collect' | 'collect' | 'required';

export interface Stakeholder {
  id: number,
  name: string,
  phone: string,
  email: string,
  isClient: boolean,
  stakeholderType: string,
  orderId: number,
  collectEmail: CollectEnum,
  collectPhone: CollectEnum,
  collectName: CollectEnum
}

export interface LocalStakeholder extends Stakeholder {
  orderToken: string,
}

export interface StakeholderInterface {
  [stakeholderId: number]: LocalStakeholder
}

export interface StakeholdersInterface {
  [orderToken: string]: StakeholderInterface
}

export type Stakeholders = StakeholdersInterface | null;

export interface InspectionDeficiencyImage {
  id: number,
  link: string,
  name: string,
}

export type Section =
  | 'Roof' | 'Exterior' | 'Basement | Foundation | Crawlspace & Structure' | 'HVAC' | 'Plumbing'
  | 'Electrical' | 'Fireplace' | 'Attic | Insulation & Ventilation' | 'Doors | Windows & Interior'
  | 'Built-In Appliance' | 'Garage';

export type Severity = 'safety' | 'repair' | 'monitor';

export interface InspectionDeficiency {
  id: number,
  title: string,
  detail: string,
  inspectionId: number,
  inspectionDocumentId: number,
  standardDeficiencyId: number,
  quantity: number,
  images: InspectionDeficiencyImage[],
  section: Section,
  reportText: string,
  pageNumber: number,
  severity: Severity,
  futureEvaluationNeeded: boolean,
  repairCost: number,
  remedyTitle: string,
  remedyDetail: string,
  quantityDescriptor: string
}

export interface CreateOrUpdateInspectionDeficiency extends InspectionDeficiency {
  // For creating Standard Deficiency
  addDeficiencyToStandardList: boolean,
  standardDeficiencyRemedyId: number,
  // For creating Remedy
  minPrice: number,
  maxPrice: number,
  newRemedyFutureEvaluationNeeded: boolean,
}

export interface InspectionDocument {
  id: number,
  inspectionId: number,
  name: string,
  url: string,
  externalDocumentUrl: string,
  repairEstimateReadyEmailSentAt: number,
  createdAt: number
}

export interface LocalInspectionProposal extends InspectionProposal {
  orderToken: string,
  inspectionType?: string
}

export interface InspectionProposalInterface {
  [inspectionProposalId: string]: LocalInspectionProposal
}

export interface InspectionProposalsInterface {
  [inspectionId: number]: InspectionProposalInterface
}

export type InspectionProposals = InspectionProposalsInterface | null;

export interface InspectionBid {
  contractorId: number,
  price: string,
  durationHour: string,
  requiresScheduling: boolean | null,
  isAgentPreferred: boolean
}

export interface LocalInspectionBid extends InspectionBid {
  orderToken: string,
  inspectionId: number
}

export interface InspectionBidInterface {
  [inspectionId: number]: LocalInspectionBid[]
}

export type InspectionBids = InspectionBidInterface | null;

export enum ContractorTypeEnum {
  Inspector = 'inspector',
  Showami = 'showami',
  Redfin = 'redfin',
  Observa = 'observa',
  TaskRabbit = 'taskrabbit',
  TeamInternal = 'team_internal',
  Surveyor = 'surveyor',
}

export enum ContractorPaymentPlatform {
  Tipalti = 'tipalti_payment',
  Showami = 'showami_payment',
  Direct = 'direct_payment',
}

export type ContractorPricing = {
  active: boolean;
  allowCustomPricing: boolean;
  description?: string;
  name: string;
};

export type ContractorPricingInput = {
  name: string,
  customPricing?: string,
  subContracted?: boolean,
};
export interface Contractor {
  id: string,
  contractorPricings: ContractorPricing[],
  fileAttachments: ContractorFileAttachment[] | [],
  internalTeams: Team[],
  token?: string,
  contractorType?: ContractorTypeEnum,
  businessName?: string,
  businessSite?: string,
  latitude?: number,
  longitude?: number,
  certifications?: string,
  ashiLicenseNumber?: string,
  internachiLicenseNumber?: string,
  otherLicenseNumber?: string,
  stateLicenseNumber?: string,
  inBusinessSince?: string,
  logo?: { url: string },
  preferredInspectorStatus?: string,
  instantBookingEnabled?: boolean,
  businessContactName?: string,
  businessPhone?: string,
  businessEmail?: string,
  businessAddress?: string,
  street?: string,
  street2?: string,
  city?: string,
  state?: string,
  country?: string,
  businessZipCode?: string,
  schedulerPhone?: string,
  schedulerEmail?: string,
  schedulerName?: string,
  sendAutomatedSms?: boolean,
  serviceZipCodes?: string[] | [],
  serviceAreaRadiusMileage?: number,
  hasSupra?: boolean,
  hasSentrilock?: boolean,
  reportWritingSoftware?: string,
  msaSigned: boolean,
  tipaltiIsPayable: boolean,
  contractorLicenseHeld?: boolean,
  inspectorLicenseHeld?: boolean,
  realEstateLicenseHeld?: boolean,
  currentOnboarding?: OnboardingType,
  currentInspectorOnboarding?: OnboardingType,
}

export enum OnboardingCompletedStep {
  LandingPageViewed = 'landing_page_viewed',
  FormViewed = 'intake_form_viewed',
  FormSubmitted = 'intake_form_submitted',
  PasswordSet = 'password_set',
}

export enum OnboardingStatus {
  pending = 'pending',
  started = 'started',
  escalated = 'escalated',
  succeeded = 'succeeded',
  failed = 'failed',
  cancelled = 'cancelled',
}

export type OnboardingType = {
  appLoggedIn: boolean,
  canSetPassword: boolean,
  completedStep: OnboardingCompletedStep,
  contractor: Contractor,
  createdAt: number,
  id: string,
  misaSigned: boolean,
  onboardingTrackerDismissedAt: number,
  paymentDetailsFilled: boolean,
  status: OnboardingStatus,
  updatedAt: number,
  // Surveyor only
  backgroundCheckRequired?: boolean;
  backgroundCheckSubmittedAt?: number;
  marketSaturatedAtOnboardingTime?: boolean;
  submitBackgroundCheckUrl?: string;
  // Inspector only
  businessInformationAdded?: boolean,
  isMultiInspectorFirm?: boolean,
  usersAdded?: boolean,
};

export type OnboardingQueryResponseType = {
  surveyorOnboarding?: OnboardingType;
  inspectorOnboarding?: OnboardingType;
};

export type OnboardingUpdateResponseType = {
  updateSurveyorOnboarding?: {
    surveyorOnboarding: OnboardingType;
  }
  updateInspectorOnboarding?: {
    inspectorOnboarding: OnboardingType;
  }
};

export type OnboardingCreateResponseType = {
  createSurveyorOnboarding?: {
    surveyorOnboarding: {
      completedStep: OnboardingCompletedStep;
      id: string;
    };
  }
  createInspectorOnboarding?: {
    inspectorOnboarding: {
      completedStep: OnboardingCompletedStep;
      id: string;
    };
  }
};

export interface ContractorFileAttachment {
  id: string,
  note: string | null,
  category: string,
  createdAt: number,
  updatedAt: number,
  attachment: {
    filename: string | null,
    url: string | null
  }
}

export interface ContractorInterface {
  [contractorId: number]: Contractor
}

export type Contractors = ContractorInterface | null;

export interface UsersInterface {
  [userId: string]: User
}

export type Users = UsersInterface | null;

export interface Property {
  id: IdRef,
  address: string,
  street: string,
  street2: string,
  city: string,
  state: string,
  zipCode: string,
  country: string,
  homeType: string,
  totalSquareFeet: string,
  bedrooms: number,
  bathrooms: number,
  yearBuilt: number,
  photo: PropertyPhotoInterface,
  timezone: string
}

export interface LocalProperty extends Property {
  orderToken: string
}

export interface PropertiesInterface {
  [propertyId: number]: LocalProperty
}

export type Properties = PropertiesInterface | null;

export interface Order {
  id: number,
  status?: string,
  paymentType?: string,
  propertyId?: number,
  property?: Property,
  teamId: number,
  token?: string,
  teamToken?: string,
  readOnlyToken: string,
  adminEditUrl?: string,
  createdAt?: number,
  clientName?: string,
  clientEmail?: string,
  clientPhone?: string,
  agentName?: string,
  agentEmail?: string,
  agentPhone?: string,
  specialInstructions?: string,
}

export interface Team {
  id: number,
  token: string,
  name: string,
  inspectify: boolean,
  logoUrl: string | null,
  rehabTags: string[],
  templateWorkItems: string[],
  canPublishRehabProjects?: boolean,
  company: {
    name: string
    id: string
  }
}

export interface TeamsInterface {
  [teamId: string]: Team
}

export interface Inspection {
  id: number,
  orderId: number,
  status: string,
  inspectionType: string,
  token: string,
  inspectionScopeNumber: string,
  inspectionDocumentReadyEmailSentAt: number,
  scheduledProposalId: number,
  createdAt: number,
  appRequirement: string,
}

export interface LocalInspection extends Inspection {
  orderToken: string
  bids?: LocalInspectionBid[]
  proposals?: LocalInspectionProposal[]
}

export interface InspectionInterface {
  [inspectionId: number]: LocalInspection
}

export interface InspectionsInterface {
  [orderToken: string]: InspectionInterface // LocalInspection[]
}

export type Inspections = InspectionsInterface | null;

export interface StandardDeficiency {
  id: number,
  title: string,
  detail: string,
  section: string,
  severity: string,
  standardDeficiencyRemedyId: number,
}

export interface StandardDeficiencyRemedy {
  id: number,
  title: string,
  detail: string,
  minPrice: number,
  maxPrice: number,
  quantityDescriptor: string,
}

export interface OrdersInterface {
  [token: string]: Order
}

export type Orders = OrdersInterface | null;

export enum FileTypes {
  INSURANCE = 'insurance_certificate',
  CONTRACT = 'contract', // this is the Master Inspection Agreement
}

export interface UpdateContractorReturnDataType {
  updateContractor: {
    success: boolean,
    code: string,
    errors: string,
    message: string,
    contractor: Contractor
  }
}

export type ContractorPaymentMailingAddress = {
  line1: string,
  line2?: string,
  city: string,
  state: string,
  postalCode: string,
  country: string
};

export interface GenerateTipaltiIframeUrlDataType {
  generateTipaltiIframeUrl: {
    iframeUrl: string
  }
}

// TODO: Move this to marketplace-common and add values for other feedback projects
export enum ReviewableType {
  REPORT = 'Report',
}

// TODO: Move this to marketplace-common for use with othe feedback projects
export type UserFeedback = {
  id: IdRef,
  reviewableId: string,
  reviewableType: ReviewableType,
  note?: string,
  score?: string,
  initiatedAt?: number,
  submittedAt?: number,
  userId?: IdRef,
  createdAt: number,
  updatedAt: number,
};

export type OrderByTokenVariables = {
  token: string;
};

export enum UserPermissionEnum {
  Admin = 'Admin',
  Offers = 'Offers',
  Pricing = 'Pricing',
  None = 'None',
}

export const PERMISSION_NAMES: { [permission: string]: UserPermissionEnum } = {
  contractor_admin: UserPermissionEnum.Admin,
  view_new_jobs_tab: UserPermissionEnum.Offers,
  view_your_pay_field: UserPermissionEnum.Pricing,
};

export enum UserStatusEnum {
  Active = 'Active',
  InviteSent = 'Invite sent',
  Archived = 'Archived',
}
