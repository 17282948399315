import React, { useContext } from 'react';
import './styles.scss';
import Button from '../../../components/Button';
import { BusinessDetailsMobileViewProps, BusinessDetailsSection, BusinessDetailsText } from './types';
import { InspectorDashboardContext } from '../context';
import { DashboardPageTab } from '../types';
import { ContractorTypeEnum } from '../../../types';
import Spinner from '../../../components/Spinner';

const BusinessDetailsMobileView: React.FC<BusinessDetailsMobileViewProps> = ({
  currentSection,
  setCurrentSection,
  showBackButton,
  showUpdateButton,
  updateBusinessDetails,
  onboardingContractorType,
  businessDetailsEdited,
  setBusinessDetailsEdited,
  loading,
  errorUpdating,
  children,
}) => {
  const { setPage } = useContext(InspectorDashboardContext);

  const handleNext = () => {
    if (businessDetailsEdited) {
      updateBusinessDetails();
      setBusinessDetailsEdited(false);
    }
    switch (currentSection) {
      case BusinessDetailsSection.BasicInformation:
        if (onboardingContractorType === ContractorTypeEnum.Inspector) {
          setCurrentSection(BusinessDetailsSection.InspectionServices);
        } else {
          setCurrentSection(BusinessDetailsSection.Certifications);
        }
        break;
      case BusinessDetailsSection.InspectionServices:
        setCurrentSection(BusinessDetailsSection.Certifications);
        break;
      case BusinessDetailsSection.Certifications:
        setCurrentSection(BusinessDetailsSection.ServiceableArea);
        break;
      case BusinessDetailsSection.ServiceableArea:
        setCurrentSection(BusinessDetailsSection.AccessAndSoftware);
        break;
      case BusinessDetailsSection.AccessAndSoftware:
        setCurrentSection(BusinessDetailsSection.Documents);
        break;
      default:
        break;
    }
  };

  const handleBack = () => {
    const sectionsToUpdateOnBack = [
      BusinessDetailsSection.Certifications,
      BusinessDetailsSection.ServiceableArea,
      BusinessDetailsSection.InspectionServices,
      BusinessDetailsSection.AccessAndSoftware,
    ];
    if (sectionsToUpdateOnBack.includes(currentSection)) updateBusinessDetails();
    switch (currentSection) {
      case BusinessDetailsSection.InspectionServices:
        setCurrentSection(BusinessDetailsSection.BasicInformation);
        break;
      case BusinessDetailsSection.Certifications:
        if (onboardingContractorType === ContractorTypeEnum.Inspector) {
          setCurrentSection(BusinessDetailsSection.InspectionServices);
        } else {
          setCurrentSection(BusinessDetailsSection.BasicInformation);
        }
        break;
      case BusinessDetailsSection.ServiceableArea:
        setCurrentSection(BusinessDetailsSection.Certifications);
        break;
      case BusinessDetailsSection.AccessAndSoftware:
        setCurrentSection(BusinessDetailsSection.ServiceableArea);
        break;
      case BusinessDetailsSection.Documents:
        setCurrentSection(BusinessDetailsSection.AccessAndSoftware);
        break;
      default:
        break;
    }
  };

  return (
    <div onChange={() => setBusinessDetailsEdited(true)}>
      <div className="businessDetailsMobileHeaderContainer">
        <div className="businessDetailsMobileHeader">
          Business Details
        </div>
        {showUpdateButton && (
          <div className="businessDetailsUpdateButtonContainer">
            <div className="businessDetailsUpdateButton">
              <Button
                onClick={updateBusinessDetails}
                disabled={loading || !businessDetailsEdited}
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
              >
                {loading ? <Spinner /> : 'Update'}
              </Button>
            </div>
            {errorUpdating && (
              <div className="businessDetailsErrorMessage">{BusinessDetailsText.Error}</div>
            )}
          </div>
        )}
      </div>
      <div className="businessDetailsContainer">
        {children}
      </div>
      <div className="businessDetailsMobileButtonContainer">
        {currentSection === BusinessDetailsSection.Documents ? (
          <Button
            onClick={() => setPage(DashboardPageTab.Jobs)}
          >
            Finish
          </Button>
        ) : (
          <div className="businessDetailsUpdateButtonContainer">
            <div className="businessDetailsUpdateButton">
              <Button
                onClick={handleNext}
              >
                Next
              </Button>
            </div>
            {errorUpdating && (
              <div className="businessDetailsErrorMessage">{BusinessDetailsText.Error}</div>
            )}
          </div>
        )}
        {showBackButton && (
          <Button
            onClick={handleBack}
            className="businessDetailsMobileBackButton"
          >
            Back
          </Button>
        )}
      </div>
    </div>
  );
};

export default BusinessDetailsMobileView;
