import React from 'react';
import { WorkItem } from '../../../../types';
import { SubsystemName, SystemName } from './styles';
import RehabWorkItem from './RehabWorkItem';

interface RehabSystemSectionsProps {
  workItemsBySystem: {
    [systemName: string]: {
      name: string;
      displayIndex: number;
      subsystems: { [subsystemName: string]: WorkItem[] };
    };
  };
}

const RehabSystemSections: React.FC<RehabSystemSectionsProps> = ({ workItemsBySystem }) => (
  <div>
    {Object.keys(workItemsBySystem).map((system) => (
      <div key={system}>
        {Object.keys(workItemsBySystem[system].subsystems).map((subsystem) => (
          <div key={subsystem}>
            <SystemName>
              {system}
            </SystemName>

            {subsystem && subsystem !== 'null' && (
            <SubsystemName>
              / {subsystem}
            </SubsystemName>
            )}

            {workItemsBySystem[system].subsystems[subsystem].map((workItem) => (
              <RehabWorkItem key={workItem.id} workItemId={workItem.id} />
            ))}
          </div>
        ))}
      </div>
    ))}
  </div>
);
export default RehabSystemSections;
