import React, { useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import SendFilledIcon from 'haven-design-system/build/assets/svgs/action/send_filled.svg?component';
import ErrorIcon from 'haven-design-system/build/assets/svgs/issues/limitation_filled.svg?component';
import ChevronLeftIcon from 'haven-design-system/build/assets/svgs/controls/chevron_left.svg?component';
import ConfirmIcon from 'haven-design-system/build/assets/svgs/action/confirm.svg?component';
import ArchiveImage from 'haven-design-system/build/assets/svgs/images/general/archive.svg';
import ThinkingImage from '../../../../assets/images/thinkingImage.svg';
import SendingMailImage from '../../../../assets/images/sendingMailImage.svg';
import UserProfileInformation from './UserProfileInformation/UserProfileInformation';
import UserPermissions from './UserPermissions/UserPermissions';
import './styles.scss';
import Button from '../../../../components/Button';
import { formatPhoneNumber, unformatPhoneNumber, validateEmail } from '../../../../utils/string';
import {
  getFirstName,
  getLastName,
  getMergedName,
  getPermissionNames,
  getPermissionsForBackend,
  getUserStatus,
} from '../../../../utils/user';
import Spinner from '../../../../components/Spinner';
import { ConfirmationModalProps } from '../../../../components/ConfirmationModal/types';
import ConfirmationModal from '../../../../components/ConfirmationModal/ConfirmationModal';
import { ModalType, SubmitButtonText, UserFormProps } from './types';
import { useCreateUser, useResendInvite, useUpdateUser } from '../../../../hooks/user';
import { EventName, track } from '../../../../utils/analytics';
import { UserPermissionEnum, UserStatusEnum } from '../../../../types';
import PopupInstructions from '../../../../components/PopupInstructions';
import { PopupInstructionsContent } from '../../../../components/PopupInstructions/types';

const UserForm = ({
  handleReturn,
  singleUser,
  setSingleUser,
  usersAdded,
}: UserFormProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [sendInvitePopupIsOpen, setSendInvitePopupIsOpen] = useState(!usersAdded);
  const [modalIsOpened, setModalIsOpened] = useState(false);
  const [currentModal, setCurrentModal] = useState(ModalType.CANCEL);
  const [formHasChanged, setFormHasChanged] = useState(false);
  const [submitButtonText, setSubmitButtonText] = useState(
    singleUser ? SubmitButtonText.SAVED : SubmitButtonText.SEND,
  );
  const [userData, setUserData] = useState({
    firstName: singleUser?.firstName || '',
    lastName: singleUser?.lastName || '',
    phoneNumber: singleUser?.phoneNumber || '',
    email: singleUser?.email || '',
    status: singleUser?.status || null,
    adminPermission: singleUser?.permissions?.includes(UserPermissionEnum.Admin),
    offersPermission: singleUser?.permissions?.includes(UserPermissionEnum.Offers),
    pricingPermission: singleUser?.permissions?.includes(UserPermissionEnum.Pricing),
  });

  const originalUserData = useMemo(() => ({
    first_name: singleUser?.firstName || '',
    last_name: singleUser?.lastName || '',
    phone_number: formatPhoneNumber(singleUser?.phoneNumber) || '',
    email: singleUser?.email || '',
    status: singleUser?.status || null,
    admin: singleUser?.permissions?.includes(UserPermissionEnum.Admin) ? 'on' : 'off',
    offers: singleUser?.permissions?.includes(UserPermissionEnum.Offers) ? 'on' : 'off',
    pricing: singleUser?.permissions?.includes(UserPermissionEnum.Pricing) ? 'on' : 'off',
    id: singleUser?.id,
  }), [singleUser]);

  const formIsValid = useMemo(() => userData.firstName.length > 0
    && userData.lastName.length > 0
    && userData.phoneNumber?.length === 10 // not all existing users have a phone number
    && userData.email.length > 0 && validateEmail(userData.email), [userData]);

  // Show the popup if the user did not close the popup, no users have been added, the UserForm is in add user mode, the form is complete
  const showSendInvitePopup = useMemo(() => (sendInvitePopupIsOpen
    && !usersAdded
    && !singleUser
    && formIsValid),
  [sendInvitePopupIsOpen, usersAdded, singleUser, formIsValid]);

  const handleCloseSendInvitePopup = () => setSendInvitePopupIsOpen(false);

  const openModal = (modalType: ModalType) => {
    setCurrentModal(modalType);
    setModalIsOpened(true);
  };

  const [createUser, { loading: createUserLoading }] = useCreateUser({
    onCompleted: (data) => {
      setSubmitButtonText(SubmitButtonText.SAVED);
      openModal(ModalType.CONFIRM);
      track(EventName.InviteNewUser);
      setUserData({
        ...userData,
        status: UserStatusEnum.InviteSent,
      });
      setSingleUser({
        id: data?.createUser?.user.id,
        firstName: getFirstName(data?.createUser?.user.name),
        lastName: getLastName(data?.createUser?.user.name),
        email: data?.createUser?.user.email,
        phoneNumber: data?.createUser?.user.phoneNumber,
        status: UserStatusEnum.InviteSent,
        activatedAt: data?.createUser?.user?.activatedAt || null,
        archivedAt: data?.createUser?.user?.archivedAt || null,
        permissions: getPermissionNames(data?.createUser?.user?.grantedPermissions),
      });
      setFormHasChanged(false);
    },
    onError: (error) => {
      let errorText = "We're sorry, we were unable to create this user. Please try again or contact support.";
      if (error.graphQLErrors?.length > 0) {
        // Handle specific error codes
        if (error.graphQLErrors[0].extensions?.code === 'USER_ALREADY_EXISTS') {
          errorText = `The user with the email ${userData.email} already exists in your inspector account. You can resend an email invitation to this user by clicking on their profile under your users list.`;
        }
      }
      setErrorMessage(errorText);
    },
    refetchQueries: ['manageableUsers'],
    awaitRefetchQueries: true,
  });

  const [resendInvite, { loading: resendInviteLoading }] = useResendInvite({
    onCompleted: () => {
      openModal(ModalType.CONFIRM);
      track(EventName.ResendInviteToUser);
    },
    onError: () => {
      setErrorMessage("We're sorry, we were unable to resend the invite. Please try again or contact support.");
    },
  });

  const [updateUser, { loading: updateUserLoading }] = useUpdateUser({
    onCompleted: (data) => {
      setSubmitButtonText(SubmitButtonText.SAVED);
      if (!formHasChanged) {
        if (data.updateUser.user.archivedAt) {
          track(EventName.ArchiveUser);
        } else {
          track(EventName.UnarchiveUser);
        }
      }
      if (originalUserData.email !== userData.email) {
        track(EventName.EditUserEmail);
      }
      if ((originalUserData.offers === 'on' && !userData.offersPermission)
      || (originalUserData.offers === 'off' && userData.offersPermission)
      || (originalUserData.pricing === 'on' && !userData.pricingPermission)
      || (originalUserData.pricing === 'off' && userData.pricingPermission)) {
        track(EventName.EditUserPermissions);
      }
      setSingleUser({
        id: data?.updateUser?.user.id,
        firstName: getFirstName(data?.updateUser?.user.name),
        lastName: getLastName(data?.updateUser?.user.name),
        email: data?.updateUser?.user.email,
        phoneNumber: data?.updateUser?.user.phoneNumber,
        status: getUserStatus(data?.updateUser?.user),
        activatedAt: data?.updateUser?.user?.activatedAt || null,
        archivedAt: data?.updateUser?.user?.archivedAt || null,
        permissions: getPermissionNames(data?.updateUser?.user?.grantedPermissions),
      });
      setUserData({
        ...userData,
        status: getUserStatus(data?.updateUser?.user),
      });
      setFormHasChanged(false);
    },
    onError: () => {
      setErrorMessage("We're sorry, we were unable to update the user. Please try again or contact support.");
    },
    refetchQueries: ['manageableUsers'],
    awaitRefetchQueries: true,
  });

  const handleSendInvite = () => {
    setErrorMessage('');
    createUser({
      variables: {
        input: {
          name: getMergedName(userData.firstName, userData.lastName),
          phoneNumber: userData.phoneNumber,
          email: userData.email,
          grantedPermissions: getPermissionsForBackend(userData),
        },
      },
    });
  };

  const handleResendInvite = () => {
    setErrorMessage('');
    resendInvite({
      variables: {
        input: {
          id: singleUser.id,
        },
      },
    });
  };

  const handleConfirmModal = () => {
    setModalIsOpened(false);
    handleReturn();
  };

  const handleBackModal = () => setModalIsOpened(false);

  const handleSaveUser = () => {
    setErrorMessage('');
    updateUser({
      variables: {
        input: {
          id: singleUser.id,
          name: getMergedName(userData.firstName, userData.lastName),
          phoneNumber: userData.phoneNumber,
          email: userData.email,
          grantedPermissions: getPermissionsForBackend(userData),
        },
      },
    });
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!singleUser) {
      handleSendInvite();
    } else {
      handleSaveUser();
    }
  };

  const handleArchiveUser = () => {
    setModalIsOpened(false);
    updateUser({
      variables: {
        input: {
          id: singleUser.id,
          archived: true,
        },
      },
    });
  };

  const handleUnarchiveUser = () => {
    updateUser({
      variables: {
        input: {
          id: singleUser.id,
          archived: false,
        },
      },
    });
  };

  const modalProps: { [type: string]: ConfirmationModalProps } = useMemo(() => ({
    cancel: {
      header: 'Are you sure? Your work will not be saved.',
      backButton: 'Back',
      confirmButton: 'Yes, I want to leave',
      backIconButton: true,
      imageUrl: ThinkingImage,
      opened: modalIsOpened,
      onConfirm: handleConfirmModal,
      onBack: handleBackModal,
    },
    confirm: {
      header: `Email invite sent to ${getMergedName(userData.firstName, userData.lastName)}`,
      subheader: `Make sure the inspector checks for the message so they can log into the app and begin inspecting! The email invite was sent to ${userData.email}`,
      confirmButton: 'Back to manage users',
      backIconButton: true,
      imageUrl: SendingMailImage,
      opened: modalIsOpened,
      onConfirm: handleConfirmModal,
      onBack: handleBackModal,
    },
    archive: {
      header: 'Are you sure?',
      subheader: 'Archiving this user will prevent them from performing further inspections on the Inspectify App. This action can always be undone by clicking the “archive” icon on the dashboard.',
      backButton: 'Back',
      confirmButton: 'Yes, archive this user',
      backIconButton: true,
      imageUrl: ArchiveImage,
      opened: modalIsOpened,
      onConfirm: handleArchiveUser,
      onBack: handleBackModal,
    },
  }), [modalIsOpened]);

  const handleFormChange = (form: HTMLFormElement) => {
    const data = new FormData(form);
    const value = Object.fromEntries(data.entries());
    const fieldsToCheck = (v: any) => ({
      firstName: v.first_name,
      lastName: v.last_name,
      phoneNumber: v.phone_number,
      email: v.email,
      offersPermission: v.offers || 'off',
      pricingPermission: v.pricing || 'off',
    });
    const formIsEqualToOriginal = isEqual(fieldsToCheck(value), fieldsToCheck(originalUserData));
    setFormHasChanged(!formIsEqualToOriginal);
    if (singleUser) {
      setSubmitButtonText(formIsEqualToOriginal ? SubmitButtonText.SAVED : SubmitButtonText.SAVE);
    }
    setUserData({
      ...userData,
      firstName: value.first_name.toString(),
      lastName: value.last_name.toString(),
      phoneNumber: unformatPhoneNumber(value.phone_number.toString()),
      email: value.email.toString(),
      status: userData.status,
      adminPermission: userData.adminPermission,
      offersPermission: value.offers === 'on',
      pricingPermission: value.pricing === 'on',
    });
  };

  const handleUpdatePermission = (permissionName: string, value: boolean) => {
    setUserData({
      ...userData,
      [permissionName]: !!value,
    });
  };

  const handleBack = () => {
    if (formHasChanged) {
      openModal(ModalType.CANCEL);
    } else {
      handleReturn();
    }
  };

  const disableSubmitButton = () => {
    if (!formIsValid || !formHasChanged || createUserLoading || resendInviteLoading) return true;
    return false;
  };

  return (
    <div className="userFormContainer">
      <div className="userFormContainer__header">
        <Button
          onClick={handleBack}
          className="userFormContainer__header__backButton"
        >
          <ChevronLeftIcon />
        </Button>
        <div className="userFormContainer__header__text">{singleUser ? 'Manage user' : 'Add user'}</div>
      </div>
      <form
        className="userForm"
        onSubmit={handleFormSubmit}
        onChange={(e) => handleFormChange(e.currentTarget)}
      >
        <input type="hidden" name="id" value={singleUser?.id} />
        <ConfirmationModal {...modalProps[currentModal]} />
        <UserProfileInformation
          firstName={userData.firstName}
          lastName={userData.lastName}
          phoneNumber={userData.phoneNumber}
          email={userData.email}
          status={userData.status}
          isEditUserView={!!userData?.status}
          isAdmin={userData.adminPermission}
          openModal={openModal}
          handleUnarchiveUser={handleUnarchiveUser}
        />
        <div className="userForm__divider" />
        <UserPermissions
          adminPermission={userData.adminPermission}
          offersPermission={userData.offersPermission}
          pricingPermission={userData.pricingPermission}
          handleUpdatePermission={handleUpdatePermission}
        />
        <div className="userForm__divider" />
        <div className="userForm__actionContainer">
          {!!errorMessage && (
            <div className="userForm__submitError">
              <ErrorIcon />
              {errorMessage}
            </div>
          )}
          <div className="userForm__actionContainer__buttonContainer">
            {!singleUser ? (
              <Button
                type="submit"
                disabled={disableSubmitButton()}
                className="userForm__actionContainer__buttonContainer__button"
              >
                {createUserLoading ? (
                  <Spinner />
                ) : (
                  <>
                    {submitButtonText}
                    <SendFilledIcon />
                  </>
                )}
              </Button>
            ) : (
              <>
                <Button
                  type="submit"
                  disabled={disableSubmitButton()}
                  className="userForm__actionContainer__buttonContainer__button"
                >
                  {updateUserLoading ? (
                    <Spinner />
                  ) : (
                    <>
                      <ConfirmIcon />
                      {submitButtonText}
                    </>
                  )}
                </Button>
                {userData.status === UserStatusEnum.InviteSent ? (
                  <Button
                    type="button"
                    disabled={formHasChanged}
                    className="userForm__actionContainer__buttonContainer__button"
                    onClick={handleResendInvite}
                  >
                    {resendInviteLoading ? (
                      <Spinner />
                    ) : (
                      <>
                        Resend invite
                        <SendFilledIcon />
                      </>
                    )}
                  </Button>
                ) : null }
              </>
            )}
            {showSendInvitePopup && (
              <PopupInstructions
                header={PopupInstructionsContent.SendInviteHeader}
                body={PopupInstructionsContent.SendInviteBody}
                xPos={0}
                yPos={50}
                onClose={handleCloseSendInvitePopup}
                showPopupAboveElement
              />
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default UserForm;
