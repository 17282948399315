import { useQuery } from '@apollo/client';
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import InspectifyLayout from '../../components/InspectifyLayout';
import { USER_CONTRACTOR } from '../../graphql/queries';
import { UserContractor } from '../../types';
import { signOut } from '../../utils/amplify_auth';
import { EventName, track } from '../../utils/analytics';
import { captureException } from '../../utils/error';
import { useUrlQuery } from '../../utils/hooks';
import { useCurrentUser } from '../../utils/user';
import { InspectorDashboardContext } from './context';
import InspectorDashboardPage from './InspectorDashboardPage';
import '../../styles/vendor/bootstrapOverrides.scss';
import '../../styles/vendor/reactModalOverrides.scss';

const InspectorDashboardLoader: React.FC = () => {
  const { state, setContractor } = useContext(InspectorDashboardContext);
  const currentUser = useCurrentUser();
  const history = useHistory();
  const query = useUrlQuery();
  const contractorId = query.get('contractor_id');
  const contractorToken = query.get('contractor_token');

  const basicInformationRef = useRef(null);
  const inspectionServicesRef = useRef(null);
  const certificationsRef = useRef(null);
  const serviceableAreaRef = useRef(null);
  const accessAndSoftwareRef = useRef(null);
  const documentsRef = useRef(null);

  const [user, setUser] = useState(null as UserContractor | null);
  const [error, setError] = useState('');

  const { loading, refetch: refetchContractor } = useQuery<UserContractor>(USER_CONTRACTOR, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data?.currentUser?.contractor) {
        setError('');
        setUser(data);
        setContractor(data.currentUser.contractor);
      } else {
        setError("Your credentials don't match our records. If you're a client, please login using the button below. If there's been a mistake, please contact customer support.");
      }
    },
    onError: (e) => {
      captureException('InspectorDashboard - USER_CONTRACTOR query', e);
    },
  });

  useEffect(() => () => setError(''), []);

  useEffect(() => {
    const notSignedIn = currentUser === false;
    const contractorIdsNotEqual = (
      currentUser
      && contractorId
      && state.contractor?.id
      && contractorId !== state.contractor.id.toString()
    );

    if (notSignedIn || contractorIdsNotEqual) {
      if (contractorIdsNotEqual) signOut();

      track(EventName.InspectorRedirectToSignIn, {
        contractor: state.contractor?.id,
        urlContractorId: contractorId,
        urlContractorToken: contractorToken,
      });

      history.push(`/sign_in?next=/dashboard/${contractorToken ? `&contractor_token=${contractorToken}` : ''}`);
    }
  }, [currentUser, state.contractor?.id, contractorId, contractorToken]);

  const isLoading = loading || currentUser === null || state.contractor === null;

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <InspectifyLayout hideHeaderOnMobile showFooter={!!error.length} />
      <InspectorDashboardPage
        user={user}
        isLoading={isLoading}
        error={error}
        refetchContractor={refetchContractor}
        basicInformationRef={basicInformationRef}
        inspectionServicesRef={inspectionServicesRef}
        certificationsRef={certificationsRef}
        serviceableAreaRef={serviceableAreaRef}
        accessAndSoftwareRef={accessAndSoftwareRef}
        documentsRef={documentsRef}
      />
    </>
  );
};

export default InspectorDashboardLoader;
