import React from 'react';
import '../../styles.scss';
import InformationSection from '../../../../components/InformationSection';
import { ContractorTypeEnum } from '../../../../types';
import { OnboardingFormQuestions } from '../../../Onboarding/OnboardingFormPage/types';
import { calculateSliderWidth } from '../../../../utils/onboarding';
import { BusinessDetailsText } from '../types';
import { ServiceableAreaSectionProps } from './types';

const ServiceableAreaSection: React.FC<ServiceableAreaSectionProps> = ({
  sectionRef,
  isMobile,
  title,
  onboardingContractorType,
  serviceAreaRadiusInMiles,
  setServiceAreaRadiusInMiles,
}) => (
  <InformationSection
    title={title}
    sectionRef={sectionRef}
  >
    <div className="userProfile__section__inputRow">
      <div className="userProfile__section__inputRow__inputContainer">
        <div className="userProfile__section__inputRow__inputContainer__label">
          <div className="userProfile__section__inputRow__inputContainer__label--required">
            {OnboardingFormQuestions.Required}
          </div>
          <div className="questionContainer">
            <span className="questionText">
              {onboardingContractorType === ContractorTypeEnum.Surveyor
                ? BusinessDetailsText.SurveyorServiceableArea
                : BusinessDetailsText.InspectorServiceableArea}
            </span>&nbsp;
            <span className="questionTextSecondary">
              {onboardingContractorType === ContractorTypeEnum.Surveyor
                ? BusinessDetailsText.SurveyorParentheses
                : BusinessDetailsText.InspectorParentheses}
            </span>
          </div>
        </div>
        <div className="sliderContainer">
          <div
            id="handle_follower"
            className="sliderTooltip"
            style={{
              position: 'absolute',
              left: calculateSliderWidth(isMobile, serviceAreaRadiusInMiles),
              top: 5,
            }}
          >
            {serviceAreaRadiusInMiles?.toString()?.concat(' miles') || 'No value selected'}
          </div>
          <input
            id="distance_slider"
            type="range"
            className={!serviceAreaRadiusInMiles ? 'no-value' : undefined}
            min={5}
            max={200}
            step={5}
            value={serviceAreaRadiusInMiles}
            onChange={(e) => setServiceAreaRadiusInMiles(parseInt(e.target.value, 10))}
          />
          <div className="sliderLabelContainer">
            <div>{OnboardingFormQuestions.DistanceMin}</div>
            <div>{OnboardingFormQuestions.DistanceMax}</div>
          </div>
        </div>
      </div>
    </div>
  </InformationSection>
);

export default ServiceableAreaSection;
