import { ReportAttachment, ReportSeverityType } from 'marketplace-common';
import { WorkItem, WorkItemAttachment } from '../../types';

export enum RehabTabs {
  Report = 'Report',
  Rehab = 'Rehab',
}

export interface PriceBySystem {
  id: string
  price: number,
  missingPrice: boolean,
  displayIndex: number,
}

export type ModalInformations = { label: string, value: string | null | undefined }[];

export type WorkItemModalOptions = {
  isOpen: boolean
  attachments?: (ReportAttachment | WorkItemAttachment)[]
  systemName?: string
  subsystemName?: string
  informations?: {label: string, value: string | null | undefined}[]
  noteTitle?: string
  noteDetails?: string
  note?: string
  severity?: ReportSeverityType
  limitation?: boolean
  itemType?: string
  itemId?: string
  pricingExternalId?: string
  templateWorkItemPricingId?: string
  workItem?: WorkItem
  isAddingFromReport?: boolean
};

export type WorkItemInput = {
  systemName?: string
  title?: string
  details?: string
  unit?: string | null
  quantity?: number | null
  pricePerUnit?: number | null
  totalPrice?: number | null
  projectId?: string
  templateWorkItemPricingId?: string
  pricingExternalId?: string
  teamId?: number
  saveToLibrary?: boolean
  tags?: string[]
};

export interface PartialSubsystemNested {
  name: string;
  displayIndex: number;
  includeOnReport: boolean;
  status?: string;
  reportLimitations: string[];
  note: string | null;
  reportAttachments: string[];
  reportDeficiencies: string[];
  includeOnReportIfNotPresent: boolean;
}

export type PartialSystemNested = {
  name: string;
  displayIndex: number;
  includeOnReport: boolean;
  reportSubsystems: PartialSubsystemNested[];
};

export interface GroupedWorkItemsBySystem {
  [systemName: string]: {
    name: string;
    displayIndex: number;
    subsystems: { [subsystemName: string]: WorkItem[] };
  };
}
