import { useQuery } from '@apollo/client';
import cn from 'classnames';
import ConfirmIcon from 'haven-design-system/build/assets/svgs/action/confirm.svg?component';
import DownloadIcon from 'haven-design-system/build/assets/svgs/action/download_filled.svg?component';
import HistoryIcon from 'haven-design-system/build/assets/svgs/display/history.svg?component';
import uniq from 'lodash/uniq';
import React, { useEffect, useMemo } from 'react';
import { Button, Dropdown, Nav, Navbar } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';

import { FETCH_AVAILABLE_USERS } from '../../graphql/queries/user';
import { useReportPdf } from '../../hooks/report/useReportPdf';
import { SplitFlags } from '../../redux/actions';
import { selectActive, selectLayout, selectRehab, selectReports } from '../../redux/selectors';
import { useTypedSelector } from '../../redux/store';
import { AvailableUser, TeamUser } from '../../types';
import { signOut } from '../../utils/amplify_auth';
import { EventName, track } from '../../utils/analytics';
import { captureException } from '../../utils/error';
import { useUrlQuery } from '../../utils/hooks';
import { getStateCodeFromStateName } from '../../utils/map';
import { usePageContext } from '../../utils/pageContext';
import { downloadPermitPdf } from '../../utils/permits';
import { downloadProjectCsv, downloadProjectPdf } from '../../utils/rehab';
import { useTreatment } from '../../utils/splitio';
import { handleSwitchAvailableUser, useCurrentUser } from '../../utils/user';
import IconButton from '../IconButton';
import InspectifyCobrandingLogo from '../InspectifyCobrandingLogo';

import styles from './PageLayout.module.scss';
import { RehabPdfFilterOption } from './types';

export type Branding = 'inspectify' | 'inspectify-color' | 'inspectify-hippo' | 'cobranded-poweredby' | 'custom';

const Header: React.FC = () => {
  const currentUser = useCurrentUser();
  const { dispatch, state } = usePageContext();
  const { downloadReportPdf } = useReportPdf();

  const query = useUrlQuery();
  const history = useHistory();
  const inspectorDashboardMatch = useRouteMatch('/dashboard');
  const rehabToolPageMatch = useRouteMatch('/rehab/:projectId');
  const permitsPageMatch = useRouteMatch('/orders/:orderToken/permit_report');
  const isPrintMode = query.get('print') === 'true';
  const isPermitPdfReport = permitsPageMatch && isPrintMode;

  const active = useTypedSelector(selectActive);
  const reports = useTypedSelector(selectReports);
  const rehab = useTypedSelector(selectRehab);
  const {
    isLoading,
    displayNavbar,
    cobrandingLogoUrl,
    hideHeaderOnMobile,
    showSignIn,
  } = useTypedSelector(selectLayout);

  const featureRehabHistory = useTreatment(SplitFlags.FeatureRehabHistory);

  const { data: availableUsersData } = useQuery(FETCH_AVAILABLE_USERS, {
    variables: { onlyContractors: false },
    onError: (e) => {
      captureException(e, { source: 'Loading available users' });
    },
  });

  const handleDownloadRehabPdf = async (
    type?: RehabPdfFilterOption,
    values: string[] = [],
  ) => {
    const params = { ...active.project.params };

    if (type === RehabPdfFilterOption.Tags) {
      params.tags = values;
    } else if (type === RehabPdfFilterOption.Contributors) {
      params.contributors = values;
    }

    const loadingToastId = toast.info('Generating rehab project PDF...', { autoClose: false });
    const result = await downloadProjectPdf(active.project.id, params);

    toast.dismiss(loadingToastId);

    if (result.success) {
      toast.success('Rehab project PDF downloaded successfully.');
    } else {
      toast.error(`Failed to generate PDF: ${result.error}`);
    }

    track(EventName.DownloadRehabProjectPDF, {
      reportId: active.project.id,
      params,
    });
  };

  const handleDownloadRehabCsv = (tags: string[] = []) => {
    const params = { ...active.project.params };
    if (tags) params.tags = tags;
    downloadProjectCsv(active.project.id, params);
    track(EventName.DownloadRehabProjectCSV, { reportId: active.project.id, params });
    toast.info('Downloading rehab project CSV...', { autoClose: false });
  };

  useEffect(() => {
    // set the first available user as the selected user if no selected user is found
    const selectedUser = localStorage.getItem('inspectifySelectedUser');
    if (!selectedUser || selectedUser === 'undefined') {
      localStorage.setItem('inspectifySelectedUser', availableUsersData?.availableUsers?.[0]?.id || null);
    }
    const selectedTeams = localStorage.getItem('inspectifySelectedTeamIds');
    if (!selectedTeams || selectedTeams === 'undefined') {
      localStorage.setItem('inspectifySelectedTeamIds', availableUsersData?.availableUsers?.[0]?.teamUsers?.map((teamUser: TeamUser) => teamUser?.team?.id).join(',') || []);
    }
  }, [availableUsersData]);

  const display: Branding = useMemo(() => {
    if (cobrandingLogoUrl) {
      return 'cobranded-poweredby';
    }
    if (permitsPageMatch) {
      return 'inspectify-color';
    }

    return 'inspectify';
  }, [cobrandingLogoUrl]);

  const report = useMemo(() => (
    active.report?.id && reports[active.report.id] ? reports[active.report.id] : null
  ), [active.report, reports]);

  const rehabWorkItemFilterOptions = useMemo(() => {
    if (!active.project?.id) {
      return { tags: [], contributors: [] };
    }

    return Object.values(rehab.workItems || []).reduce(
      (acc, item) => {
        if (item.projectId === active.project.id) {
          acc.tags = uniq([...acc.tags, ...(item.tags || [])]);
          acc.contributors = uniq([
            ...acc.contributors,
            ...(item.workItemContributors?.flatMap((contributor) => (contributor.name ? [contributor.name] : [])) || []),
          ]);
        }
        return acc;
      },
      { tags: [] as string[], contributors: [] as string[] },
    );
  }, [active.project, rehab.workItems]);

  Object.entries(rehabWorkItemFilterOptions).flatMap(([type, values]) => values.map((value) => (
    <Dropdown alignRight key={`${type}-${value}-${new Date()}`}>
      <Dropdown.Toggle className={styles.navbarDropdownToggle}>
        {value} only
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          id="download-project-pdf"
          onClick={() => handleDownloadRehabPdf(type as RehabPdfFilterOption, [value])}
        >
          <div className={styles.navbarDropdownItem}>PDF</div>
        </Dropdown.Item>
        {type === RehabPdfFilterOption.Tags && (
          <Dropdown.Item
            id="download-project-csv"
            onClick={() => handleDownloadRehabCsv([value])}
          >
            <div className={styles.navbarDropdownItem}>CSV</div>
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )));

  const handleDownloadPermitsPdf = async () => {
    const params = { ...active.property.params };

    toast.info('Downloading permit report PDF...');
    await downloadPermitPdf(active.property.id, active.property.params);
    track(EventName.DownloadRehabProjectPDF, { reportId: active.project.id, params });
  };

  if (isPermitPdfReport) return null;

  return (
    <Navbar
      variant="dark"
      bg={displayNavbar ? 'primary' : 'light'}
      expand="lg"
      className={cn(
        displayNavbar ? styles.inspectifyNavBar : styles.navBarHidden,
        hideHeaderOnMobile ? styles.hideHeaderOnMobile : '',
        rehabToolPageMatch || permitsPageMatch ? styles.lightNavBar : '',
      )}
    >
      {displayNavbar && !isLoading && (
        <LinkContainer to="/">
          <Navbar.Brand className={styles.inspectifyNavBarBrand}>
            <InspectifyCobrandingLogo
              display={display}
              customPrimaryLogoSrc={cobrandingLogoUrl}
            />
          </Navbar.Brand>
        </LinkContainer>
      )}

      {rehabToolPageMatch && !isLoading && (
        <Navbar.Brand>
          <div className={styles.navbarHeaderText}>Rehab Tool</div>
        </Navbar.Brand>
      )}

      {permitsPageMatch && !isLoading && (
        <Navbar.Brand className={styles.permitNavbarBrand}>
          <div className={styles.permitNavbarHeaderContainer}>
            Permit Data
            <div className={styles.betaLabel}>
              Beta Feature
            </div>
          </div>
        </Navbar.Brand>
      )}

      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        {!isLoading && (
          <Nav>
            {currentUser && rehabToolPageMatch && (featureRehabHistory === 'on') && (
              <IconButton
                onClick={() => {
                  dispatch({
                    type: 'set_page_state',
                    payload: {
                      isProjectHistoryPanelOpen: !state.pageState?.isProjectHistoryPanelOpen,
                    },
                  });
                }}
              >
                <HistoryIcon className={styles.navbarButton} />
              </IconButton>
            )}

            {(active.project.id || report?.report?.publishedAt || permitsPageMatch) && (
              <Dropdown
                id="download-dropdown"
                alignRight
                className="d-flex justify-content-end px-3"
              >
                <Dropdown.Toggle as={IconButton} variant="link">
                  <DownloadIcon className={styles.navbarButton} />
                </Dropdown.Toggle>
                <Dropdown.Menu className={styles.navbarDropdownMenu}>
                  {report?.report?.publishedAt && (
                    <Dropdown.Item
                      id="download-report-pdf"
                      className={styles.navbarDropdownItem}
                      onClick={() => downloadReportPdf()}
                    >
                      Inspection report PDF
                    </Dropdown.Item>
                  )}
                  {permitsPageMatch && !isLoading && (
                    <Dropdown.Item
                      id="download-permit-report-pdf"
                      className={styles.navbarDropdownItem}
                      onClick={() => handleDownloadPermitsPdf()}
                    >
                      Permit report PDF
                    </Dropdown.Item>
                  )}

                  {rehabToolPageMatch && active.project.id && (
                    <Dropdown id="download-project" alignRight drop="left">
                      <Dropdown.Toggle className={styles.navbarDropdownToggle}>
                        Rehab project
                      </Dropdown.Toggle>
                      <Dropdown.Menu className={styles.navbarDropdownMenu}>
                        <Dropdown>
                          <Dropdown.Toggle className={styles.navbarDropdownToggle}>
                            All work items
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item id="download-project-pdf" onClick={() => handleDownloadRehabPdf()}>
                              <div className={styles.navbarDropdownItem}>PDF</div>
                            </Dropdown.Item>
                            <Dropdown.Item id="download-project-csv" onClick={() => handleDownloadRehabCsv()}>
                              <div className={styles.navbarDropdownItem}>CSV</div>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>

                        {Object.entries(rehabWorkItemFilterOptions).flatMap(([type, values]) => values.map((value) => (
                          <Dropdown alignRight key={`${type}-${value}-${new Date()}`}>
                            <Dropdown.Toggle className={styles.navbarDropdownToggle}>
                              {value} only
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                id="download-project-pdf"
                                onClick={() => handleDownloadRehabPdf(type as RehabPdfFilterOption, [value])}
                              >
                                <div className={styles.navbarDropdownItem}>PDF</div>
                              </Dropdown.Item>
                              {type === RehabPdfFilterOption.Tags && (
                              <Dropdown.Item
                                id="download-project-csv"
                                onClick={() => handleDownloadRehabCsv([value])}
                              >
                                <div className={styles.navbarDropdownItem}>CSV</div>
                              </Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        )))}

                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </Nav>
        )}

        {showSignIn && <Navbar.Toggle aria-controls="responsive-navbar-nav" />}

        {!currentUser && showSignIn && (
          <div
            className={cn(
              styles.navbarText,
              'text-right',
              displayNavbar ? styles.hideTopBarText : 'text-muted',
            )}
          >
            Already have an account?&nbsp;
            <Button
              id="sign-in-button"
              variant="link"
              className={cn(
                'px-0',
                styles.navbarButton,
                displayNavbar ? 'text-white' : styles.hideTopBarText,
              )}
              onClick={() => {
                history.push(`/sign_in?next=${window.location.pathname}`);
              }}
            >
              Sign In
            </Button>
          </div>
        )}

        {currentUser && showSignIn && (
          <>
            {/* Desktop menu */}
            <Nav>
              <Dropdown
                alignRight
                className="d-none d-lg-block"
              >
                <Dropdown.Toggle
                  id="header-nav-dropdown"
                  variant="link"
                  className={inspectorDashboardMatch ? styles.navbarUserButton : ''}
                >
                  {currentUser?.name || currentUser?.email}
                </Dropdown.Toggle>
                <Dropdown.Menu className={styles.navbarDropdownAccountsMenu}>
                  <Dropdown.Item
                    id="accounts-section"
                    className={styles.navbarDropdownAccount}
                  >
                    Accounts
                  </Dropdown.Item>
                  {availableUsersData?.availableUsers?.map((availableUser: AvailableUser) => (
                    <Dropdown.Item
                      key={availableUser.id + (availableUser.contractorId || availableUser.teamUsers?.[0]?.team?.company?.id)}
                      className={styles.navbarDropdownAccountButton}
                      onClick={
                        () => handleSwitchAvailableUser(availableUser)
                      }
                    >
                      {availableUser?.contractor ? (
                        <div className={styles.accountContainer}>
                          <div className={styles.textContainer}>
                            <div className={styles.navbarDropdownAccountNameText}>
                              {availableUser?.contractor?.businessName}
                            </div>
                            <div className={styles.navbarDropdownAccountCityStateText}>
                              {`${availableUser?.contractor?.city}, ${getStateCodeFromStateName(availableUser?.contractor?.state)}`}
                            </div>
                          </div>
                          <div className={styles.iconContainer}>
                            {localStorage.getItem('inspectifySelectedUser') === availableUser?.id
                              && <ConfirmIcon className={styles.confirmIcon} />}
                          </div>
                        </div>
                      ) : (
                        <div className={styles.accountContainer}>
                          <div className={styles.navbarDropdownAccountNameText}>
                            {availableUser?.teamUsers?.[0]?.team?.company?.name || currentUser?.name || currentUser?.email}
                          </div>
                          <div className={styles.iconContainer}>
                            {localStorage.getItem('inspectifySelectedUser') === availableUser?.id
                              && <ConfirmIcon className={styles.confirmIcon} />}
                          </div>
                        </div>
                      )}
                    </Dropdown.Item>
                  ))}
                  <Dropdown.Divider />
                  {inspectorDashboardMatch && (
                    <Dropdown.Item
                      id="help-center-button"
                      className={styles.navbarDropdownItem}
                      href="https://knowledge.inspectify.com/"
                      target="_blank"
                    >
                      Help Center
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    id="help-center-button"
                    className={styles.navbarDropdownItem}
                    href="https://public-inspectify.s3.amazonaws.com/documents/Inspectify+-+Terms+of+Use.pdf"
                    target="_blank"
                  >
                    Terms of use
                  </Dropdown.Item>
                  <Dropdown.Item
                    id="help-center-button"
                    className={styles.navbarDropdownItem}
                    href="https://www.inspectify.com/company/privacy-policy"
                    target="_blank"
                  >
                    Privacy policy
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item id="logout-button" className={styles.navbarDropdownItem} onClick={signOut}>
                    Logout
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </>
        )}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Header;
