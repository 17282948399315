import React from 'react';
import '../../styles.scss';
import { BasicInformationSectionProps } from './types';
import {
  formatPhoneNumber,
  unformatPhoneNumber,
  validateEmail,
  validateZipCode,
} from '../../../../utils/string';
import { StateCodeEnum, StateEnum } from '../../../../types/address';
import { BasicInformation } from '../../../Onboarding/OnboardingFormPage/types';
import { ContractorTypeEnum } from '../../../../types';
import InformationSection from '../../../../components/InformationSection';

const BasicInformationSection: React.FC<BasicInformationSectionProps> = ({
  firstName,
  setFirstName,
  lastName,
  setLastName,
  businessName,
  setBusinessName,
  website,
  setWebsite,
  businessContactName,
  setBusinessContactName,
  phoneNumber,
  setPhoneNumber,
  email,
  setEmail,
  schedulerName,
  setSchedulerName,
  schedulerPhoneNumber,
  setSchedulerPhoneNumber,
  schedulerEmail,
  setSchedulerEmail,
  street,
  setStreet,
  city,
  setCity,
  state,
  setState,
  zipCode,
  setZipCode,
  showZipCodeError,
  setShowZipCodeError,
  showPhoneError,
  setShowPhoneError,
  showEmailError,
  setShowEmailError,
  showSchedulerPhoneError,
  setShowSchedulerPhoneError,
  showSchedulerEmailError,
  setShowSchedulerEmailError,
  addressRef,
  isMobile,
  onboardingContractorType,
  sectionRef,
}) => (
  <InformationSection
    title={BasicInformation.Title}
    sectionRef={sectionRef}
  >
    {onboardingContractorType === ContractorTypeEnum.Inspector ? (
      <>
        <div className="userProfile__section__inputRow">
          <div className="userProfile__section__inputRow__inputContainer">
            <div className="userProfile__section__inputRow__inputContainer__label">
              <div className="userProfile__section__inputRow__inputContainer__label--required">
                {BasicInformation.Required}
              </div>
              {BasicInformation.BusinessName}
            </div>
            <input
              className="userProfile__section__inputRow__inputContainer__input"
              type="text"
              value={businessName}
              name="business_name"
              onChange={(e) => setBusinessName(e.target.value)}
            />
          </div>
          <div className="userProfile__section__inputRow__inputContainer">
            <div className="userProfile__section__inputRow__inputContainer__label">
              {BasicInformation.Website}
            </div>
            <input
              className="userProfile__section__inputRow__inputContainer__input"
              type="text"
              value={website}
              name="website"
              onChange={(e) => setWebsite(e.target.value)}
            />
          </div>
        </div>
        <div className="userProfile__section__inputRow">
          <div className="userProfile__section__inputRow__inputContainer">
            <div className="userProfile__section__inputRow__inputContainer__label">
              {BasicInformation.BusinessContactName}
            </div>
            <input
              className="userProfile__section__inputRow__inputContainer__input"
              type="text"
              value={businessContactName}
              name="business_contact_name"
              onChange={(e) => setBusinessContactName(e.target.value)}
            />
          </div>
          <div className="userProfile__section__inputRow__inputContainer">
            <div className="userProfile__section__inputRow__inputContainer__label">
              <div className="userProfile__section__inputRow__inputContainer__label--required">
                {BasicInformation.Required}
              </div>
              {BasicInformation.BusinessPhoneNumber}
            </div>
            <input
              className={
                    showPhoneError
                      ? 'userProfile__section__inputRow__inputContainer__input--error'
                      : 'userProfile__section__inputRow__inputContainer__input'
                  }
              type="tel"
              value={formatPhoneNumber(phoneNumber)}
              onBlur={() => {
                setShowPhoneError(phoneNumber.length > 0 && unformatPhoneNumber(phoneNumber).length < 10);
              }}
              name="business_phone_number"
              required
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            {showPhoneError && (
            <div className="userProfile__section__inputRow__inputContainer__errorText">
              {BasicInformation.PhoneError}
            </div>
            )}
          </div>
          <div className="userProfile__section__inputRow__inputContainer">
            <div className="userProfile__section__inputRow__inputContainer__label">
              <div className="userProfile__section__inputRow__inputContainer__label--required">
                {BasicInformation.Required}
              </div>
              {BasicInformation.BusinessEmail}
            </div>
            <input
              className={
                      showEmailError
                        ? 'userProfile__section__inputRow__inputContainer__input--error'
                        : 'userProfile__section__inputRow__inputContainer__input'
                    }
              type="email"
              value={email}
              required
              onBlur={
                      () => setShowEmailError(email.length > 0
                        && !validateEmail(email))
                    }
              name="business_email"
              onChange={(e) => setEmail(e.target.value)}
            />
            {showEmailError && (
            <div className="userProfile__section__inputRow__inputContainer__errorText">
              {BasicInformation.EmailError}
            </div>
            )}
          </div>
        </div>
        <div className="userProfile__section__inputRow">
          <div className="userProfile__section__inputRow__inputContainer">
            <div className="userProfile__section__inputRow__inputContainer__label">
              {BasicInformation.SchedulerName}
            </div>
            <input
              className="userProfile__section__inputRow__inputContainer__input"
              type="text"
              value={schedulerName}
              name="scheduler_name"
              onChange={(e) => setSchedulerName(e.target.value)}
            />
          </div>
          <div className="userProfile__section__inputRow__inputContainer">
            <div className="userProfile__section__inputRow__inputContainer__label">
              {BasicInformation.SchedulerPhoneNumber}
            </div>
            <input
              className={
                    showSchedulerPhoneError
                      ? 'userProfile__section__inputRow__inputContainer__input--error'
                      : 'userProfile__section__inputRow__inputContainer__input'
                  }
              type="tel"
              value={formatPhoneNumber(schedulerPhoneNumber)}
              onBlur={() => {
                setShowSchedulerPhoneError(schedulerPhoneNumber.length > 0 && unformatPhoneNumber(schedulerPhoneNumber).length < 10);
              }}
              name="scheduler_phone_number"
              required
              onChange={(e) => setSchedulerPhoneNumber(e.target.value)}
            />
            {showSchedulerPhoneError && (
            <div className="userProfile__section__inputRow__inputContainer__errorText">
              {BasicInformation.PhoneError}
            </div>
            )}
          </div>
          <div className="userProfile__section__inputRow__inputContainer">
            <div className="userProfile__section__inputRow__inputContainer__label">
              {BasicInformation.SchedulerEmail}
            </div>
            <input
              className={
                      showSchedulerEmailError
                        ? 'userProfile__section__inputRow__inputContainer__input--error'
                        : 'userProfile__section__inputRow__inputContainer__input'
                    }
              type="email"
              value={schedulerEmail}
              required
              onBlur={() => setShowSchedulerEmailError(schedulerEmail.length > 0
                        && !validateEmail(schedulerEmail))}
              name="scheduler_email"
              onChange={(e) => setSchedulerEmail(e.target.value)}
            />
            {showSchedulerEmailError && (
            <div className="userProfile__section__inputRow__inputContainer__errorText">
              {BasicInformation.EmailError}
            </div>
            )}
          </div>
        </div>
        <div className="userProfile__section__inputRow">
          <div className="userProfile__section__inputRow__inputContainer">
            <div className="userProfile__section__inputRow__inputContainer__label">
              <div className="userProfile__section__inputRow__inputContainer__label--required">
                {BasicInformation.Required}
              </div>
              {BasicInformation.StreetAddress}
            </div>
            <input
              className="userProfile__section__inputRow__inputContainer__input"
              type="text"
              ref={addressRef}
              value={street}
              name="street"
              onChange={(e) => setStreet(e.target.value)}
            />
          </div>
        </div>
        <div className="userProfile__section__inputRow">
          <div className="userProfile__section__inputRow__inputContainer">
            <div className="userProfile__section__inputRow__inputContainer__label">
              <div className="userProfile__section__inputRow__inputContainer__label--required">
                {BasicInformation.Required}
              </div>
              {BasicInformation.City}
            </div>
            <input
              className="userProfile__section__inputRow__inputContainer__input"
              type="text"
              value={city}
              name="city"
              onChange={(e) => setCity(e.target.value)}
            />
          </div>
          {isMobile ? (
            <div className="stateZipCodeContainer">
              <div className="userProfile__section__inputRow__inputContainer">
                <div className="userProfile__section__inputRow__inputContainer__label">
                  <div className="userProfile__section__inputRow__inputContainer__label--required">
                    {BasicInformation.Required}
                  </div>
                  {BasicInformation.State}
                </div>
                <select
                  className="userProfile__section__inputRow__inputContainer__input"
                  value={state}
                  name="state"
                  onChange={(e) => setState(e.target.value as StateEnum | StateCodeEnum)}
                >
                  <option key="blank">{' '}</option>
                  {Object.values(StateEnum).map((stateName: StateEnum | StateCodeEnum) => (
                    <option key={stateName}>{stateName}</option>
                  ))}
                </select>
              </div>
              <div className="userProfile__section__inputRow__inputContainer">
                <div className="userProfile__section__inputRow__inputContainer__label">
                  <div className="userProfile__section__inputRow__inputContainer__label--required">
                    {BasicInformation.Required}
                  </div>
                  {BasicInformation.ZipCode}
                </div>
                <input
                  className={
                        showZipCodeError
                          ? 'userProfile__section__inputRow__inputContainer__input--error'
                          : 'userProfile__section__inputRow__inputContainer__input'
                      }
                  type="text"
                  value={zipCode}
                  name="zipCode"
                  onChange={(e) => setZipCode(e.target.value)}
                  onBlur={
                      () => setShowZipCodeError(zipCode.length > 0
                        && !validateZipCode(zipCode))
                    }
                />
                {showZipCodeError && (
                <div className="userProfile__section__inputRow__inputContainer__errorText">
                  {BasicInformation.ZipCodeError}
                </div>
                )}
              </div>
            </div>
          ) : (
            <>
              <div className="userProfile__section__inputRow__inputContainer">
                <div className="userProfile__section__inputRow__inputContainer__label">
                  <div className="userProfile__section__inputRow__inputContainer__label--required">
                    {BasicInformation.Required}
                  </div>
                  {BasicInformation.State}
                </div>
                <select
                  className="userProfile__section__inputRow__inputContainer__input"
                  value={state}
                  name="state"
                  onChange={(e) => setState(e.target.value as StateEnum | StateCodeEnum)}
                >
                  <option key="blank">{' '}</option>
                  {Object.values(StateEnum).map((stateName: StateEnum | StateCodeEnum) => (
                    <option key={stateName}>{stateName}</option>
                  ))}
                </select>
              </div>
              <div className="userProfile__section__inputRow__inputContainer">
                <div className="userProfile__section__inputRow__inputContainer__label">
                  <div className="userProfile__section__inputRow__inputContainer__label--required">
                    {BasicInformation.Required}
                  </div>
                  {BasicInformation.ZipCode}
                </div>
                <input
                  className={
                        showZipCodeError
                          ? 'userProfile__section__inputRow__inputContainer__input--error'
                          : 'userProfile__section__inputRow__inputContainer__input'
                      }
                  type="text"
                  value={zipCode}
                  name="zipCode"
                  onChange={(e) => setZipCode(e.target.value)}
                  onBlur={
                      () => setShowZipCodeError(zipCode.length > 0
                        && !validateZipCode(zipCode))
                    }
                />
                {showZipCodeError && (
                <div className="userProfile__section__inputRow__inputContainer__errorText">
                  {BasicInformation.ZipCodeError}
                </div>
                )}
              </div>
            </>
          )}
        </div>
      </>
    ) : (
      <>
        <div className="userProfile__section__inputRow">
          <div className="userProfile__section__inputRow__inputContainer">
            <div className="userProfile__section__inputRow__inputContainer__label">
              <div className="userProfile__section__inputRow__inputContainer__label--required">
                {BasicInformation.Required}
              </div>
              {BasicInformation.FirstName}
            </div>
            <input
              className="userProfile__section__inputRow__inputContainer__input"
              type="text"
              value={firstName}
              name="first_name"
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="userProfile__section__inputRow__inputContainer">
            <div className="userProfile__section__inputRow__inputContainer__label">
              <div className="userProfile__section__inputRow__inputContainer__label--required">
                {BasicInformation.Required}
              </div>
              {BasicInformation.LastName}
            </div>
            <input
              className="userProfile__section__inputRow__inputContainer__input"
              type="text"
              value={lastName}
              name="last_name"
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
        </div>
        <div className="userProfile__section__inputRow">
          <div className="userProfile__section__inputRow__inputContainer">
            <div className="userProfile__section__inputRow__inputContainer__label">
              <div className="userProfile__section__inputRow__inputContainer__label--required">
                {BasicInformation.Required}
              </div>
              {BasicInformation.Phone}
            </div>
            <input
              className={
                    showPhoneError
                      ? 'userProfile__section__inputRow__inputContainer__input--error'
                      : 'userProfile__section__inputRow__inputContainer__input'
                  }
              type="tel"
              value={formatPhoneNumber(phoneNumber)}
              onBlur={() => {
                setShowPhoneError(phoneNumber.length > 0 && unformatPhoneNumber(phoneNumber).length < 10);
              }}
              name="phone_number"
              required
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            {showPhoneError && (
            <div className="userProfile__section__inputRow__inputContainer__errorText">
              {BasicInformation.PhoneError}
            </div>
            )}
          </div>
          <div className="userProfile__section__inputRow__inputContainer">
            <div className="userProfile__section__inputRow__inputContainer__label">
              <div className="userProfile__section__inputRow__inputContainer__label--required">
                {BasicInformation.Required}
              </div>
              {BasicInformation.Email}
            </div>
            <input
              className={
                      showEmailError
                        ? 'userProfile__section__inputRow__inputContainer__input--error'
                        : 'userProfile__section__inputRow__inputContainer__input'
                    }
              type="email"
              value={email}
              required
              onBlur={
                      () => setShowEmailError(email.length > 0
                        && !validateEmail(email))
                    }
              name="email"
              onChange={(e) => setEmail(e.target.value)}
            />
            {showEmailError && (
            <div className="userProfile__section__inputRow__inputContainer__errorText">
              {BasicInformation.EmailError}
            </div>
            )}
          </div>
        </div>
        <div className="userProfile__section__inputRow">
          <div className="userProfile__section__inputRow__inputContainer">
            <div className="userProfile__section__inputRow__inputContainer__label">
              <div className="userProfile__section__inputRow__inputContainer__label--required">
                {BasicInformation.Required}
              </div>
              {BasicInformation.StreetAddress}
            </div>
            <input
              className="userProfile__section__inputRow__inputContainer__input"
              type="text"
              ref={addressRef}
              value={street}
              name="street"
              onChange={(e) => setStreet(e.target.value)}
            />
          </div>
        </div>
        <div className="userProfile__section__inputRow">
          <div className="userProfile__section__inputRow__inputContainer">
            <div className="userProfile__section__inputRow__inputContainer__label">
              <div className="userProfile__section__inputRow__inputContainer__label--required">
                {BasicInformation.Required}
              </div>
              {BasicInformation.City}
            </div>
            <input
              className="userProfile__section__inputRow__inputContainer__input"
              type="text"
              value={city}
              name="city"
              onChange={(e) => setCity(e.target.value)}
            />
          </div>
          {isMobile ? (
            <div className="stateZipCodeContainer">
              <div className="userProfile__section__inputRow__inputContainer">
                <div className="userProfile__section__inputRow__inputContainer__label">
                  <div className="userProfile__section__inputRow__inputContainer__label--required">
                    {BasicInformation.Required}
                  </div>
                  {BasicInformation.State}
                </div>
                <select
                  className="userProfile__section__inputRow__inputContainer__input"
                  value={state}
                  name="state"
                  onChange={(e) => setState(e.target.value as StateEnum | StateCodeEnum)}
                >
                  <option key="blank">{' '}</option>
                  {Object.values(StateEnum).map((stateName: StateEnum | StateCodeEnum) => (
                    <option key={stateName}>{stateName}</option>
                  ))}
                </select>
              </div>
              <div className="userProfile__section__inputRow__inputContainer">
                <div className="userProfile__section__inputRow__inputContainer__label">
                  <div className="userProfile__section__inputRow__inputContainer__label--required">
                    {BasicInformation.Required}
                  </div>
                  {BasicInformation.ZipCode}
                </div>
                <input
                  className={
                        showZipCodeError
                          ? 'userProfile__section__inputRow__inputContainer__input--error'
                          : 'userProfile__section__inputRow__inputContainer__input'
                      }
                  type="text"
                  value={zipCode}
                  name="zipCode"
                  onChange={(e) => setZipCode(e.target.value)}
                  onBlur={
                      () => setShowZipCodeError(zipCode.length > 0
                        && !validateZipCode(zipCode))
                    }
                />
                {showZipCodeError && (
                <div className="userProfile__section__inputRow__inputContainer__errorText">
                  {BasicInformation.ZipCodeError}
                </div>
                )}
              </div>
            </div>
          ) : (
            <>
              <div className="userProfile__section__inputRow__inputContainer">
                <div className="userProfile__section__inputRow__inputContainer__label">
                  <div className="userProfile__section__inputRow__inputContainer__label--required">
                    {BasicInformation.Required}
                  </div>
                  {BasicInformation.State}
                </div>
                <select
                  className="userProfile__section__inputRow__inputContainer__input"
                  value={state}
                  name="state"
                  onChange={(e) => setState(e.target.value as StateEnum | StateCodeEnum)}
                >
                  <option key="blank">{' '}</option>
                  {Object.values(StateEnum).map((stateName: StateEnum | StateCodeEnum) => (
                    <option key={stateName}>{stateName}</option>
                  ))}
                </select>
              </div>
              <div className="userProfile__section__inputRow__inputContainer">
                <div className="userProfile__section__inputRow__inputContainer__label">
                  <div className="userProfile__section__inputRow__inputContainer__label--required">
                    {BasicInformation.Required}
                  </div>
                  {BasicInformation.ZipCode}
                </div>
                <input
                  className={
                        showZipCodeError
                          ? 'userProfile__section__inputRow__inputContainer__input--error'
                          : 'userProfile__section__inputRow__inputContainer__input'
                      }
                  type="text"
                  value={zipCode}
                  name="zipCode"
                  onChange={(e) => setZipCode(e.target.value)}
                  onBlur={
                      () => setShowZipCodeError(zipCode.length > 0
                        && !validateZipCode(zipCode))
                    }
                />
                {showZipCodeError && (
                <div className="userProfile__section__inputRow__inputContainer__errorText">
                  {BasicInformation.ZipCodeError}
                </div>
                )}
              </div>
            </>
          )}
        </div>
      </>
    )}
  </InformationSection>
);

export default BasicInformationSection;
