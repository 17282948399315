import React, {
  useContext,
  useEffect, useMemo, useRef, useState,
} from 'react';
import isNil from 'lodash/isNil';
import {
  getAnswer,
  ReportInformation,
  ReportSeverityType,
} from 'marketplace-common';
import { colorPaletteGrayscale100, colorPaletteGrayscale500, colorPalettePurple100, colorPalettePurple600 } from 'haven-design-system/build/typescript/es6';
import ScanIcon from 'haven-design-system/build/assets/svgs/camera/photo/scan.svg?component';
import { useReportsPageContext } from '../../../../../utils/reportsPageContext';
import { formatMoney } from '../../../../../utils/money';
import TagIcon from '../../../../../assets/icons/tag.svg?component';
import house from '../../../../../assets/icons/house.svg';
import pencil from '../../../../../assets/icons/pencil.svg';
import { RehabInformationData } from '../../../../../types';
import MediaWorkItem from './MediaWorkItem';
import {
  WorkItemContainer,
  WorkItemDetails,
  WorkItemDetailsContainer,
  WorkItemTitle,
  AltImageWrapper,
  WorkItemPricingContainer,
  Price,
  PriceBreakdown,
  EditContainer,
  EditText,
  AltImage,
  WorkItemTagsContainer,
  WorkItemTag,
  ContributorIcon,
} from './styles';
import { useTypedSelector } from '../../../../../redux/store';
import {
  selectRehab,
  selectWorkItem,
  selectWorkItemAssociations,
  selectWorkItemAttachments,
} from '../../../../../redux/selectors';
import { RehabToolContext } from '../../../context';

interface Props {
  workItemId: string;
}

const RehabWorkItem: React.FC<Props> = ({
  workItemId,
}) => {
  const workItemRef = useRef();
  const { dispatch, state: contextState } = useReportsPageContext();
  const { state: { isCostSplittingEnabled, report, isRehabReadOnly }, setWorkItemModalOptions } = useContext(RehabToolContext);

  const rehab = useTypedSelector(selectRehab);
  const workItem = useTypedSelector((state) => selectWorkItem(state, workItemId));
  const workItemAttachments = useTypedSelector(
    (state) => selectWorkItemAttachments(state, workItemId),
  );
  const workItemAssociations = useTypedSelector(
    (state) => selectWorkItemAssociations(state, workItemId),
  );

  const isPricePending = isNil(workItem?.totalPrice);
  const isIncomplete = isPricePending || !workItem?.title;
  const isLumpSumCost = (isNil(workItem?.pricePerUnit) && isNil(workItem?.unit)) && !isPricePending;

  const [imageIndex, setImageIndex] = useState(0);
  const [title, setTitle] = useState('');
  const [detail, setDetail] = useState('');
  const [note, setNote] = useState('');
  const [itemType, setItemType] = useState('');
  const [severity, setSeverity] = useState<ReportSeverityType>(null);

  const { setIncompleteWorkItemOptions } = useContext(RehabToolContext);

  const workItemSubsystemNames = useMemo(() => (workItemAssociations.length > 0
    ? workItemAssociations.reduce((acc, ass) => {
      // have to do this bc data interface may not have a name field, only for ReportSubsystemData
      if (ass?.data && 'name' in ass.data) return [...acc, ass.data.name];
      if (ass?.data && 'reportSubsystemId' in ass.data && report?.subsystems[ass.data.reportSubsystemId]) {
        return [...acc, report.subsystems[ass.data.reportSubsystemId].name];
      }
      return acc;
    }, [] as string[]) : []
  ), [workItemAssociations]);

  const workItemInformations = useMemo(() => (
    workItemAssociations.filter((ass) => ass?.itemType === 'ReportInformation')
  ), [workItemAssociations]);

  const media = useMemo(() => (
    (imageIndex >= 0 && workItemAttachments.length > 0 && workItemAttachments[imageIndex])
      ? workItemAttachments[imageIndex] : null
  ), [imageIndex, workItemAttachments, workItem, rehab]);

  const formattedWorkItemInformations = useMemo(() => (
    workItemInformations.map((wII) => ({
      label: (wII.data as RehabInformationData).title,
      value: getAnswer(wII.data as ReportInformation),
    }))
  ), [workItemInformations]);

  useEffect(() => {
    if (workItemRef.current) {
      dispatch({ type: 'add_work_item_ref', payload: workItemRef });
    }
  }, [workItemRef.current]);

  useEffect(() => {
    if (workItemRef.current && contextState.activeWorkItem === workItemId) {
      (workItemRef.current as any).scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [workItemRef.current, contextState.activeWorkItem]);

  // note stef: in future make reusable / update context not local state?
  useEffect(() => {
    if (workItemAssociations.length > 0) {
      // hardcoded 0 indexes below due to data designed to support
      // multiple workItemAssociations in one work item
      // but UI design is not atm.
      const firstAss = workItemAssociations[0];
      setItemType(firstAss.itemType);
      if (firstAss.data) {
        // have to do this bc ReportAttachmentData interface does not have a note field
        if ('note' in firstAss.data) setNote(firstAss.data.note);
        // have to do this bc ReportSubsystemData interface does not have a title field
        if ('title' in firstAss.data) setTitle(firstAss.data.title);
        // have to do this bc ReportSubsystemData interface does not have a detail field
        if ('detail' in firstAss.data) setDetail(firstAss.data.detail);
        if ('severity' in firstAss.data) setSeverity(firstAss.data.severity);
      }
    }
  }, [workItemAssociations]);

  useEffect(() => setImageIndex(0), [rehab]);

  useEffect(() => {
    if (isIncomplete) {
      setIncompleteWorkItemOptions({
        isOpen: true,
        attachments: workItemAttachments,
        systemName: workItem?.systemName,
        subsystemName: workItemSubsystemNames && workItemSubsystemNames[0],
        informations: formattedWorkItemInformations,
        noteTitle: title,
        noteDetails: detail,
        note,
        severity: itemType === 'ReportDeficiency' ? severity : undefined,
        limitation: itemType === 'ReportLimitation',
        pricingExternalId: workItem?.pricingExternalId,
        templateWorkItemPricingId: workItem?.templateWorkItemPricingId,
        workItem,
      });
    }
  }, [isIncomplete]);

  const shiftIndex = (incrementBy: number) => {
    const newIndex = (imageIndex + incrementBy);
    if (workItem && newIndex >= 0 && newIndex < workItem.workItemAttachments?.length) {
      setImageIndex(newIndex);
    } else {
      setImageIndex(0);
    }
  };

  const priceBreakdown = () => {
    if (isPricePending) return '';
    if (isLumpSumCost) return 'lump sum cost';

    let str = isNil(workItem?.pricePerUnit) ? '' : `${formatMoney(workItem.pricePerUnit)}`;
    str = isNil(workItem?.quantity) ? str : `${str.length ? `${str} x ` : ''}${workItem.quantity}`;
    str = isNil(workItem?.unit) ? str : `${str} ${workItem.unit}`;
    if (str === '0') return 'Unknown price breakdown';
    return str;
  };

  const setIsWorkItemModalOpen = () => {
    setWorkItemModalOptions({
      isOpen: true,
      attachments: workItemAttachments,
      systemName: workItem?.systemName,
      subsystemName: workItem?.subsystemName,
      informations: formattedWorkItemInformations,
      noteTitle: title,
      noteDetails: detail,
      note,
      severity: itemType === 'ReportDeficiency' ? severity : undefined,
      limitation: itemType === 'ReportLimitation',
      pricingExternalId: workItem?.pricingExternalId,
      templateWorkItemPricingId: workItem?.templateWorkItemPricingId,
      workItem,
      isAddingFromReport: false,
    });
  };

  return (
    <WorkItemContainer ref={workItemRef} isActive={contextState.activeWorkItem === workItemId}>
      {media ? (
        <MediaWorkItem
          attachmentUrl={media.attachmentUrl}
          thumbnailUrl={media.thumbnailUrl}
          s3ObjectKey={media.s3ObjectKey}
          imageIndex={imageIndex}
          totalImages={(workItem?.workItemAttachments?.length || 1) - 1}
          handleChangeImageIndex={shiftIndex}
        />
      ) : (
        <AltImageWrapper>
          <AltImage src={house} alt="home" />
        </AltImageWrapper>
      )}

      <WorkItemDetailsContainer>
        <WorkItemTitle isItalic={!workItem?.title}>{workItem?.title ? workItem.title : 'Undefined work item'}</WorkItemTitle>
        <WorkItemDetails>{workItem?.details}</WorkItemDetails>
        {(workItem?.tags?.length > 0 || workItem.pricingExternalId?.length > 0) && (
          <WorkItemTagsContainer>
            {workItem?.tags?.length > 0 && workItem.tags.map((tag) => (
              <WorkItemTag key={`${workItem.id}-${tag}`}><TagIcon /> {tag}</WorkItemTag>
            ))}
            {workItem.pricingExternalId?.length > 0 && (
              <WorkItemTag $color={colorPaletteGrayscale500} $background={colorPaletteGrayscale100}>
                <ScanIcon /> {workItem.pricingExternalId}
              </WorkItemTag>
            )}
            {isCostSplittingEnabled && workItem.workItemContributors?.length > 0 && workItem.workItemContributors.map((contributor) => (
              <WorkItemTag key={`${workItem.id}-${contributor.name}`} $color={colorPalettePurple600} $background={colorPalettePurple100}>
                <ContributorIcon /> {contributor.name}
              </WorkItemTag>
            ))}
          </WorkItemTagsContainer>
        )}
      </WorkItemDetailsContainer>

      <WorkItemPricingContainer>
        <Price>{isPricePending ? 'Price Pending' : `${formatMoney(workItem.totalPrice)}`}</Price>
        <PriceBreakdown>{priceBreakdown()}</PriceBreakdown>
        {!isRehabReadOnly && (
          <EditContainer id={`edit-button-${workItemId}`} onClick={() => setIsWorkItemModalOpen()}>
            <img src={pencil} alt="edit button" />
            <EditText>Edit work item</EditText>
          </EditContainer>
        )}
      </WorkItemPricingContainer>
    </WorkItemContainer>
  );
};

export default RehabWorkItem;
