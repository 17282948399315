import { Contractor, ContractorFileAttachment, ContractorPricingInput, ContractorTypeEnum } from '../../../types';
import { StateCodeEnum, StateEnum } from '../../../types/address';
import { InspectionServiceType } from './BusinessDetailsSections/InspectionService/types';

export interface ContractorState {
  contractor: Contractor,
  hasStateChanged: boolean,
}

export enum BusinessDetailsSection {
  BasicInformation = 'Basic information',
  InspectionServices = 'Inspection services',
  Certifications = 'Certifications',
  Documents = 'Documents',
  AccessAndSoftware = 'Access and software',
  ServiceableArea = 'Serviceable area',
}

export enum BusinessDetailsText {
  SupraAccess = 'Do you have Supra access?',
  SentriLockAccess = 'Do you have SentriLock access?',
  SurveyorServiceableArea = 'How far are you willing to drive?',
  SurveyorParentheses = '(Select the furthest distance in miles)',
  InspectorServiceableArea = 'Indicate your furthest driving distance from your business address',
  InspectorParentheses = '(in miles)',
  ReportWritingSoftware = 'Report writing software',
  MISA = 'Master inspection and services agreement',
  Insurance = 'Insurance certificate agreement',
  Error = 'Error updating, check your connection',
}

export interface BusinessDetailsLoaderProps {
  contractorId: string,
  businessDetailsEdited: boolean,
  setBusinessDetailsEdited: (e: boolean) => void,
  confirmationModalIsOpen: boolean,
  setConfirmationModalIsOpen: (value: boolean) => void,
  handleConfirm: () => void,
  basicInformationRef: React.RefObject<HTMLDivElement>,
  inspectionServicesRef: React.RefObject<HTMLDivElement>,
  certificationsRef: React.RefObject<HTMLDivElement>,
  serviceableAreaRef: React.RefObject<HTMLDivElement>,
  accessAndSoftwareRef: React.RefObject<HTMLDivElement>,
  documentsRef: React.RefObject<HTMLDivElement>,
  currentSection: BusinessDetailsSection,
  setCurrentSection: (value: BusinessDetailsSection) => void,
}

export interface BusinessDetailsProps {
  confirmationModalIsOpen: boolean,
  setConfirmationModalIsOpen: (value: boolean) => void,
  updateButtonDisabled: boolean,
  setBusinessDetailsEdited: (e: boolean) => void,
  handleConfirm: () => void,
  renderSectionContent: (section: BusinessDetailsSection) => JSX.Element,
  currentSection: BusinessDetailsSection,
  loading: boolean,
  errorUpdating?: boolean,
  basicInformationRef: React.RefObject<HTMLDivElement>,
  inspectionServicesRef: React.RefObject<HTMLDivElement>,
  certificationsRef: React.RefObject<HTMLDivElement>,
  serviceableAreaRef: React.RefObject<HTMLDivElement>,
  accessAndSoftwareRef: React.RefObject<HTMLDivElement>,
  documentsRef: React.RefObject<HTMLDivElement>,
  misaFiles: ContractorFileAttachment[],
  email: string,
  setEmail: (value: string) => void,
  phoneNumber: string
  setPhoneNumber: (value: string) => void,
  street: string,
  setStreet: (value: string) => void,
  city: string,
  setCity: (value: string) => void,
  state: StateEnum | StateCodeEnum,
  setState: (value: StateEnum | StateCodeEnum) => void,
  zipCode: string,
  setZipCode: (value: string) => void,
  showEmailError: boolean,
  setShowEmailError: (e: boolean) => void,
  showPhoneError: boolean,
  setShowPhoneError: (e: boolean) => void,
  showZipCodeError: boolean,
  setShowZipCodeError: (e: boolean) => void,
  addressRef: React.RefObject<HTMLInputElement>,
  serviceAreaRadiusInMiles?: number,
  setServiceAreaRadiusInMiles: (value: number) => void,
  hasSupra: boolean,
  setHasSupra: (value: boolean) => void,
  hasSentrilock: boolean,
  setHasSentriLock: (value: boolean) => void,
  reportWritingSoftware: ReportWritingSoftwareEnum,
  setReportWritingSoftware: (value: ReportWritingSoftwareEnum) => void,
  isMobile: boolean,
  msaSigned: boolean,
  updateBusinessDetails: () => void,
  onboardingContractorType: ContractorTypeEnum,

  // surveyor only
  firstName: string,
  setFirstName: (value: string) => void,
  lastName: string,
  setLastName: (value: string) => void,
  inspectorLicenseHeld: boolean,
  setInspectorLicenseHeld: (e: boolean) => void,
  contractorLicenseHeld: boolean,
  setContractorLicenseHeld: (e: boolean) => void,
  realEstateLicenseHeld: boolean,
  setRealEstateLicenseHeld: (e: boolean) => void,

  // inspector only
  businessName: string,
  setBusinessName: (e: string) => void,
  website: string,
  setWebsite: (e: string) => void,
  businessContactName: string,
  setBusinessContactName: (e: string) => void,
  schedulerName: string,
  setSchedulerName: (e: string) => void,
  schedulerPhoneNumber: string,
  setSchedulerPhoneNumber: (e: string) => void,
  schedulerEmail: string,
  setSchedulerEmail: (e: string) => void,
  showSchedulerPhoneError: boolean,
  setShowSchedulerPhoneError: (value: boolean) => void,
  showSchedulerEmailError: boolean,
  setShowSchedulerEmailError: (value: boolean) => void,
  stateLicenseNumber: string,
  setStateLicenseNumber: (e: string) => void,
  ashiLicenseNumber: string,
  setAshiLicenseNumber: (e: string) => void,
  internachiLicenseNumber: string,
  setInternachiLicenseNumber: (e: string) => void,
  otherLicenseNumber: string,
  setOtherLicenseNumber: (e: string) => void,
  certificationFiles: ContractorFileAttachment[],
  uploadInsuranceCertificate: (uploadedFile: File) => void;
  loadingNewFile: boolean;
  inspectionServices: InspectionServiceType[],
  contractorPricingInputs: ContractorPricingInput[],
  setContractorPricingInputs: (value: ContractorPricingInput[]) => void,
}

export interface BusinessDetailsMobileViewProps {
  currentSection: BusinessDetailsSection,
  setCurrentSection: (value: BusinessDetailsSection) => void,
  showBackButton: boolean,
  showUpdateButton: boolean,
  onboardingContractorType: ContractorTypeEnum,
  // optional since not all mobile sections need to update
  loading?: boolean,
  errorUpdating?: boolean,
  businessDetailsEdited?: boolean,
  setBusinessDetailsEdited?: (e: boolean) => void,
  updateBusinessDetails?: () => void,
}

export enum ReportWritingSoftwareEnum {
  CloudInspect = 'Cloud Inspect',
  HomeInspectorPro = 'Home Inspector Pro',
  HomeGauge = 'HomeGauge',
  ISN = 'ISN',
  InspectIt = 'Inspect It',
  Inspectify = 'Inspectify',
  PalmTech = 'Palm-Tech',
  ReportHost = 'ReportHost',
  Spectacular = 'Spectacular',
  Spectora = 'Spectora',
  TapInspect = 'Tap Inspect',
  WhisperSoltuions = 'Whisper Solutions',
}

export type InsuranceCertificate = {
  category: string;
  created_at: number;
  filename: string;
  typename: string;
  url: string;
};

export const inspectionServiceNames: { [key: string]: string } = {
  '3d_home_scan': '3D home scan',
  access_issue_fee: 'Access issue fee',
  adu_inspection: 'ADU inspection',
  asbestos_inspection: 'Asbestos inspection',
  broker_price_opinion: 'Broker price opinion',
  cancellation_fee: 'Cancellation fee',
  crawlspace_fee: 'Crawlspace fee',
  darwin_lockbox_install: 'Darwin lockbox install',
  darwin_lockbox_removal: 'Darwin lockbox removal',
  darwin_lockbox_troubleshoot: 'Darwin lockbox troubleshoot',
  denver_rental_compliance: 'Denver rental compliance',
  draw_inspection: 'Draw inspection',
  energy_audit: 'Energy audit',
  final_inspection: 'Final inspection',
  floor_plan: 'Floor plan',
  foundation_inspection: 'Foundation inspection',
  four_point_inspection: 'Four point inspection',
  hoa_inspection: 'HOA inspection',
  home_inspection: 'Home inspection',
  hvac_inspection: 'HVAC inspection',
  insurance_inspection: 'Insurance inspection',
  land_development_inspection: 'Land development inspection',
  lead_based_paint_inspection: 'Lead based paint inspection',
  lease_renewal: 'Lease renewal',
  lockbox: 'Lockbox',
  major_systems_plus: 'Major systems plus',
  media_only: 'Media only',
  meth_inspection: 'Meth inspection',
  midlease_walk: 'Midlease walk',
  mold_inspection: 'Mold inspection',
  move_in_inspection: 'Move in inspection',
  move_out_inspection: 'Move out inspection',
  occupancy_check: 'Occupancy check',
  oil_tank_locate: 'Oil tank locate',
  pool_inspection: 'Pool inspection',
  pre_drywall_inspection: 'Pre-drywall inspection',
  pre_inspection: 'Pre-inspection',
  quality_control_inspection: 'Quality control inspection',
  radon_inspection: 'Radon inspection',
  re_inspection: 'Re-inspection',
  rent_ready_inspection: 'Rent ready inspection',
  rental_pre_listing_inspection: 'Rental pre-listing inspection',
  roof_inspection: 'Roof inspection',
  safety_issue_fee: 'Safety issue fee',
  septic_inspection: 'Septic inspection',
  septic_line_scope: 'Septic line scope',
  sewer_inspection: 'Sewer inspection',
  solar_and_insulation_qa_checks: 'Solar and insulation QA checks',
  sow_inspection: 'SOW inspection',
  spa_inspection: 'Spa inspection',
  termite_inspection: 'Termite inspection',
  thermography: 'Thermography',
  trip_fee: 'Trip fee',
  utility_appointment: 'Utility appointment',
  water_quality_testing: 'Water quality testing',
  well_inspection: 'Well inspection',
  wind_mitigation: 'Wind mitigation',
  winterization: 'Winterization',
  zip_measurements: 'Zip measurements',
};
