import {
  ReportAttachment,
  ReportInformation,
  ReportItemType,
  ReportMediaType,
  ReportSeverityType,
} from 'marketplace-common';
import React, { useMemo, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useCurrentUser } from '../../../../hooks/user';
import { WorkItemModalOptions } from '../../../RehabToolPage/types';
import Attachment from './Attachment';
import MediaModal from './MediaModal';
import styles from './styles.module.scss';

interface Props {
  title: any,
  imageWidth: number,
  attachments: ReportAttachment[],
  informations?: ReportInformation[],
  isSystemAttachments?: boolean,
  isRehab?: boolean,
  handleWorkItemModal?: (options: WorkItemModalOptions) => void,
  systemName?: string,
  subsystemName?: string,
  informationsData?: { label: string, value: string | null | undefined }[],
  noteTitle?: string;
  noteDetails?: string;
  note?: string;
  badgeSeverity?: ReportSeverityType;
  isLimitation?: boolean;
  handleOpenReportMediaUploader?: () => void;
  isPrint?: boolean;
  allowCopyLongLiveUrl?: boolean;
}

const Attachments: React.FC<Props> = ({
  title, imageWidth, attachments, informations, isSystemAttachments, isRehab,
  handleWorkItemModal, systemName, subsystemName, informationsData,
  noteTitle, noteDetails, note, badgeSeverity, isLimitation, handleOpenReportMediaUploader,
  isPrint, allowCopyLongLiveUrl,
}) => {
  const { currentUser } = useCurrentUser();

  if (isRehab && attachments.length === 0) { return null; }

  const [showModal, setShowModal] = useState(false);
  const [currentAttachmentIndex, privateSetCurrentAttachmentIndex] = useState(0);

  // Accessing React State in Event Listeners
  // https://medium.com/geographit/accessing-react-state-in-event-listeners-with-usestate-and-useref-hooks-8cceee73c559
  const attachmentIndexRef = useRef(currentAttachmentIndex);

  const photosphereAttachments = useMemo(
    () => attachments.filter((attachment) => attachment.itemType === ReportItemType.Attachment || attachment.mediaType === ReportMediaType.sphere),
    [attachments],
  );

  const remainingAttachments = useMemo(
    () => attachments.filter((attachment) => !(attachment.itemType === ReportItemType.Attachment || attachment.mediaType === ReportMediaType.sphere)),
    [attachments],
  );

  const incrementCurrentAttachment = (incrementBy: number) => {
    const newIndex = (attachmentIndexRef.current + incrementBy) % attachments.length;

    // skip spheres
    if (attachments[newIndex].mediaType === ReportMediaType.sphere) {
      incrementCurrentAttachment(incrementBy + (incrementBy < 0 ? -1 : 1));
      return;
    }
    if (newIndex < 0) {
      attachmentIndexRef.current = attachments.length - 1;
    } else {
      attachmentIndexRef.current = newIndex;
    }

    privateSetCurrentAttachmentIndex(attachmentIndexRef.current);
  };

  const setCurrentAttachment = (newIndex: number) => {
    if (newIndex < 0) {
      attachmentIndexRef.current = attachments.length - 1;
    } else {
      attachmentIndexRef.current = newIndex % attachments.length;
    }

    privateSetCurrentAttachmentIndex(attachmentIndexRef.current);
  };

  const arrowHandler = (event: KeyboardEvent) => {
    const { key } = event;
    switch (key) {
      case 'ArrowLeft':
        incrementCurrentAttachment(-1);
        break;
      case 'ArrowRight':
        incrementCurrentAttachment(1);
        break;
      default:
        break;
    }
  };

  const handleWorkItemModalClick = (attachment: ReportAttachment) => {
    handleWorkItemModal({
      isOpen: true,
      attachments: [attachment],
      systemName,
      subsystemName,
      informations: informationsData,
      noteTitle,
      noteDetails,
      note,
      severity: badgeSeverity,
      limitation: isLimitation,
      itemType: 'ReportAttachment',
      itemId: attachment.id,
      isAddingFromReport: true,
    });
  };

  const showAddButton = !isRehab && currentUser?.admin && !isPrint;

  const renderAttachmentList = (
    sectionTitle: string,
    listItem: ReportAttachment[],
  ) => (
    <div>
      <h3
        className={styles.header}
        style={{ fontWeight: isSystemAttachments ? 700 : 0 }}
      >
        {sectionTitle}
      </h3>
      <div className={styles.attachmentGrid}>
        {listItem.map((attachment) => (
          <Attachment
            data-testid="attachment"
            key={attachment.id}
            attachment={attachment}
            handleWorkItemModalClick={handleWorkItemModalClick}
            handleOpenMediaModal={() => {
              const newIndex = attachments.findIndex((a) => a.id === attachment.id);
              setCurrentAttachment(newIndex);
              setShowModal(true);
            }}
            imageWidth={imageWidth}
            informations={informations}
            isRehab={isRehab}
          />
        ))}
      </div>
    </div>
  );

  return (
    <div
      className={styles.container}
      style={{ margin: isSystemAttachments ? '24px 0px' : 0 }}
    >
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: isSystemAttachments ? 24 : 0,
        }}
      >
        <h3
          className={styles.header}
          style={{ fontWeight: isSystemAttachments ? 700 : 0 }}
        >
          {attachments.length > 0 ? '' : title}
        </h3>

        {showAddButton && <Button onClick={handleOpenReportMediaUploader}>Add attachments</Button>}
      </div>

      {attachments.length > 0 && (
        <>
          {photosphereAttachments.length > 0
            && renderAttachmentList(`${subsystemName} 360° Photo`, photosphereAttachments)}

          {remainingAttachments.length > 0
            && renderAttachmentList(`${subsystemName} photos / videos`, remainingAttachments)}

          <MediaModal
            show={showModal}
            allowCopyLongLiveUrl={allowCopyLongLiveUrl}
            handleClose={() => setShowModal(false)}
            attachments={attachments}
            currentAttachmentIndex={currentAttachmentIndex}
            incrementCurrentAttachment={incrementCurrentAttachment}
            arrowHandler={arrowHandler}
          />
        </>
      )}
    </div>
  );
};

export default Attachments;
