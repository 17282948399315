import { gql } from '@apollo/client';

export const FULL_CONTRACTOR_FIELDS = gql`
  fragment FullContractorFields on ContractorType {
    id
    contractorType
    businessName
    businessSite
    latitude
    longitude
    certifications
    ashiLicenseNumber
    internachiLicenseNumber
    otherLicenseNumber
    stateLicenseNumber
    contractorLicenseHeld
    inspectorLicenseHeld
    realEstateLicenseHeld
    inBusinessSince
    country
    logo {
      url
    }
    preferredInspectorStatus
    instantBookingEnabled
    businessContactName
    businessPhone
    businessEmail
    businessAddress
    street
    street2
    city
    state
    businessZipCode
    schedulerName
    schedulerPhone
    schedulerEmail
    sendAutomatedSms
    serviceZipCodes
    serviceAreaRadiusMileage
    hasSentrilock
    hasSupra
    reportWritingSoftware
    msaSigned
    token 
    tipaltiIsPayable
    fileAttachments {
      category
      createdAt
      attachment {
        filename
        url
      }
    }
    currentOnboarding {
      id
      appLoggedIn
      backgroundCheckRequired
      backgroundCheckSubmittedAt
      canSetPassword
      completedStep
      createdAt
      misaSigned
      marketSaturatedAtOnboardingTime
      onboardingTrackerDismissedAt
      paymentDetailsFilled
      status
      submitBackgroundCheckUrl
      updatedAt
    }
    currentInspectorOnboarding {
      id
      appLoggedIn
      businessInformationAdded
      canSetPassword
      completedStep
      createdAt
      isMultiInspectorFirm
      misaSigned
      onboardingTrackerDismissedAt
      paymentDetailsFilled
      status
      updatedAt
      usersAdded
    }
    contractorPricings {
      active
      allowCustomPricing
      description
      name
    }
  }
`;

export const USER_CONTRACTOR = gql`
  ${FULL_CONTRACTOR_FIELDS}
  query {
    currentUser {
      id
      email
      name
      createdAt
      admin
      teamId
      teamAdmin
      canManageUsers
      contractorId
      grantedPermissions
      contractor {
        ...FullContractorFields
      }
      teamUsers {
        team {
          id
          token
          name
          allowRehabToolAccess
          enableRehabCostSplitting
          rehabTags
          drawSummaryTableDisplay
          sowSummaryTableDisplay
          company {
            id
            name
          }
        }
      }
    }
  }
`;

export const SINGLE_CONTRACTOR = gql`
  ${FULL_CONTRACTOR_FIELDS}
  query SingleContractor($id: ID!) {
    contractor(id: $id) {
      ...FullContractorFields
    }
  }
`;

export const CONTRACTOR_ALLOW_INITIAL_USER_CREATION = gql`
  query ContractorAllowInitialUserCreation($token: String!) {
    contractorAllowInitialUserCreation(token: $token)
  }
`;
