import React, {
  useEffect, useState, useMemo,
} from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { usePlacesWidget } from 'react-google-autocomplete';
import { UPDATE_CONTRACTOR } from '../../../graphql/mutations';
import { captureException } from '../../../utils/error';
import { ContractorFileAttachment, ContractorPricing, ContractorPricingInput, ContractorTypeEnum, UpdateContractorReturnDataType } from '../../../types';
import { BusinessDetailsLoaderProps, BusinessDetailsSection, inspectionServiceNames, InsuranceCertificate, ReportWritingSoftwareEnum } from './types';
import { EventName, track } from '../../../utils/analytics';
import BusinessDetails from './BusinessDetails';
import { isUserUsingMobile } from '../../../utils/user';
import { StateCodeEnum, StateEnum } from '../../../types/address';
import { getFormattedStreetAddress } from '../../../utils/map';
import { uploadContractorFile } from '../../../utils/file_upload';
import BusinessDetailsMobileView from './BusinessDetailsMobileView';
import BasicInformationSection from './BusinessDetailsSections/BasicInformationSection';
import CertificationsSection from './BusinessDetailsSections/CertificationsSection';
import ServiceableAreaSection from './BusinessDetailsSections/ServiceableAreaSection';
import AccessAndSoftwareSection from './BusinessDetailsSections/AccessAndSoftwareSection';
import DocumentsSection from './BusinessDetailsSections/DocumentsSection';
import InspectionServicesSection from './BusinessDetailsSections/InspectionServicesSection';
import { InspectionServiceType } from './BusinessDetailsSections/InspectionService/types';
import { unformatPhoneNumber, validateEmail, validateZipCode } from '../../../utils/string';
import { SINGLE_CONTRACTOR } from '../../../graphql/queries/contractor';

const BusinessDetailsLoader: React.FC<BusinessDetailsLoaderProps> = ({
  contractorId,
  basicInformationRef,
  inspectionServicesRef,
  certificationsRef,
  serviceableAreaRef,
  accessAndSoftwareRef,
  documentsRef,
  currentSection,
  setCurrentSection,
  confirmationModalIsOpen,
  setConfirmationModalIsOpen,
  businessDetailsEdited,
  setBusinessDetailsEdited,
  handleConfirm,
}) => {
  const { data: contractorData } = useQuery(SINGLE_CONTRACTOR, {
    variables: { id: contractorId },
    onError: () => { captureException(new Error('Fetching contractor')); },
  });

  const contractor = useMemo(() => contractorData?.contractor, [contractorData]);
  const isMobile = useMemo(() => isUserUsingMobile(), []);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState<StateEnum | StateCodeEnum>();
  const [zipCode, setZipCode] = useState('');
  const [showZipCodeError, setShowZipCodeError] = useState(false);
  const [serviceAreaRadiusInMiles, setServiceAreaRadiusInMiles] = useState(0);
  const [hasSupra, setHasSupra] = useState<boolean>();
  const [hasSentrilock, setHasSentriLock] = useState<boolean>();
  const [reportWritingSoftware, setReportWritingSoftware] = useState<ReportWritingSoftwareEnum>();
  const [certificationFiles, setCertificationFiles] = useState([] as ContractorFileAttachment[]);
  const [misaFiles, setMisaFiles] = useState([] as ContractorFileAttachment[]);

  // surveyor only
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [inspectorLicenseHeld, setInspectorLicenseHeld] = useState(false);
  const [contractorLicenseHeld, setContractorLicenseHeld] = useState(false);
  const [realEstateLicenseHeld, setRealEstateLicenseHeld] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [showPhoneError, setShowPhoneError] = useState(false);

  // inspector only
  const [businessName, setBusinessName] = useState('');
  const [website, setWebsite] = useState('');
  const [businessContactName, setBusinessContactName] = useState('');
  const [schedulerName, setSchedulerName] = useState('');
  const [schedulerEmail, setSchedulerEmail] = useState('');
  const [schedulerPhoneNumber, setSchedulerPhoneNumber] = useState('');
  const [showSchedulerEmailError, setShowSchedulerEmailError] = useState(false);
  const [showSchedulerPhoneError, setShowSchedulerPhoneError] = useState(false);
  const [stateLicenseNumber, setStateLicenseNumber] = useState('');
  const [ashiLicenseNumber, setAshiLicenseNumber] = useState('');
  const [internachiLicenseNumber, setInternachiLicenseNumber] = useState('');
  const [otherLicenseNumber, setOtherLicenseNumber] = useState('');
  const [loadingNewFile, setLoadingNewFile] = useState(false);
  const [uploadingError, setUploadingError] = useState(false);
  const [contractorPricingInputs, setContractorPricingInputs] = useState<ContractorPricingInput[]>([]);
  const [inspectionServices, setInspectionServices] = useState<InspectionServiceType[]>([]);

  const getInspectionServiceName = (name: string) : string => {
    const inspectionServiceName = inspectionServiceNames[name];
    if (inspectionServiceName) return inspectionServiceName;
    return name.replace(/_/g, ' ').charAt(0).toUpperCase() + name.replace(/_/g, ' ').slice(1);
  };

  // Rename and alphabetize the inspection service names
  const initializeInspectionServices = (contractorPricings?: ContractorPricing[]) => {
    const formattedInspectionServices: InspectionServiceType[] = contractorPricings?.map((pricing) => ({
      name: pricing.name,
      formattedName: getInspectionServiceName(pricing.name),
      allowCustomPricing: pricing.allowCustomPricing || false,
      description: pricing?.description || '',
      active: pricing.active || false,
      recentlyEdited: false,
      tooltipId: `tooltip-${pricing.name.replace(/_/g, '-')}`,
    }));
    formattedInspectionServices?.sort((a, b) => a.formattedName.localeCompare(b.formattedName));
    return formattedInspectionServices;
  };

  // If an inspection service was recently edited, update the recentlyEdited value
  useEffect(() => {
    if (contractorPricingInputs.length === 0) {
      setInspectionServices(initializeInspectionServices(contractor?.contractorPricings));
    } else if (contractorPricingInputs.length > 0) {
      const editedInspectionServices = inspectionServices.map((service) => {
        if (contractorPricingInputs.find((input) => input.name === service.name)) {
          return {
            ...service,
            recentlyEdited: true,
          };
        }
        return {
          ...service,
          recentlyEdited: false,
        };
      });
      setInspectionServices(editedInspectionServices);
      setBusinessDetailsEdited(true);
    }
  }, [contractorPricingInputs]);

  useEffect(() => {
    if (contractor) {
      setEmail(contractor?.businessEmail || '');
      setPhoneNumber(contractor?.businessPhone || '');
      setStreet(contractor?.street);
      setCity(contractor?.city);
      setState(contractor?.state as StateEnum);
      setZipCode(contractor?.businessZipCode);
      setServiceAreaRadiusInMiles(contractor?.serviceAreaRadiusMileage);
      setHasSupra(contractor?.hasSupra);
      setHasSentriLock(contractor?.hasSentrilock);

      if (contractor.contractorType === 'inspector') {
        setBusinessName(contractor?.businessName || '');
        setWebsite(contractor?.businessSite || '');
        setBusinessContactName(contractor?.businessContactName || '');
        setSchedulerName(contractor?.schedulerName || '');
        setSchedulerEmail(contractor?.schedulerEmail || '');
        setSchedulerPhoneNumber(contractor?.schedulerPhone || '');
        setReportWritingSoftware(contractor?.reportWritingSoftware as ReportWritingSoftwareEnum);
        setStateLicenseNumber(contractor?.stateLicenseNumber || '');
        setAshiLicenseNumber(contractor?.ashiLicenseNumber || '');
        setInternachiLicenseNumber(contractor?.internachiLicenseNumber || '');
        setOtherLicenseNumber(contractor?.otherLicenseNumber || '');
        setInspectionServices(initializeInspectionServices(contractor?.contractorPricings));
      }

      if (contractor?.contractorType === 'surveyor') {
        const [first, last] = contractor?.businessName.split(' ');
        if (first) setFirstName(first);
        if (last) setLastName(last);
        setInspectorLicenseHeld(contractor?.inspectorLicenseHeld);
        setContractorLicenseHeld(contractor?.contractorLicenseHeld);
        setRealEstateLicenseHeld(contractor?.realEstateLicenseHeld);
      }
    }
  }, [contractor]);

  const [update, { data: updateContractorData, loading, error }] = useMutation<UpdateContractorReturnDataType>(
    UPDATE_CONTRACTOR,
    {
      onError: (e) => {
        captureException(e);
      },
    },
  );

  useEffect(() => {
    if (updateContractorData) {
      setInspectionServices(initializeInspectionServices(updateContractorData?.updateContractor?.contractor?.contractorPricings));
    }
  }, [updateContractorData]);

  const sharedFieldsValid = () : boolean => (
    unformatPhoneNumber(phoneNumber).length === 10
    && validateEmail(email)
    && street.length > 0
    && city.length > 0
    && state.length > 0
    && validateZipCode(zipCode)
  );

  const businessDetailsComplete = () : boolean => {
    if (!contractor) return false;

    if (contractor.contractorType === ContractorTypeEnum.Inspector) {
      return sharedFieldsValid() && certificationFiles.length > 0 && businessName.length > 0;
    }
    if (contractor.contractorType === ContractorTypeEnum.Surveyor) {
      return sharedFieldsValid() && firstName.length > 0 && lastName.length > 0;
    }
    return false;
  };

  const updateButtonDisabled: boolean = useMemo(() => loading || !businessDetailsComplete() || !businessDetailsEdited, [loading, businessDetailsEdited, businessDetailsComplete]);

  const updateBusinessDetails = async () => {
    try {
      await update({
        variables: {
          input: {
            id: contractorId,
            businessName: contractor.contractorType === ContractorTypeEnum.Inspector ? businessName : `${firstName} ${lastName}`,
            businessSite: website,
            businessContactName,
            businessPhone: phoneNumber,
            businessEmail: email,
            schedulerName,
            schedulerPhone: schedulerPhoneNumber,
            schedulerEmail,
            street,
            city,
            state,
            zipCode,
            newContractorPricings: contractorPricingInputs,
            stateLicenseNumber,
            ashiLicenseNumber,
            internachiLicenseNumber,
            otherLicenseNumber,
            serviceAreaRadiusMileage: serviceAreaRadiusInMiles,
            hasSupra,
            hasSentrilock,
            reportWritingSoftware,
            inspectorLicenseHeld,
            contractorLicenseHeld,
            realEstateLicenseHeld,
          },
        },
      });
      track(EventName.InspectorUpdatedDetails, { contractor });
      setBusinessDetailsEdited(false);
    } catch (e: any) {
      captureException(e);
    }
  };

  const { ref } = usePlacesWidget({
    apiKey: process.env.GOOGLE_MAP_API_KEY,
    onPlaceSelected: (place: any) => {
      setStreet(getFormattedStreetAddress(place));
      setCity(place.address_components.find((component: any) => component.types.includes('locality')).long_name);
      setState(place.address_components.find((component: any) => component.types.includes('administrative_area_level_1')).long_name);
      setZipCode(place.address_components.find((component: any) => component.types.includes('postal_code')).long_name);
    },
    options: {
      types: ['address'],
      field: 'place_id',
    },
  });

  useEffect(() => {
    if (contractor?.fileAttachments) {
      const tempCerts: ContractorFileAttachment[] = [];
      const tempMisas: ContractorFileAttachment[] = [];
      contractor.fileAttachments.forEach((file: ContractorFileAttachment) => {
        if (file.category === 'insurance_certificate') {
          tempCerts.push(file);
        } else if (file.category === 'contract') {
          tempMisas.push(file);
        }
      });
      setCertificationFiles(tempCerts);
      setMisaFiles(tempMisas);
    }
  }, [contractor?.fileAttachments]);

  const uploadInsuranceCertificate = async (uploadedFile: File) => {
    setLoadingNewFile(true);
    const category = 'insurance_certificate';
    try {
      // TODO GA9-1603: migrate addContractorAttachment to "new" GraphQL server
      const res: InsuranceCertificate = await uploadContractorFile(uploadedFile, category, contractorId);
      if (res) {
        track(EventName.InspectorUploadedFile, { contractorId, category });
        const fileAttachment = {
          category,
          createdAt: res.created_at,
          attachment: {
            filename: res.filename,
            url: res.url,
          },
        } as ContractorFileAttachment;
        setCertificationFiles([...certificationFiles, fileAttachment]);
      }
    } catch (e: any) {
      captureException(e.message);
      setUploadingError(true);
    } finally {
      setLoadingNewFile(false);
    }
  };

  // Business details mobile view
  const renderSectionContent = (section: BusinessDetailsSection) => {
    switch (section) {
      case BusinessDetailsSection.BasicInformation:
        return (
          <BusinessDetailsMobileView
            currentSection={currentSection}
            setCurrentSection={setCurrentSection}
            showBackButton={false}
            showUpdateButton
            updateBusinessDetails={updateBusinessDetails}
            onboardingContractorType={contractor?.contractorType}
            businessDetailsEdited={businessDetailsEdited}
            setBusinessDetailsEdited={setBusinessDetailsEdited}
            errorUpdating={error?.message?.length > 0 || uploadingError}
            loading={loading}
          >
            <BasicInformationSection
              title={BusinessDetailsSection.BasicInformation}
              sectionRef={basicInformationRef}
              firstName={firstName}
              setFirstName={setFirstName}
              lastName={lastName}
              setLastName={setLastName}
              businessName={businessName}
              setBusinessName={setBusinessName}
              website={website}
              setWebsite={setWebsite}
              businessContactName={businessContactName}
              setBusinessContactName={setBusinessContactName}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              email={email}
              setEmail={setEmail}
              schedulerName={schedulerName}
              setSchedulerName={setSchedulerName}
              schedulerEmail={schedulerEmail}
              setSchedulerEmail={setSchedulerEmail}
              schedulerPhoneNumber={schedulerPhoneNumber}
              setSchedulerPhoneNumber={setSchedulerPhoneNumber}
              street={street}
              setStreet={setStreet}
              city={city}
              setCity={setCity}
              state={state}
              setState={setState}
              zipCode={zipCode}
              setZipCode={setZipCode}
              showZipCodeError={showZipCodeError}
              setShowZipCodeError={setShowZipCodeError}
              showPhoneError={showPhoneError}
              setShowPhoneError={setShowPhoneError}
              showEmailError={showEmailError}
              setShowEmailError={setShowEmailError}
              showSchedulerPhoneError={showSchedulerPhoneError}
              setShowSchedulerPhoneError={setShowSchedulerPhoneError}
              showSchedulerEmailError={showSchedulerEmailError}
              setShowSchedulerEmailError={setShowSchedulerEmailError}
              addressRef={ref}
              isMobile={isMobile}
              onboardingContractorType={contractor?.contractorType}
            />
          </BusinessDetailsMobileView>
        );
      case BusinessDetailsSection.InspectionServices:
        return (
          <BusinessDetailsMobileView
            currentSection={currentSection}
            setCurrentSection={setCurrentSection}
            showBackButton
            showUpdateButton
            updateBusinessDetails={updateBusinessDetails}
            onboardingContractorType={contractor?.contractorType}
            businessDetailsEdited={businessDetailsEdited}
            setBusinessDetailsEdited={setBusinessDetailsEdited}
            errorUpdating={error?.message?.length > 0 || uploadingError}
            loading={loading}
          >
            <InspectionServicesSection
              title={BusinessDetailsSection.InspectionServices}
              sectionRef={inspectionServicesRef}
              inspectionServices={inspectionServices}
              contractorPricingInputs={contractorPricingInputs}
              setContractorPricingInputs={setContractorPricingInputs}
            />
          </BusinessDetailsMobileView>
        );
      case BusinessDetailsSection.Certifications:
        return (
          <BusinessDetailsMobileView
            currentSection={currentSection}
            setCurrentSection={setCurrentSection}
            showBackButton
            showUpdateButton
            updateBusinessDetails={updateBusinessDetails}
            onboardingContractorType={contractor?.contractorType}
            businessDetailsEdited={businessDetailsEdited}
            setBusinessDetailsEdited={setBusinessDetailsEdited}
            errorUpdating={error?.message?.length > 0 || uploadingError}
            loading={loading}
          >
            <CertificationsSection
              title={BusinessDetailsSection.Certifications}
              sectionRef={certificationsRef}
              onboardingContractorType={contractor?.contractorType}
              stateLicenseNumber={stateLicenseNumber}
              setStateLicenseNumber={setStateLicenseNumber}
              ashiLicenseNumber={ashiLicenseNumber}
              setAshiLicenseNumber={setAshiLicenseNumber}
              internachiLicenseNumber={internachiLicenseNumber}
              setInternachiLicenseNumber={setInternachiLicenseNumber}
              otherLicenseNumber={otherLicenseNumber}
              setOtherLicenseNumber={setOtherLicenseNumber}
              inspectorLicenseHeld={inspectorLicenseHeld}
              setInspectorLicenseHeld={setInspectorLicenseHeld}
              contractorLicenseHeld={contractorLicenseHeld}
              setContractorLicenseHeld={setContractorLicenseHeld}
              realEstateLicenseHeld={realEstateLicenseHeld}
              setRealEstateLicenseHeld={setRealEstateLicenseHeld}
            />
          </BusinessDetailsMobileView>
        );
      case BusinessDetailsSection.ServiceableArea:
        return (
          <BusinessDetailsMobileView
            currentSection={currentSection}
            setCurrentSection={setCurrentSection}
            showBackButton
            showUpdateButton
            updateBusinessDetails={updateBusinessDetails}
            onboardingContractorType={contractor?.contractorType}
            businessDetailsEdited={businessDetailsEdited}
            setBusinessDetailsEdited={setBusinessDetailsEdited}
            errorUpdating={error?.message?.length > 0 || uploadingError}
            loading={loading}
          >
            <ServiceableAreaSection
              isMobile={isMobile}
              onboardingContractorType={contractor?.contractorType}
              title={BusinessDetailsSection.ServiceableArea}
              sectionRef={serviceableAreaRef}
              serviceAreaRadiusInMiles={serviceAreaRadiusInMiles}
              setServiceAreaRadiusInMiles={setServiceAreaRadiusInMiles}
            />
          </BusinessDetailsMobileView>
        );
      case BusinessDetailsSection.AccessAndSoftware:
        return (
          <BusinessDetailsMobileView
            currentSection={currentSection}
            setCurrentSection={setCurrentSection}
            showBackButton
            showUpdateButton
            updateBusinessDetails={updateBusinessDetails}
            onboardingContractorType={contractor?.contractorType}
            businessDetailsEdited={businessDetailsEdited}
            setBusinessDetailsEdited={setBusinessDetailsEdited}
            errorUpdating={error?.message?.length > 0 || uploadingError}
            loading={loading}
          >
            <AccessAndSoftwareSection
              title={BusinessDetailsSection.AccessAndSoftware}
              sectionRef={accessAndSoftwareRef}
              hasSupra={hasSupra}
              setHasSupra={setHasSupra}
              onboardingContractorType={contractor?.contractorType}
              hasSentrilock={hasSentrilock}
              setHasSentriLock={setHasSentriLock}
              reportWritingSoftware={reportWritingSoftware}
              setReportWritingSoftware={setReportWritingSoftware}
            />
          </BusinessDetailsMobileView>
        );
      case BusinessDetailsSection.Documents:
        return (
          <BusinessDetailsMobileView
            currentSection={currentSection}
            setCurrentSection={setCurrentSection}
            showBackButton
            showUpdateButton={false}
            onboardingContractorType={contractor?.contractorType}
          >
            <DocumentsSection
              title={BusinessDetailsSection.Documents}
              sectionRef={documentsRef}
              misaFiles={misaFiles}
              certificationFiles={certificationFiles}
              uploadInsuranceCertificate={uploadInsuranceCertificate}
              loadingNewFile={loadingNewFile}
              msaSigned={contractor?.msaSigned}
              onboardingContractorType={contractor?.contractorType}
            />
          </BusinessDetailsMobileView>
        );
      default:
        return <></>;
    }
  };

  return (
    <BusinessDetails
      renderSectionContent={renderSectionContent}
      confirmationModalIsOpen={confirmationModalIsOpen}
      setConfirmationModalIsOpen={setConfirmationModalIsOpen}
      updateButtonDisabled={updateButtonDisabled}
      setBusinessDetailsEdited={setBusinessDetailsEdited}
      handleConfirm={handleConfirm}
      currentSection={currentSection}
      loading={loading}
      errorUpdating={error?.message?.length > 0 || uploadingError}
      basicInformationRef={basicInformationRef}
      inspectionServicesRef={inspectionServicesRef}
      certificationsRef={certificationsRef}
      serviceableAreaRef={serviceableAreaRef}
      accessAndSoftwareRef={accessAndSoftwareRef}
      documentsRef={documentsRef}
      misaFiles={misaFiles}
      certificationFiles={certificationFiles}
      uploadInsuranceCertificate={uploadInsuranceCertificate}
      loadingNewFile={loadingNewFile}
      inspectionServices={inspectionServices}
      firstName={firstName}
      setFirstName={setFirstName}
      lastName={lastName}
      setLastName={setLastName}
      email={email}
      setEmail={setEmail}
      phoneNumber={phoneNumber}
      setPhoneNumber={setPhoneNumber}
      businessName={businessName}
      setBusinessName={setBusinessName}
      website={website}
      setWebsite={setWebsite}
      businessContactName={businessContactName}
      setBusinessContactName={setBusinessContactName}
      schedulerName={schedulerName}
      setSchedulerName={setSchedulerName}
      schedulerEmail={schedulerEmail}
      setSchedulerEmail={setSchedulerEmail}
      schedulerPhoneNumber={schedulerPhoneNumber}
      setSchedulerPhoneNumber={setSchedulerPhoneNumber}
      street={street}
      setStreet={setStreet}
      city={city}
      setCity={setCity}
      state={state}
      setState={setState}
      zipCode={zipCode}
      setZipCode={setZipCode}
      serviceAreaRadiusInMiles={serviceAreaRadiusInMiles}
      setServiceAreaRadiusInMiles={setServiceAreaRadiusInMiles}
      hasSupra={hasSupra}
      setHasSupra={setHasSupra}
      hasSentrilock={hasSentrilock}
      setHasSentriLock={setHasSentriLock}
      reportWritingSoftware={reportWritingSoftware}
      setReportWritingSoftware={setReportWritingSoftware}
      isMobile={isMobile}
      stateLicenseNumber={stateLicenseNumber}
      setStateLicenseNumber={setStateLicenseNumber}
      ashiLicenseNumber={ashiLicenseNumber}
      setAshiLicenseNumber={setAshiLicenseNumber}
      internachiLicenseNumber={internachiLicenseNumber}
      setInternachiLicenseNumber={setInternachiLicenseNumber}
      otherLicenseNumber={otherLicenseNumber}
      setOtherLicenseNumber={setOtherLicenseNumber}
      inspectorLicenseHeld={inspectorLicenseHeld}
      setInspectorLicenseHeld={setInspectorLicenseHeld}
      contractorLicenseHeld={contractorLicenseHeld}
      setContractorLicenseHeld={setContractorLicenseHeld}
      realEstateLicenseHeld={realEstateLicenseHeld}
      setRealEstateLicenseHeld={setRealEstateLicenseHeld}
      showEmailError={showEmailError}
      setShowEmailError={setShowEmailError}
      showPhoneError={showPhoneError}
      setShowPhoneError={setShowPhoneError}
      showZipCodeError={showZipCodeError}
      setShowZipCodeError={setShowZipCodeError}
      addressRef={ref}
      msaSigned={contractor?.msaSigned}
      updateBusinessDetails={updateBusinessDetails}
      onboardingContractorType={contractor?.contractorType}
      showSchedulerPhoneError={showSchedulerPhoneError}
      setShowSchedulerPhoneError={setShowSchedulerPhoneError}
      showSchedulerEmailError={showSchedulerEmailError}
      setShowSchedulerEmailError={setShowSchedulerEmailError}
      contractorPricingInputs={contractorPricingInputs}
      setContractorPricingInputs={setContractorPricingInputs}
    />
  );
};

export default BusinessDetailsLoader;
