import uniq from 'lodash/uniq';
import {
  ProjectHistory,
  Rehab,
  WorkItem,
  WorkItemAssociationsInterface,
  WorkItemAttachmentsInterface,
  WorkItemsInterface,
} from '../../types';
import {
  SET_PROJECT,
  ADD_WORK_ITEMS,
  ADD_WORK_ITEM_ASSOCIATIONS,
  ADD_WORK_ITEM_ATTACHMENTS,
  DELETE_WORK_ITEM,
  DELETE_WORK_ITEM_ASSOCIATIONS,
  ADD_TEMPLATE_WORK_ITEMS,
  ADD_TEMPLATE_WORK_ITEM_PRICINGS,
  DELETE_WORK_ITEM_ATTACHMENTS,
  ADD_PROJECT_HISTORY,
  ADD_REHAB_TEAM_MARKETS,
  CLEAR_TEMPLATE_WORK_ITEMS,
  ADD_WORK_ITEM_CONTRIBUTOR,
  ADD_REHAB_TEAM_CUSTOM_SYSTEMS,
  UPDATE_TEAM_CUSTOM_SYSTEM,
  ADD_REHAB_REPORT_SYSTEMS,
} from '../actions/types';

export const initialRehabState: Rehab = {
  projects: {},
  rehabTeamMarkets: {},
  rehabTeamCustomSystems: {},
  rehabReportSystems: [],
  workItems: {},
  workItemAssociations: {},
  workItemAttachments: {},
  templateWorkItems: {},
  templateWorkItemPricings: {},
  workHistory: {},
};

function reducer(state = initialRehabState, action: any) {
  switch (action.type) {
    case SET_PROJECT: {
      const rehabState = { ...state };
      const { project } = action.payload;
      const existingProject = rehabState.projects[project.id] || {};

      rehabState.projects[project.id] = { ...existingProject, ...project };

      return rehabState;
    }
    case ADD_REHAB_TEAM_MARKETS: {
      const rehabState = { ...state };
      const { rehabTeamMarkets } = action.payload;

      rehabState.rehabTeamMarkets = {
        ...rehabState.rehabTeamMarkets,
        ...rehabTeamMarkets,
      };

      return rehabState;
    }
    case ADD_REHAB_TEAM_CUSTOM_SYSTEMS: {
      const rehabState = { ...state };
      const { rehabTeamCustomSystems } = action.payload;

      rehabState.rehabTeamCustomSystems = {
        ...rehabState.rehabTeamCustomSystems,
        ...rehabTeamCustomSystems,
      };

      return rehabState;
    }

    case UPDATE_TEAM_CUSTOM_SYSTEM: {
      const rehabState = { ...state };
      const { updatedSystem } = action.payload;

      if (rehabState.rehabTeamCustomSystems[updatedSystem.id]) {
        const updatedRehabTeamCustomSystems = {
          ...rehabState.rehabTeamCustomSystems,
          [updatedSystem.id]: updatedSystem,
        };

        return {
          ...rehabState,
          rehabTeamCustomSystems: updatedRehabTeamCustomSystems,
        };
      }
      return {
        ...rehabState,
        rehabTeamCustomSystems: {
          ...rehabState.rehabTeamCustomSystems,
          [updatedSystem.id]: updatedSystem,
        },
      };
    }

    case ADD_REHAB_REPORT_SYSTEMS: {
      const rehabState = { ...state };
      const reportSystems = action.payload;

      rehabState.rehabReportSystems = {
        ...reportSystems,
      };

      return rehabState;
    }

    case ADD_WORK_ITEMS: {
      const rehabState = { ...state };
      const { workItems } = action.payload;

      if (workItems) {
        Object.keys(workItems as WorkItemsInterface).forEach((id) => {
          const workItem: WorkItem = workItems?.[id];
          const projectId: string = workItem?.projectId;

          if (projectId && rehabState.projects[projectId]) {
            // add work item ids to the project
            rehabState.projects[projectId].workItems.push(id);
          }
        });

        rehabState.workItems = {
          ...rehabState.workItems,
          ...workItems,
        };
      }

      return rehabState;
    }
    case DELETE_WORK_ITEM: {
      const rehabState = { ...state };
      const { workItemId } = action.payload;

      const workItem = rehabState.workItems[workItemId];
      const project = rehabState.projects[workItem.projectId];
      project.workItems = project.workItems.filter((id) => id !== workItemId);

      const workItems = Object.keys(rehabState.workItems).reduce((acc, id) => {
        if (id === workItemId) return acc;
        return { ...acc, [id]: rehabState.workItems[id] };
      }, {} as WorkItemsInterface);

      return {
        ...rehabState,
        workItems,
        projects: {
          ...rehabState.projects,
          [project.id]: project,
        },
      };
    }

    case ADD_WORK_ITEM_CONTRIBUTOR: {
      const rehabState = { ...state };
      const { contributor } = action.payload;
      const workItem = rehabState.workItems[contributor.workItemId];

      const updatedWorkItemContributors = workItem.workItemContributors
        ? [...workItem.workItemContributors, contributor]
        : [contributor];

      return {
        ...rehabState,
        workItems: {
          ...rehabState.workItems,
          [contributor.workItemId]: {
            ...workItem,
            workItemContributors: updatedWorkItemContributors,
          },
        },
      };
    }

    case ADD_WORK_ITEM_ASSOCIATIONS: {
      const rehabState = { ...state };
      const { workItemAssociations } = action.payload;

      rehabState.workItemAssociations = {
        ...rehabState.workItemAssociations,
        ...workItemAssociations,
      };

      const workItemAssociationIds = Object.keys(workItemAssociations);
      workItemAssociationIds.forEach((wIAI) => {
        if (workItemAssociations[wIAI].workItemId
          && rehabState.workItems[workItemAssociations[wIAI].workItemId]) {
          if (rehabState.workItems[
            workItemAssociations[wIAI].workItemId
          ].workItemAssociations.indexOf(wIAI) === -1) {
            rehabState.workItems[
              workItemAssociations[wIAI].workItemId
            ].workItemAssociations.push(wIAI);
          }
        }
      });

      return rehabState;
    }
    case DELETE_WORK_ITEM_ASSOCIATIONS: {
      const rehabState = { ...state };
      const { workItemAssociationIds } = action.payload;

      const workItemAssociations = Object.keys(rehabState.workItemAssociations).reduce(
        (acc, assId) => {
          if ((workItemAssociationIds as string[]).includes(assId)) return acc;
          return { ...acc, [assId]: rehabState.workItemAssociations[assId] };
        }, {} as WorkItemAssociationsInterface,
      );

      return {
        ...rehabState,
        workItemAssociations,
      };
    }
    case DELETE_WORK_ITEM_ATTACHMENTS: {
      const rehabState = { ...state };
      const { workItemAttachmentIds } = action.payload;

      const workItemAttachments = Object.keys(rehabState.workItemAttachments).reduce(
        (acc, attId) => {
          if ((workItemAttachmentIds as string[]).includes(attId)) return acc;
          return { ...acc, [attId]: rehabState.workItemAttachments[attId] };
        }, {} as WorkItemAttachmentsInterface,
      );

      return {
        ...rehabState,
        workItemAttachments,
      };
    }
    case ADD_WORK_ITEM_ATTACHMENTS: {
      const rehabState = { ...state };
      const { workItemAttachments } = action.payload;

      if (workItemAttachments) {
        rehabState.workItemAttachments = {
          ...rehabState.workItemAttachments,
          ...workItemAttachments,
        };
      }

      if (workItemAttachments) {
        const workItemAttachmentIds = Object.keys(workItemAttachments);
        workItemAttachmentIds.forEach((wIAI) => {
          if (workItemAttachments[wIAI].workItemId
            && rehabState.workItems[workItemAttachments[wIAI].workItemId]) {
            if (rehabState.workItems[
              workItemAttachments[wIAI].workItemId
            ].workItemAttachments.indexOf(wIAI) === -1) {
              rehabState.workItems[
                workItemAttachments[wIAI].workItemId
              ].workItemAttachments.push(wIAI);
            }
          }
        });
      }

      return rehabState;
    }
    case ADD_TEMPLATE_WORK_ITEMS: {
      const rehabState = { ...state };
      const { templateWorkItems } = action.payload;

      Object.keys(templateWorkItems).forEach((id) => {
        const templateWorkItem = templateWorkItems[id];
        templateWorkItem.templateWorkItemPricings = uniq([
          ...(rehabState.templateWorkItems[id]?.templateWorkItemPricings || []),
          ...(templateWorkItem?.templateWorkItemPricings || []),
        ]);
        rehabState.templateWorkItems[id] = templateWorkItem;
      });

      return rehabState;
    }
    case ADD_TEMPLATE_WORK_ITEM_PRICINGS: {
      const rehabState = { ...state };
      const { templateWorkItemPricings } = action.payload;

      rehabState.templateWorkItemPricings = {
        ...rehabState.templateWorkItemPricings,
        ...templateWorkItemPricings,
      };

      return rehabState;
    }
    case CLEAR_TEMPLATE_WORK_ITEMS: {
      const rehabState = { ...state };

      rehabState.templateWorkItems = {};
      rehabState.templateWorkItemPricings = {};

      return rehabState;
    }
    case ADD_PROJECT_HISTORY: {
      const rehabState = { ...state };
      const {
        projectId,
        projectHistory,
      }: { projectId: string, projectHistory: ProjectHistory } = action.payload;

      rehabState.workHistory[projectId] = {
        ...(rehabState.workHistory[projectId] || {}),
        ...projectHistory,
      };

      return rehabState;
    }
    default:
      return state;
  }
}

export default reducer;
