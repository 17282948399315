import { useMutation } from '@apollo/client';
import { UPDATE_INSPECTOR_ONBOARDING } from '../../graphql/mutations';
import { captureException } from '../../utils/error';
import { UpdateOnboardingVariables } from './types';
import { OnboardingUpdateResponseType } from '../../types';
import { BasicInformation } from '../../pages/Onboarding/OnboardingFormPage/types';

export const useUpdateInspectorOnboarding = (variables?: UpdateOnboardingVariables) => {
  const [updateInspectorOnboarding, { loading: updateInspectorOnboardingLoading, data: updateInspectorOnboardingData }] = useMutation<OnboardingUpdateResponseType, UpdateOnboardingVariables>(
    UPDATE_INSPECTOR_ONBOARDING,
    {
      onError: (error) => {
        if (error.message.includes('phone')) {
          variables?.setPhoneErrorMessage?.(BasicInformation.PhoneNumberInUse);
        }
        if (error.message.includes('email')) {
          variables?.setEmailErrorMessage?.(BasicInformation.EmailInUse);
        }
        captureException(new Error(variables?.errorMessage));
      },
      onCompleted: (data: OnboardingUpdateResponseType) => { variables?.onCompleted?.(data); },
    },
  );
  return { updateInspectorOnboarding, updateInspectorOnboardingLoading, updateInspectorOnboardingData };
};
