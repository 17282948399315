import React, { useEffect, useState } from 'react';
import {
  Alert, OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import InfoIcon from 'haven-design-system/build/assets/svgs/display/info_outlined.svg?component';
import UploadIcon from 'haven-design-system/build/assets/svgs/action/upload_outlined.svg?component';
import { colorBaseBlueDark, colorPaletteGrayscale100 } from 'haven-design-system/build/typescript/es6';
import {
  AttachedStaticFile,
  Button,
  FilesWrapper,
  Name,
} from './styles';
import { toReadableTime } from '../../utils/date';
import { ContractorFileAttachment } from '../../types';
import Spinner from '../Spinner';

interface Props {
  files: ContractorFileAttachment[] | File[];
  upload: boolean;
  name?: string;
  tooltip?: string;
  addFile?: (uploadedFile: File, category?: string) => void; // TODO GA9-1627: remove category once inspector onboarding is fully released
  loading?: boolean;
}

const FormFile: React.FC<Props> = ({
  name, files, addFile, loading, upload, tooltip,
}) => {
  const hiddenFileInput = React.useRef(null);
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [errorEditingInfo, setErrorEditingInfo] = useState(false);

  const handleClick = (e: any) => {
    e.preventDefault();
    hiddenFileInput.current.click();
  };

  const handleChange = (file: any) => {
    setUploadedFile(file[0]);
  };

  useEffect(() => {
    if (uploadedFile) addFile(uploadedFile);
  }, [uploadedFile]);

  const renderFiles = () => (files as any[]).map((file: any) => {
    let timestamp: number;
    let url: string;
    let filename: string;
    let isReportAttachment = false;

    if ('attachment' in file) {
      isReportAttachment = true;
      timestamp = file.createdAt;
      url = file.attachment.url;
      filename = file.attachment?.filename;
    } else {
      timestamp = file.lastModified / 1000;
      url = URL.createObjectURL(file);
      filename = file.name;
    }

    return (
      <AttachedStaticFile key={timestamp.toString()}>
        <a href={url} target="_blank" rel="noreferrer" download>{filename}</a>
        {isReportAttachment && (
          ` (${upload ? 'Uploaded' : 'Signed'} ${toReadableTime(timestamp, 'secs')})`
        )}
      </AttachedStaticFile>
    );
  });

  return (
    <>
      {(errorEditingInfo) && (
        <Alert variant="danger" onClose={() => setErrorEditingInfo(null)} dismissible>
          There was an issue uploading the file.
          Please try again or contact customer service for support.
          Error: {errorEditingInfo}.
        </Alert>
      )}
      <FilesWrapper>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
          <Name>{name}</Name>
          {tooltip && (
            <OverlayTrigger overlay={<Tooltip id="tooltip">{tooltip}</Tooltip>}>
              <span style={{ padding: '0 4px' }}><InfoIcon color={colorBaseBlueDark} /></span>
            </OverlayTrigger>
          )}
        </div>
        {renderFiles()}
        {upload && (
          <>
            {uploadedFile && !errorEditingInfo && loading && (
            <AttachedStaticFile>
              <Spinner />
            </AttachedStaticFile>
            )}
            <Dropzone onDrop={(acceptedFiles) => handleChange(acceptedFiles)}>
              {({ getRootProps, getInputProps, isDragActive }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} ref={hiddenFileInput} />
                  <Button
                    onClick={handleClick}
                    isDragActive={isDragActive}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      border: '1px solid',
                      borderRadius: '4px',
                      borderColor: colorPaletteGrayscale100,
                    }}
                  >
                    <UploadIcon color={colorBaseBlueDark} style={{ margin: 4 }} />
                    <span style={{ color: colorBaseBlueDark, fontWeight: 700 }}>Drop or choose a file to add</span>
                  </Button>
                </div>
              )}
            </Dropzone>
          </>
        )}
      </FilesWrapper>
    </>
  );
};

export default FormFile;
