import { useMutation } from '@apollo/client';
import { UPDATE_SURVEYOR_ONBOARDING } from '../../graphql/mutations';
import { captureException } from '../../utils/error';
import { UpdateOnboardingVariables } from './types';
import { OnboardingUpdateResponseType } from '../../types';
import { BasicInformation } from '../../pages/Onboarding/OnboardingFormPage/types';

export const useUpdateSurveyorOnboarding = (variables?: UpdateOnboardingVariables) => {
  const [updateSurveyorOnboarding, { loading: updateSurveyorOnboardingLoading, data: updateSurveyorOnboardingData }] = useMutation<OnboardingUpdateResponseType, UpdateOnboardingVariables>(
    UPDATE_SURVEYOR_ONBOARDING,
    {
      onError: (error) => {
        if (error.message.includes('phone')) {
          variables?.setPhoneErrorMessage?.(BasicInformation.PhoneNumberInUse);
        }
        if (error.message.includes('email')) {
          variables?.setEmailErrorMessage?.(BasicInformation.EmailInUse);
        }
        captureException(new Error(variables?.errorMessage));
      },
      onCompleted: (data: OnboardingUpdateResponseType) => { variables?.onCompleted?.(data); },
    },
  );
  return { updateSurveyorOnboarding, updateSurveyorOnboardingLoading, updateSurveyorOnboardingData };
};
