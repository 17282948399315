import React, { useContext, useMemo } from 'react';
import { DashboardPageTab } from '../types';
import { InspectorDashboardContext } from '../context';
import ContractorDetails from './ContractorDetails';
import { Container, MenuButton, MenuButtonLabel } from './styles';
import Footer from '../../../components/PageLayout/Footer';
import { ContractorTypeEnum } from '../../../types';
import { canAccessMSAPage } from '../../../utils/user';
import { BusinessDetailsSection } from '../BusinessDetails/types';
import './styles.scss';
import MenuButtonSection from './MenuButtonSection';
import { MenuProps } from './types';
import { useTreatment } from '../../../utils/splitio';
import { SplitFlags } from '../../../redux/actions';

const Menu: React.FC<MenuProps> = ({
  user,
  handleClickMenuTab,
  basicInformationRef,
  inspectionServicesRef,
  certificationsRef,
  serviceableAreaRef,
  accessAndSoftwareRef,
  documentsRef,
}) => {
  const { state } = useContext(InspectorDashboardContext);
  const featureInspectorOnboarding = useTreatment(SplitFlags.FeatureInspectorOnboarding);

  const showMSA = useMemo(() => (
    canAccessMSAPage(user)
  ), [user]);

  const canSetupPayment = useMemo(() => (
    user.currentUser.grantedPermissions.includes('view_payment_setup_page')
  ), [user]);

  return (
    <Container>
      <div>
        <ContractorDetails contractor={user.currentUser.contractor} />

        <MenuButton
          type="button"
          selected={state.page === DashboardPageTab.Jobs}
          onClick={() => handleClickMenuTab(DashboardPageTab.Jobs)}
        >
          <MenuButtonLabel selected={state.page === DashboardPageTab.Jobs}>
            {DashboardPageTab.Jobs}
          </MenuButtonLabel>
        </MenuButton>

        <MenuButton
          type="button"
          selected={state.page === DashboardPageTab.BusinessDetails}
          onClick={() => handleClickMenuTab(DashboardPageTab.BusinessDetails)}
        >
          <MenuButtonLabel selected={state.page === DashboardPageTab.BusinessDetails}>
            {DashboardPageTab.BusinessDetails}
          </MenuButtonLabel>
        </MenuButton>
        {/* TODO: remove contractor type checks when feature flag is removed */}
        {((featureInspectorOnboarding === 'on' && user.currentUser.contractor.contractorType === ContractorTypeEnum.Inspector)
          || user.currentUser.contractor.contractorType === ContractorTypeEnum.Surveyor)
          && state.page === DashboardPageTab.BusinessDetails ? (

            <>
              <MenuButtonSection
                sectionName={BusinessDetailsSection.BasicInformation}
                sectionRef={basicInformationRef}
              />
              {user.currentUser?.contractor.contractorType === ContractorTypeEnum.Inspector && (
              <MenuButtonSection
                sectionName={BusinessDetailsSection.InspectionServices}
                sectionRef={inspectionServicesRef}
              />
              )}
              <MenuButtonSection
                sectionName={BusinessDetailsSection.Certifications}
                sectionRef={certificationsRef}
              />
              <MenuButtonSection
                sectionName={BusinessDetailsSection.ServiceableArea}
                sectionRef={serviceableAreaRef}
              />
              <MenuButtonSection
                sectionName={BusinessDetailsSection.AccessAndSoftware}
                sectionRef={accessAndSoftwareRef}
              />
              <MenuButtonSection
                sectionName={BusinessDetailsSection.Documents}
                sectionRef={documentsRef}
              />
            </>
          ) : null}

        {user.currentUser?.canManageUsers ? (
          <MenuButton
            type="button"
            selected={state.page === DashboardPageTab.UserManagement}
            onClick={() => handleClickMenuTab(DashboardPageTab.UserManagement)}
          >
            <MenuButtonLabel selected={state.page === DashboardPageTab.UserManagement}>
              {DashboardPageTab.UserManagement}
            </MenuButtonLabel>
          </MenuButton>
        ) : (
          null
        )}

        {showMSA ? (
          <MenuButton
            type="button"
            selected={state.page === DashboardPageTab.MasterInspectionAndServiceAgreement}
            onClick={() => handleClickMenuTab(DashboardPageTab.MasterInspectionAndServiceAgreement)}
          >
            <MenuButtonLabel
              selected={state.page === DashboardPageTab.MasterInspectionAndServiceAgreement}
            >
              {DashboardPageTab.MasterInspectionAndServiceAgreement}
            </MenuButtonLabel>
          </MenuButton>
        ) : (
          null
        )}

        {canSetupPayment ? (
          <>
            <MenuButton
              type="button"
              selected={state.page === DashboardPageTab.TipaltiSetupProcess}
              onClick={() => handleClickMenuTab(DashboardPageTab.TipaltiSetupProcess)}
            >
              <MenuButtonLabel selected={state.page === DashboardPageTab.TipaltiSetupProcess}>
                {DashboardPageTab.TipaltiSetupProcess}
              </MenuButtonLabel>
            </MenuButton>

            <MenuButton
              type="button"
              selected={state.page === DashboardPageTab.TipaltiPaymentHistory}
              onClick={() => handleClickMenuTab(DashboardPageTab.TipaltiPaymentHistory)}
            >
              <MenuButtonLabel selected={state.page === DashboardPageTab.TipaltiPaymentHistory}>
                {DashboardPageTab.TipaltiPaymentHistory}
              </MenuButtonLabel>
            </MenuButton>
          </>
        ) : (
          null
        )}
      </div>

      <Footer sidebarFooter />
    </Container>
  );
};

export default Menu;
