import React, { useMemo } from 'react';
import InfoUnfilledIcon from '../../../../assets/icons/info_unfilled.svg';
import { WorkItem } from '../../../../types';
import { formatMoney } from '../../../../utils/money';
import {
  Table, TableBody, TableHead, TableRow,
} from '../../styles';
import {
  SummaryTableHeader,
  SummaryTableSubheader,
  MissingPricesWarningText,
  InfoIconMarginRight,
} from './styles';
import WorkItemSection from './WorkItemSection';
import { calculateWorkItemSummary, groupWorkItemsBySystemAndSubsystem } from '../../../../utils/rehab';

interface Props {
  workItems: {
    [systemName: string]: WorkItem[],
  },
}

const Summary: React.FC<Props> = ({ workItems }) => {
  const { totalCount, totalCost, isAnyPriceMissing } = useMemo(
    () => calculateWorkItemSummary(workItems),
    [workItems],
  );

  const groupedItems = useMemo(
    () => groupWorkItemsBySystemAndSubsystem(workItems),
    [workItems],
  );

  return (
    <Table>
      <TableHead>
        <TableRow>
          <SummaryTableHeader>Summary</SummaryTableHeader>
        </TableRow>
        <TableRow>
          <SummaryTableSubheader>{totalCount} work items</SummaryTableSubheader>
          <SummaryTableSubheader isCost>
            <strong>Total:</strong> {formatMoney(totalCost)}
          </SummaryTableSubheader>
        </TableRow>
        {isAnyPriceMissing && (
          <TableRow>
            <td colSpan={2}>
              <MissingPricesWarningText>
                <InfoIconMarginRight src={InfoUnfilledIcon} />
                Some work items have no pricing assigned
              </MissingPricesWarningText>
            </td>
          </TableRow>
        )}
      </TableHead>
      <TableBody>
        {Object.keys(groupedItems).map((systemName) => (
          <React.Fragment key={systemName}>
            {Object.keys(groupedItems[systemName]).map((subsystemName) => (
              <WorkItemSection
                key={`${systemName}-${subsystemName}`}
                title={systemName}
                subtitle={subsystemName}
                items={groupedItems[systemName][subsystemName]}
              />
            ))}
          </React.Fragment>
        ))}
      </TableBody>
    </Table>
  );
};

export default Summary;
