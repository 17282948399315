import { createSelector } from '@reduxjs/toolkit';
import { IdRef } from 'marketplace-common';
import { MarketplaceFrontendReduxState as ReduxState } from '../reducers';
import { extractSystemData } from '../../utils/rehab';
import { hideNestedSubsystem, hideNestedSystem, sortNestedSystemOrSubsystemByDisplayIndex } from '../../utils/report';
import { PartialSubsystemNested, PartialSystemNested } from '../../pages/RehabToolPage/types';

export const selectSplitIO = (state: ReduxState) => state.splitio;
export const selectLayout = (state: ReduxState) => state.layout;
export const selectActive = (state: ReduxState) => state.active;

export const selectTeams = (state: ReduxState) => state?.teams;
export const selectTeam = (state: ReduxState, teamToken: IdRef) => state?.teams?.[teamToken];

export const selectProperties = (state: ReduxState) => state.properties;

export const selectReports = (state: ReduxState) => state.reports;
export const selectReport = (state: ReduxState, reportId: string) => (
  state.reports?.[reportId]
);

export const selectTemplates = (state: ReduxState) => state.templates;

export const selectWorks = (state: ReduxState) => state.works;
export const selectWorksOrders = (state: ReduxState) => state.works?.orders;
export const selectWorksOrder = (state: ReduxState, orderId: number) => (
  state.works?.orders?.[orderId]
);
export const selectWorksOrderUnits = (state: ReduxState) => state.works?.orderUnits;
export const selectWorksOrderStakeholders = (state: ReduxState) => state.works?.orderStakeholders;
export const selectWorksProperties = (state: ReduxState) => state.works?.properties;
export const selectWorksOrderProperty = createSelector(
  selectWorksOrder,
  selectWorksProperties,
  (order, properties) => properties?.[order?.propertyId],
);
export const selectWorksPermits = (state: ReduxState) => state.works?.permits;
export const selectWorksPropertyPermits = createSelector(
  selectWorksOrderProperty,
  selectWorksPermits,
  (property, permits) => (property ? property.permitIds.map((permitId) => permits[permitId]) : []),
);

export const selectWorksReports = (state: ReduxState) => state.works?.reports;
export const selectInspection = (state: ReduxState, inspectionId: string) => state.works?.inspections[inspectionId];
export const selectWorksInspectionProposals = (state: ReduxState) => (
  state.works?.inspectionProposals
);

export const selectRehab = (state: ReduxState) => state.rehab;
export const selectRehabProject = (state: ReduxState, projectId: string) => (
  state.rehab.projects?.[projectId]
);
export const selectRehabTeamMarkets = (state: ReduxState) => state?.rehab?.rehabTeamMarkets;
export const selectRehabTeamCustomSystems = (state: ReduxState) => state?.rehab?.rehabTeamCustomSystems;
export const selectRehabReportSystems = (state: ReduxState) => state?.rehab?.rehabReportSystems;

export const selectWorkHistory = (state: ReduxState) => state.rehab?.workHistory;
export const selectProjectWorkHistory = (state: ReduxState, projectId: string) => (
  state.rehab?.workHistory?.[projectId]
);

export const selectAllRehabReportSystems = createSelector(
  [selectRehabReportSystems],
  (rehabReportSystems) => rehabReportSystems || [],
);

export const selectAllRehabTeamCustomSystems = createSelector(
  [selectRehabTeamCustomSystems],
  (rehabTeamCustomSystems) => rehabTeamCustomSystems || {},
);

export const selectCustomSubsytemsForSystem = createSelector(
  [
    selectAllRehabTeamCustomSystems,
    (_: ReduxState, systemName: string) => systemName,
  ],
  (allCustomSystems, systemName) => {
    const system = Object.values(allCustomSystems).find(
      (sys) => sys.name === systemName,
    );
    return system?.subsystemNames || [];
  },
);

export const selectCustomSystem = createSelector(
  [
    selectAllRehabTeamCustomSystems,
    (_: ReduxState, systemName: string) => systemName,
  ],
  (allCustomSystems, systemName) => {
    const system = Object.values(allCustomSystems).find(
      (sys) => sys.name === systemName,
    );
    return system || null;
  },
);

export const selectReportSystemDisplayIndex = createSelector(
  [
    selectAllRehabReportSystems,
    (_: ReduxState, systemName: string) => systemName,
  ],
  (reportSystems, systemName) => {
    const system = Object.values(reportSystems).find(
      (sys) => sys.name === systemName,
    );
    return system?.displayIndex || null;
  },
);

export const selectSortedRehabReportSystems = createSelector(
  [selectAllRehabReportSystems],
  (reportSystems) => {
    const systemData = extractSystemData(Object.values(reportSystems));

    const sortedReportSystems = sortNestedSystemOrSubsystemByDisplayIndex(
      Object.values(systemData as PartialSystemNested[]).filter(
        (system) => system.includeOnReport && !hideNestedSystem(system),
      ),
    ) as PartialSystemNested[];

    return sortedReportSystems || null;
  },
);

export const selectRehabReportSystemNames = createSelector(
  [selectSortedRehabReportSystems],
  (allRehabReportSystems) => {
    if (!allRehabReportSystems) return [];

    return allRehabReportSystems.map((system) => system.name);
  },
);

export const selectRehabReportSubsystemNames = createSelector(
  [selectSortedRehabReportSystems],
  (allRehabReportSystems) => {
    if (!allRehabReportSystems) return [];

    return allRehabReportSystems
      .flatMap((system) => system.reportSubsystems.map((subsystem) => subsystem.name));
  },
);

export const selectReportSubsystemsForSystem = createSelector(
  [
    selectSortedRehabReportSystems,
    (_: ReduxState, systemName: string) => systemName,
  ],
  (sortedRehabReportSystems, systemName): string[] => {
    const selectedSystem = (sortedRehabReportSystems || []).find(
      (system) => system.name === systemName,
    );

    const sortedSubsystem = sortNestedSystemOrSubsystemByDisplayIndex(
      (selectedSystem?.reportSubsystems || []) as PartialSubsystemNested[],
    ).filter(
      (subsystem) => subsystem.includeOnReport && !hideNestedSubsystem(subsystem as PartialSubsystemNested),
    );

    return sortedSubsystem.map((subsystem) => subsystem.name) || [];
  },
);

export const selectAllSubsystemsForSystem = createSelector(
  [
    selectReportSubsystemsForSystem,
    selectCustomSubsytemsForSystem,
    (_: ReduxState, systemName: string) => systemName,
  ],
  (sortedRehabReportSubsystems, customSubsystems) => [...sortedRehabReportSubsystems, ...customSubsystems],
);

export const selectAvailableProjectSubsystems = createSelector(
  [selectReportSubsystemsForSystem, selectCustomSubsytemsForSystem],
  (reportSubsystems, customSubsystems): string[] => [...reportSubsystems, ...customSubsystems],
);

export const selectCustomSystemNames = createSelector(
  [selectAllRehabTeamCustomSystems],
  (allCustomSystems) => {
    const systems = Object.values(allCustomSystems || {});
    const systemNames = systems.map((system) => system?.name);
    return systemNames;
  },
);

export const selectAllProjectSubsystemNames = createSelector(
  [selectSortedRehabReportSystems, selectCustomSystemNames],
  (allRehabReportSystems, allCustomSystemsNames) => {
    const reportSubsystemsNames = allRehabReportSystems
      .flatMap((system) => system.reportSubsystems.map((subsystem) => subsystem.name));

    return [...reportSubsystemsNames, ...allCustomSystemsNames];
  },
);

export const selectRehabWorkItemAttachments = (state: ReduxState) => (
  state.rehab?.workItemAttachments
);

export const selectWorkItems = (state: ReduxState) => state.rehab?.workItems;
export const selectWorkItem = (state: ReduxState, workItemId: string) => (
  state.rehab?.workItems?.[workItemId]
);
export const selectWorkItemAttachments = createSelector(
  selectWorkItem,
  selectRehabWorkItemAttachments,
  (workItem, workItemAttachments) => (
    Object.values(workItemAttachments).filter((att) => att.workItemId === workItem?.id)
  ),
);

export const selectProjectContributors = createSelector(
  [selectWorkItems, (_: ReduxState, projectId: string) => projectId],
  (workItems, projectId) => Object.values(workItems || {})
    .filter((workItem) => workItem.projectId === projectId)
    .flatMap((workItem) => workItem.workItemContributors || []),
);

export const selectUniqueContributorNames = createSelector(
  [selectProjectContributors],
  (contributors) => Array.from(new Set(contributors.map((contributor) => contributor.name))),
);

export const selectWorkItemAssociations = createSelector(
  selectWorkItem,
  (state: ReduxState) => state.rehab?.workItemAssociations,
  (workItem, workItemAssociations) => (
    Object.values(workItemAssociations).filter((ass) => ass.workItemId === workItem?.id)
  ),
);

export const selectlAllWorkItemAssociations = createSelector(
  (state: ReduxState) => state.rehab?.workItemAssociations ?? {},
  (_: ReduxState, workItemIds: string[]) => workItemIds,
  (workItemAssociations, workItemIds) => Object.values(workItemAssociations).filter(
    (ass) => Array.isArray(workItemIds) && workItemIds.includes(ass.workItemId),
  ),
);

export const selectTemplateWorkItems = (state: ReduxState) => state.rehab?.templateWorkItems;
export const selectTemplateWorkItemPricings = (state: ReduxState) => (
  state.rehab?.templateWorkItemPricings
);
