import React, { useMemo } from 'react';
import Modal from 'react-modal';
import { useMutation } from '@apollo/client';
import { InspectionWarranty } from 'marketplace-common';
import InspectionWarrantyImage from 'haven-design-system/build/assets/svgs/images/warranty/logo.svg?component';
import ViewAllIcon from 'haven-design-system/build/assets/svgs/controls/chevron_right.svg?component';
import LargeCheckmark from 'haven-design-system/build/assets/svgs/images/general/check.svg?component';
import './styles.scss';
import { FormFile, FormInput, FormSelect } from '../../../../components/FormElements';
import { uploadClaimDocument } from '../../../../utils/file_upload';
import { captureException } from '../../../../utils/error';
import FormInputArea from '../../../../components/FormElements/FormInputArea';
import { SUBMIT_WARRANTY_CLAIM } from '../../../../graphql/mutations/submitWarrantyClaim';
import { EventName, track } from '../../../../utils/analytics';
import Banner from '../Banner';
import Spinner from '../../../../components/Spinner';
import { getFormattedShortDate } from '../../../../utils/date';

enum ClaimStatus {
  'NotEstimated' = 'not_estimated',
  'EstimatedNotStarted' = 'estimated_not_started',
  'InProgress' = 'in_progress',
  'Complete' = 'complete',
}

interface Props {
  warranty: InspectionWarranty | null;
  isOpen: boolean;
  equipmentTypes?: string[];
  close: () => void;
}

const ClaimForm: React.FC<Props> = ({
  warranty,
  isOpen,
  equipmentTypes = [],
  close,
}) => {
  const [equipmentType, setEquipmentType] = React.useState('');
  const [lossDate, setLossDate] = React.useState('');
  const [status, setStatus] = React.useState<ClaimStatus | string>('');
  const [description, setDescription] = React.useState('');
  const [file, setFile] = React.useState<File | null>(null);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const [submitWarrantyClaim, { loading }] = useMutation(
    SUBMIT_WARRANTY_CLAIM, {
      onCompleted: () => {
        setIsSubmitted(true);
        setErrorMessage(null);
      },
      onError: (err) => {
        setErrorMessage('There was an issue submitting your claim. Please verify the information and try again. If the issue persists, please contact support at warranty@inspectify.com.');
        captureException(err, 'Error submitting warranty claim');
        setIsSubmitted(false);
      },
    },
  );

  const maxLossDate = useMemo(() => {
    if (warranty?.expirationDate) {
      const expirationDate = new Date(warranty?.expirationDate).getTime() / 1000;
      const today = (new Date().getTime() / 1000) + 86400;
      return getFormattedShortDate(Math.min(expirationDate, today));
    }
    return null;
  }, [warranty?.expirationDate]);

  const disabled = (
    !equipmentType
    || !lossDate
    || status === ''
    || !description
    || (status !== ClaimStatus.NotEstimated && !file)
  ) || loading;

  const handleClose = () => {
    setIsSubmitted(false);
    setErrorMessage(null);
    close();
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      if (disabled) throw new Error('Missing required fields');

      const input: any = {
        inspectionWarrantyId: warranty.id,
        productType: equipmentType,
        dateOfLoss: lossDate,
        repairStatus: status,
        note: description,
      };

      if (file) {
        const blobSignedId = await uploadClaimDocument(file);
        if (blobSignedId) input.blobSignedId = blobSignedId;
      }

      await submitWarrantyClaim({ variables: { input } });

      track(EventName.InspectionWarrantyClaimSubmitted, {
        warrantyId: warranty.id,
        equipmentType,
        lossDate,
        status,
        description,
        hasFile: !!file,
      });

      setDescription('');
      setFile(null);
      setStatus('');
      setLossDate('');
      setEquipmentType('');
    } catch (err: any) {
      captureException(err, 'Error uploading property ownership document');
      setIsSubmitted(false);
    }
  };

  return (
    <Modal
      className="warranty__modal"
      overlayClassName="warranty__overlay"
      isOpen={isOpen}
      onRequestClose={handleClose}
      ariaHideApp={false}
    >
      <div className="warranty__content">
        <div className="warranty__header">
          <div className="warranty__header__image">
            <InspectionWarrantyImage />
          </div>
          <div className="warranty__header__title">
            {isSubmitted ? 'Your claim has been submitted' : 'Inspection Warranty Claim Form'}
          </div>
        </div>
        {loading && <Spinner />}
        {!isSubmitted && (
          <form id="claim-form" className="warranty__form" onSubmit={handleSubmit}>
            {errorMessage && (<Banner type="error" body={errorMessage} />)}
            <div className="warranty__form__full">
              <div className="warranty__form__full__input">
                <FormSelect
                  name="Equipment type"
                  value={equipmentType}
                  onChange={(e:any) => setEquipmentType(e.target.value)}
                  options={equipmentTypes.map((type) => ({ value: type, display: type }))}
                  tooltip="Only eligible equipment types are listed here. If you do not see your equipment type, please contact support at warranty@inspectify.com"
                />
              </div>
            </div>
            <div className="warranty__form__full">
              <div className="warranty__form__full__input">
                <FormInput
                  name="When did this loss occur?"
                  value={lossDate}
                  onChange={(e:any) => setLossDate(e.target.value)}
                  min={warranty?.startDate}
                  max={maxLossDate}
                  type="date"
                  required
                />
              </div>
            </div>
            <div className="warranty__form__full">
              <div className="warranty__form__full__input">
                <FormSelect
                  name="What is the repair or replacement status of your equipment?"
                  options={[
                    { value: ClaimStatus.NotEstimated, display: 'Not estimated' },
                    { value: ClaimStatus.EstimatedNotStarted, display: 'Estimated, work not started' },
                    { value: ClaimStatus.InProgress, display: 'In progress' },
                    { value: ClaimStatus.Complete, display: 'Complete' },
                  ]}
                  value={status}
                  onChange={(e:any) => setStatus(e.target.value)}
                />
              </div>
            </div>
            {status !== ClaimStatus.NotEstimated && (
              <div className="warranty__form__full">
                <div className="warranty__form__full__input">
                  <FormFile
                    name="Attach a copy of the invoice or estimate"
                    files={file ? [file] : []}
                    addFile={(uploadedFile: File) => {
                      setFile(uploadedFile);
                    }}
                    upload
                  />
                </div>
              </div>
            )}
            <div className="warranty__form__full">
              <div className="warranty__form__full__input">
                <FormInputArea
                  name="How did you notice the issue?"
                  value={description}
                  placeholder="Provide a brief description of what happened."
                  onChange={(e:any) => setDescription(e.target.value)}
                  required
                />
              </div>
            </div>
            <div className="warranty__form__submit">
              <button
                type="submit"
                className="warranty__form__submit__button"
                disabled={disabled}
              >
                Submit claim
              </button>
            </div>
          </form>
        )}
        {isSubmitted && (
          <div className="warranty__complete">
            <div className="warranty__complete__text">
              Please allow 1-2 business days for the claim to be setup.&nbsp;
              Once the claim is established, a dedicated Claims Service
              Representative&nbsp; from our warranty administrator, Hartford
              Steam Boiler (HSB), will call you&nbsp; to assist in the handling
              of your claim.
            </div>
            <div className="warranty__complete__image"><LargeCheckmark /></div>
            <div className="warranty__complete__faq">
              <div className="warranty__complete__faq__header">
                <div className="warranty__complete__faq__header__title">Frequently Asked Questions</div>
                <a
                  className="warranty__complete__faq__header__link"
                  href="http://knowledge.inspectify.com/inspection-warranty-faqs"
                  target="_blank"
                  rel="noreferrer"
                >
                  View all <ViewAllIcon />
                </a>
              </div>
              <div className="warranty__complete__faq__body">
                <div className="warranty__complete__faq__body__item">
                  <div className="warranty__complete__faq__body__item__title">What are my repair company options?</div>
                  <div className="warranty__complete__faq__body__item__text">
                    We understand the loss of a (equipment type) may cause a disruption&nbsp;
                    in your life. To reduce the level of disruption, we want to remind&nbsp;
                    you that you have the option to choose a service provider and move&nbsp;
                    forward with repairs or replacement. If you repair or replace your&nbsp;
                    equipment before the conclusion of our investigation, we request that&nbsp;
                    you save all parts for possible inspection to assist in determining coverage.
                  </div>
                </div>

                <div className="warranty__complete__faq__body__item">
                  <div className="warranty__complete__faq__body__item__title">What if my loss is covered?</div>
                  <div className="warranty__complete__faq__body__item__text">
                    In the event of a covered loss, we will assess the cost for repair&nbsp;
                    or replacement. Our liability will extend to the lesser of the cost&nbsp;
                    to repair the damaged covered components or replace your equipment.&nbsp;
                    Additionally, the amount payable on your claim will be subject to the&nbsp;
                    remaining useful life value based on the age of your equipment.
                  </div>
                </div>
              </div>
            </div>
            <div className="warranty__complete__close">
              <button
                type="button"
                className="warranty__complete__close__button"
                onClick={handleClose}
              >
                Close this window
              </button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ClaimForm;
