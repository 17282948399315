import React, { useMemo, useState } from 'react';
import AddIcon from 'haven-design-system/build/assets/svgs/action/add.svg?component';
import Button from '../../../components/Button';
import UserTable from './UserTable';
import Checkbox from '../../../components/Checkbox';
import UserForm from './UserForm';
import './styles.scss';
import { ManageUsersView, UserManagementProps } from './types';
import PopupInstructions from '../../../components/PopupInstructions';
import { PopupInstructionsContent } from '../../../components/PopupInstructions/types';

const UserManagement: React.FC<UserManagementProps> = ({
  userData, usersAdded, isMultiInspectorFirm,
}) => {
  const [manageUsersView, setManageUsersView] = useState(ManageUsersView.ManageUsers);
  const [isShowArchivedUsersChecked, setIsShowArchivedUsersChecked] = useState(false);
  const [singleUser, setSingleUser] = useState(null);
  const [addUserPopupIsOpen, setAddUserPopupIsOpen] = useState(!usersAdded);

  // Show the popup if the user did not close the popup, no users have been added, the UserForm is in add user mode, the form is complete
  const showAddUserPopup = useMemo(() => (addUserPopupIsOpen
    && !usersAdded
    && isMultiInspectorFirm
  ), [addUserPopupIsOpen, usersAdded, isMultiInspectorFirm]);

  const handleCloseAddUserPopup = () => {
    setAddUserPopupIsOpen(false);
  };

  const handleReturnToManageUsers = () => {
    setManageUsersView(ManageUsersView.ManageUsers);
  };

  const handleAddUser = () => {
    setManageUsersView(ManageUsersView.UserForm);
    setSingleUser(null);
  };

  const renderManageUsersView = (view: ManageUsersView) => {
    switch (view) {
      case ManageUsersView.ManageUsers:
        return (
          <>
            <div className="userManagement__manageUsers">
              <div className="userManagement__manageUsers__header">
                <div className="userManagement__manageUsers__header__text">
                  {ManageUsersView.ManageUsers}
                </div>
                <Button
                  onClick={handleAddUser}
                  className="userManagement__manageUsers__header__addButton"
                >
                  <AddIcon />
                  Add user
                </Button>
                {showAddUserPopup && (
                  <PopupInstructions
                    header={PopupInstructionsContent.AddUserHeader}
                    body={PopupInstructionsContent.AddUserBody}
                    xPos={0}
                    yPos={50}
                    onClose={handleCloseAddUserPopup}
                  />
                )}
              </div>
              <UserTable
                userData={userData}
                showArchivedUsers={isShowArchivedUsersChecked}
                setSingleUser={setSingleUser}
                setManageUsersView={setManageUsersView}
              />
              <div className="userManagement__manageUsers__archivedUsers">
                <Checkbox
                  isChecked={isShowArchivedUsersChecked}
                  toggle={() => setIsShowArchivedUsersChecked(!isShowArchivedUsersChecked)}
                />
                Show archived users
              </div>
            </div>
          </>
        );
      case ManageUsersView.UserForm:
        return (
          <div className="userManagement__addUser">
            <UserForm
              handleReturn={handleReturnToManageUsers}
              singleUser={singleUser}
              setSingleUser={setSingleUser}
              usersAdded={usersAdded}
            />
          </div>
        );
      default:
        return null;
    }
  };
  return renderManageUsersView(manageUsersView);
};

export default UserManagement;
